.new-engagement-modal-wrapper {
  padding: 80px 0;
  background-image: url("../../../public/images/inductry-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.new-engagement-modal-wrapper h1 {
  font-size: 40px;
  font-weight: 600;
}

.new-engagement-modal-card {
  padding: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.new-engagement-modal-card h3 {
  font-size: 26px;
  font-weight: 500;
}

.new-engagement-modal-inner-wrapper {
  position: relative;
  margin-top: 50px;
}

.new-engagement-modal-inner-wrapper:before,
.new-engagement-modal-inner-wrapper:after {
  position: absolute;
  /* content: ""; */
  background: linear-gradient(89.99deg,
      #000e2b 0.01%,
      #80b927 24.32%,
      #80b927 72.31%,
      #011f42 100%);
}

.new-engagement-modal-inner-wrapper:before {
  width: 100%;
  height: 2px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.new-engagement-modal-inner-wrapper:after {
  background: linear-gradient(180deg,
      #011f42 0.01%,
      #80b927 24.32%,
      #80b927 72.31%,
      #011f42 100%);
  width: 2px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.new-engagement-modal-btn {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  font-family: "poppins";
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.new-engagement-modal-btn p {
  margin-top: 0rem;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.new-engagement-modal-btn:hover {
  color: #80b927;
  gap: 1rem;
}

@media (max-width: 767px) {

  .new-engagement-modal-inner-wrapper:before,
  .new-engagement-modal-inner-wrapper:after {
    display: none;
  }

  .new-engagement-modal-card {
    padding: 30px 30px;
  }
}


.engagment-model-finel-desing {
  background-color: #f2f2f2;
  padding: 50px;
  border-radius: 20px;
  height: 500px;
}

.card-text-box {
  transition: all 0.3s ease-in-out;
}

ul.new-engagement-models-top-bar {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

ul.new-engagement-models-top-bar li {
  list-style: none;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.card-text-box h1 {
  font-size: 26px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.card-text-box ul {
  margin-top: 20px;
  padding-left: 20px;
}

.card-text-box ul li {
  /* list-style: none; */
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
}

li.active-list {
  position: relative;
}

li.active-list:before {
  position: absolute;
  content: '';
  background-color: #80b927;
  width: 100%;
  height: 4px;
  border-radius: 100px;
  left: 0;
  bottom: -17px;
}

a.know-more-card-button {
  color: #80b927 !important;
  font-weight: 500;
}

a.know-more-card-button i {
  position: relative;
  top: 2px;
}

@media(max-width: 450px) {
  .engagment-model-finel-desing {
    padding: 20px;
  }

  ul.new-engagement-models-top-bar {
    margin-bottom: 20px;
    overflow-x: auto;
  }

  ul.new-engagement-models-top-bar li {
    min-width: 200px;
  }

  .new-engagement-modal-wrapper h1 {
    font-size: 28px;
  }

  .vedio-widht-control {
    width: 100%;
  }
}

@media(max-width: 950px) {
  .engagment-model-finel-desing {
    height: auto !important;
  }
}