.future-solutions-box {
    padding: 80px 0;
    background-image: url('../../../public/images/inductry-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.height-make-big .future-solutions-box {
    display: flex !important;
    align-items: center !important;
}

.height-make-big h1.engagment-model-text {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 100px !important;
}

.future-solutions-box h3.sub-heading {
    color: #ffffff;
    margin-bottom: 50px;
}

.road-map-box h3.sub-heading {
    color: #ffffff;
}

.future-solutions-box h3 {
    color: #ffffff;
    margin-bottom: 30px;
}

.industry-text-box {
    margin: 50px 0 30px 0;
}

.industry-text-box h2 {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 7px;
}

.industry-text-box p {
    color: #ffffff;
    text-align: center;
    width: 85%;
    margin: 0 auto;
}

.border-box-industry {
    position: relative;
}

.border-box-industry:before {
    position: absolute;
    content: '';
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.5%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
}

.border-box-industry .col-md-4 {
    position: relative;
}

.border-box-industry .col-md-4.none-border:before {
    display: none;
}

.border-box-industry .col-md-4:last-child:before {
    display: none;
}

.border-box-industry .col-md-4:before {
    position: absolute;
    content: '';
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.5%, rgba(255, 255, 255, 0) 100%);
    width: 2px;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

@media(max-width: 450px) {
    .row.border-box-industry .industry-text-box {
        margin: 30px 0 !important;
    }

    .height-make-big h1.engagment-model-text {
        width: 100% !important;
        margin-bottom: 0 !important;
    }

    .future-solutions-box h3 {
        margin-bottom: 0 !important;
    }

    .industry-text-box p {
        width: 100% !important;
    }

    .border-box-industry:before {
        display: none !important;
    }

    .future-solutions-box {
        padding: 60px 0 60px 0 !important;
    }
}