ul.blog-sidebar-box {
    padding: 0;
    position: sticky;
    top: 20px;
}

ul.blog-sidebar-box li.active-category {
    background-color: #80b927;
    color: #ffffff;
}

ul.blog-sidebar-box li {
    background-color: #ffffff;
    list-style: none;
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    font-weight: 400;
}

p.blog-main-card-text {
    color: #000000;
    opacity: 1 !important;
    font-size: 18px;
    font-weight: 400;
}