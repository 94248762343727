.hire-page-facts-box {
    background-image: url('../../../public/images/ai-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 80px 0;
}

h1.hire-fact-heading span {
    color: #80b927;
}

h1.hire-fact-heading {
    font-size: 40px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #ffffff;
}

ul.hire-page-fact-list {
    padding: 0;
    margin-top: 20px;
}

ul.hire-page-fact-list li {
    color: #ffffff;
    font-size: 18px;
    list-style: none;
    padding-left: 30px;
    position: relative;
    line-height: 40px;
}

ul.hire-page-fact-list li:before {
    position: absolute;
    content: '';
    background-image: url('../../../public/images/hire-page-setps-icons/dot-icon-list.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    left: 0;
    top: 10.5px;
}

.hire-us-cta-btn a {
    text-transform: capitalize;
    padding: 0 40px !important;
}

@media(max-width: 450px) {
    h1.hire-fact-heading {
        font-size: 28px;
    }

    .hire-page-facts-box {}
}