.main-banner-box.banner-bg-image {
    /* background-image: url('../../../public/images/bg-banner.png') !important;
    background-repeat: no-repeat;
    background-size: cover; */
    background-image: none;
    background-color: #01264cb8;
    position: relative;
    height: 88vh;
    overflow: hidden;
}

.video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the video covers the entire container */
    z-index: -1;
    pointer-events: none;
    /* Prevents interaction with the video */
}

.main-banner-box {
    padding: 80px 0 100px 0 !important;
    height: auto;
    display: flex;
    align-items: center;
}

.main-banner-vedio-image img {
    width: 70% !important;
}

h1.banner-heading.main-banner-heading {
    font-size: 56px !important;
}

h3.common-text {
    font-size: 18px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 15px;
}

h1.banner-heading span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

h2.banner-sub-heading {
    font-size: 22px;
    color: #FCFCFC;
    font-weight: 400;
}

a.banner-button-box:hover {
    background-color: transparent;
    border-color: #80B927;
}

a.banner-button-box {
    background-color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    border-radius: 5px;
    color: #80B927;
    padding: 15px 60px 16px;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

h4.banner-sub-heading {
    color: #FCFCFC;
    margin-top: 50px;
    font-size: 23px;
    margin-right: 103px;
    margin-bottom: 20px;
}

.logo-boxes-banner-small img {
    width: 70px;
    margin-right: 15px;
}

.logo-boxes-banner img {
    width: 150px;
}

.banner-vedio-side-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.banner-vedio-side-image img {
    width: 65%;
}

.flex-end-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.row-space-top {
    margin-top: 50px;
}

.banner-vedio-side-image iframe {
    width: 900px;
    height: 500px;
}

@media(max-width: 950px) {
    h1.banner-heading {
        font-size: 32px !important;
        line-height: 40px;
    }

    .main-banner-box {
        height: inherit !important;
    }

    .main-banner-box .banner-vedio-side-image img {
        width: 100% !important;
    }

    h1.banner-heading.main-banner-heading {
        font-size: 32px !important;
    }
}

@media(max-width: 450px) {
    .flex-end-box {
        align-items: flex-start !important;
        margin-top: 10px !important;
    }

    .main-banner-box.banner-bg-image .banner-vedio-icon-image {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .banner-vedio-icon-image img {
        width: 100px !important;
    }

    .main-banner-box {
        padding: 50px 0 !important;
    }

    .row.flex-convert-box .mt-5 {
        margin-top: 30px !important;
    }

    a.banner-button-box {
        padding: 10px 20px 10px !important;
    }

    h4.banner-sub-heading {
        margin-top: 20px !important;
    }

    .main-banner-box .banner-vedio-side-image img {
        width: 100% !important;
    }
}

@media(max-width: 375px) {
    h1.banner-heading {
        font-size: 32px !important;
        line-height: 40px;
    }

    .flex-end-box {
        align-items: flex-start !important;
        margin-top: 10px;
    }
}

.banner-model-box .modal-header {
    border: none !important;
}

.banner-model-box .modal-body {
    padding: 0 !important;
}

.banner-model-box {
    background-color: transparent;
    max-width: 900px !important;
}

.banner-model-box .modal-content {
    background-color: transparent !important;
    border: none;
}

.banner-model-box button.btn-close {
    position: absolute;
    right: 0;
    background-color: #80B927;
    opacity: 1 !important;
    font-size: 19px;
    top: 24px;
    z-index: 99;
    background-image: none !important;
    padding: 0;
    width: 40px;
    height: 40px;
    color: #ffffff;
    box-shadow: none !important;
}

.banner-vedio-icon-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.banner-vedio-icon-image img {
    width: 100px;
    /* border: 14px solid #80B927; */
    border-radius: 100px;
    position: relative;
    z-index: 2;
    box-shadow: 0 0px 50px 0 #ffffff6e;
}

span.vedio-icon-border {
    width: 180px;
    height: 180px;
    /* border: 15px solid #81b92768; */
    position: absolute;
    border-radius: 100px;
    /* animation: vedioSpin 2s linear infinite; */
}

span.vedio-icon-border-2 {
    width: 150px;
    height: 150px;
    /* border: 15px solid #81b9279b; */
    position: absolute;
    border-radius: 100px;
    /* animation: vedioSpin 4s linear infinite; */
}


@keyframes vedioSpin {
    0% {
        transform: scale(0.5);
        opacity: 1;
    }

    100% {
        transform: scale(1.7);
        opacity: 0.2;
    }
}