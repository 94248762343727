.development-services-main {
    background-color: #F3F5F9;
    padding: 60px 0px;
}

.development-tab-content-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.development-top-content h2 {
    font-size: 40px;
    font-weight: 600;
}

.development-top-content p {
    font-size: 18px;
    margin-bottom: 50px;
}

.development-tab-block {
    background-color: #fff;
    border: 1px solid #D4D4D4;
    border-radius: 10px;
}

.development-tab-menu {
    border-right: 1px solid #D4D4D4;
}

.development-tab-menu .development-tab-item {
    margin: 18px 0px;
    font-size: 16px;
    cursor: pointer;
    padding: 0 20px;
}

.development-tab-menu .development-tab-item.active {
    background-color: #80B927;
    color: #ffffff;
    font-weight: 500;
    border-radius: 5px;
    padding: 10px 20px;
}

.development-tab-content {
    padding: 30px 45px;
}

.development-tab-content-box h3 {
    font-size: 26px;
    padding-bottom: 5px;
}

.development-tab-content-box p {
    font-size: 16px;
}

.development-tab-content-box p {
    width: 100%;
    font-size: 18px;
}

/* .development-tab-content-box a {
    color: #fff;
    background-color: #80B927;
    text-decoration: none;
    font-size: 20px;
    padding: 7px 25px;
    border-radius: 6px;
    margin: 8px 0px;
    display: inline-block;
} */

.development-tab-content-box img {
    width: 85px;
    margin: 10px 40px 0px 0px;
}

.small-images-box .development-tab-content-box img {
    width: 50px;
}

.lets-discuss-btn a {
    margin-left: 0 !important;
    width: 20% !important;
}

@media(max-width: 950px) {
    .lets-discuss-btn a {
        width: 100% !important;
    }
}

@media(max-width: 768px) {
    .development-top-content h2 {
        font-size: 32px;
    }

    .development-top-content p {
        font-size: 16px;
    }
}

.case-study-detail-box .development-services-main {
    background-image: url('../../../public/images/inductry-bg.png');
    background-position: center;
}

.case-study-detail-box .development-top-content h2,
.case-study-detail-box .development-top-content p {
    color: #ffffff;
}

.case-study-detail-box .development-tab-block {
    background: rgb(252, 252, 254);
    background: linear-gradient(90deg, rgba(252, 252, 254, 1) 0%, rgba(197, 204, 212, 1) 100%);
}

.case-study-detail-box .development-tab-content-box p {
    width: 100%;
    font-size: 18px !important;
    margin: 0;
}

.case-study-detail-box .development-tab-menu {
    padding: 0 20px !important;
}

.developement-services-cta {
    font-size: 14px;
    font-weight: 400;
    color: #80b927;
    font-family: "poppins";
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .developement-services-cta p {
    margin-top: 0rem;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
  
  .developement-services-cta:hover {
color: #80B927;
    gap: 1rem;
  }

@media(max-width: 950px) {
    .development-tab-content-box p {
        width: 100%;
    }
}

@media(max-width: 450px) {
    .case-study-detail-box .development-tab-menu {
        border-bottom: 1px solid #80b927;
        border-right: none !important;
    }

    .development-tab-content-box img {
        margin: 10px 30px 0px 0px;
    }

    .development-tab-content {
        padding: 30px 20px !important;
    }

    .case-study-detail-box .development-tab-content {
        padding: 20px 20px 30px 20px;
    }
}