.industries-main-container {
    margin-top: 80px;
    margin-bottom: 40px;
}

/* width */
ul.links-boxes-lists::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

/* Track */
ul.links-boxes-lists::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
ul.links-boxes-lists::-webkit-scrollbar-thumb {
    background: #80B927;
    border-radius: 100px;
}

/* Handle on hover */
ul.links-boxes-lists::-webkit-scrollbar-thumb:hover {
    background: #80B927;
}

h4.sub-heading-industries {
    font-size: 26px;
    font-weight: 500;
}

@media(max-width: 450px) {
    .industries-main-container {
        margin-bottom: 0;
    }

    .top-image-space {
        margin-top: 30px;
    }
}

@media(max-width: 768px) {
    .industries-main-container {
        margin-top: 60px;
    }
}