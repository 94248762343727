.hire-combination-section-box-main {
    padding: 80px 0 95px 0;
    background: #f3f5f9;
}

.hire-combination-card {
    width: 100%;
    margin-top: 100px;
    padding: 0 80px 50px 80px;
    text-align: center;
    border: 1px solid #D1D1D1;
    border-radius: 15px;
    height: 70%;
}

.hire-page-cmobo-text h2 {
    font-size: 26px;
    font-weight: 500;
}

.hire-page-combo-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    border: 1px solid #CFCFCF;
    height: 100px;
    border-radius: 15px;
    box-shadow: 0px 4px 24.7px 0px #00000026;
    margin-top: -50px;
    background-color: #ffffff;
    margin-bottom: 30px;
}

.hire-page-combo-icons .hire-page-combo-icon:last-child {
    border: none;
}

.hire-page-combo-icon {
    width: 100%;
    height: 100%;
    border-right: 1px solid #CFCFCF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.hire-page-combo-icons .hire-page-combo-icon:last-child:before {
    display: none;
}

.hire-page-combo-icon:before {
    position: absolute;
    content: '';
    background-image: url('../../../public/images/tech-stack-icons/plus-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    right: -15px;
    z-index: 99;
}

.hire-page-combo-icon img {
    width: 50px;
    height: 50px;
}

@media(max-width: 950px) {
    .hire-combination-card {
        padding: 0 20px 40px 20px;
        height: 75%;
    }

    .hire-page-combo-icon img {
        width: 40px;
        height: 40px;
    }

    .hire-page-combo-icon:before {
        width: 22px;
        height: 22px;
        right: -11px;
    }
}

@media(max-width: 450px) {
    .hire-combination-section-box-main {
        padding: 40px 0;
    }

    .hire-combination-card {
        margin-top: 80px;
    }
}

@media(max-width: 1100px) {
    .hire-combination-card {
        padding: 0 30px 50px 30px;
        height: 80%;
    }
}