.future-solutions-box {
    padding: 80px 0 90px 0;
    background-image: url('../../../public/images/inductry-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.future-solutions-box h3 {
    color: #ffffff;
    margin-bottom: 30px;
}

.development-services-text-box {
    margin: 20px 0 30px 0;
}

.development-services-text-box h1 {
    text-align: center;
    text-transform: capitalize;
    font-size: 26px;
    font-weight: 500;
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 5px;
}

.development-services-text-box p {
    color: #ffffff;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}

.border-box-industry {
    position: relative;
}

.border-box-industry:before {
    position: absolute;
    content: '';
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.5%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
}

.row.border-box-industry.border-two-python {
    position: relative;
}

.row.border-box-industry.border-two-python .col-md-6:nth-child(6):before {
    display: none !important;
}

.row.border-box-industry.border-two-python:before {
    top: 34% !important;
}

.row.border-box-industry.border-two-python:after {
    position: absolute;
    content: '';
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.5%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 2px;
    top: 66%;
}

.border-box-industry .col-md-6 {
    position: relative;
}

.border-box-industry .col-md-6.none-border:before {
    /* display: none; */
}

.border-box-industry .col-md-6:nth-child(2):before,
.border-box-industry .col-md-6:nth-child(4):before {
    display: none;
}

.row.border-box-industry.border-class-box {
    justify-content: center;
}

.row.border-box-industry.border-class-box .col-md-6:nth-child(3):before {
    display: none;
}

.border-javascript-remove {
    justify-content: center;
}

.row.border-box-industry.border-two-python.border-javascript-remove .col-md-6:last-child:before {
    display: none !important;
}

.border-box-industry .col-md-6:before {
    position: absolute;
    content: '';
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.5%, rgba(255, 255, 255, 0) 100%);
    width: 2px;
    height: 100%;
    top: 49%;
    transform: translateY(-50%);
    right: 0;
    opacity: 0.8;
}

.icon-image-here {
    display: flex;
    justify-content: center;
    margin-top: 70px;
}

@media(max-width: 450px) {
    .row.border-box-industry .development-services-text-box {
        margin: 30px 0 0 0 !important;
    }

    .development-services-text-box p,
    .development-services-text-box h1 {
        width: 100%;
    }

    .row.border-box-industry.border-two-python:after {
        display: none;
    }

    .future-solutions-box h3 {
        margin-bottom: 0 !important;
    }

    .border-box-industry:before {
        display: none !important;
    }

    .future-solutions-box {
        padding: 60px 0 60px 0 !important;
    }
}