.ai-development-power-section-main {
  width: 100%;
  background-image: url("../../../public/images/what-we-do/ai-development-power-section.PNG");
  background-repeat: no-repeat;
  background-size: cover;
}

.power-section-left-section h1 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
}

.power-section-des {
  color: #ffffff;
  margin-top: 1.5rem;
  font-size: 18px;
}

.power-section-nav-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.power-section-nav-btn p {
  color: #80b927;
  margin-bottom: 0rem;
  font-size: 18px;
}

.power-section-nav-btn:hover {
  gap: 0.8rem;
}

.power-section-objectives-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  position: relative;
}

.power-section-objectives-wrapper:before {
  position: absolute;
  content: "";
  background: linear-gradient(90deg, #ffffff 0%, rgba(128, 185, 39, 0) 100%);
  width: 100%;
  height: 1.5px;
  top: 50%;
  transform: translateY(-50%);
}

.power-section-objectives-wrapper:after {
  position: absolute;
  content: "";
  background: linear-gradient(180deg, #ffffff 0%, rgba(128, 185, 39, 0) 100%);
  width: 1.5px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.power-section-single-objective {
  width: 49%;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 30px 60px 30px 0px;
}

.power-section-single-objective p:first-child {
  color: #80b927;
  margin-bottom: 0px;
  font-size: 40px;
  font-weight: 600;
}

.power-section-single-objective p:last-child {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 18px;
}

@media (max-widht: 950px) {
  .ai-development-power-section-main {
    background-position-x: 70%;
  }
}