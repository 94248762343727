.hire-page-app-idea-box {
    padding: 80px 0;
}

.app-idea-image img {
    width: 100%;
    height: 100%;
}

.app-idea-image {
    width: 95%;
    height: 100%;
}

.vedio-box-app-idea {
    max-width: 500px;
    height: 260px;
    object-fit: cover;
    border-radius: 30px !important;
}

.video-container:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 98.5%;
    background-color: #000000;
    opacity: 0.5;
    border-radius: 30px;
}

.video-container {
    width: 100%;
    max-width: 500px;
    /* overflow: hidden; */
    /* border-radius: 30px; */
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
    position: relative;
}

.play-button,
.pause-button {
    width: 60px;
    height: 60px;
    background-color: #80b927;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    transition: transform 0.3s;
    position: relative;
}

.play-button i,
.pause-button i {
    font-size: 22px;
}

.play-button:hover {
    transform: scale(1.1);
}

.play-button:before {
    position: absolute;
    content: '';
    opacity: 0.7;
    border: 15px solid #80b927;
    width: 85px;
    height: 85px;
    border-radius: 100px;
    z-index: -1;
    animation: vedioSpin 4s linear infinite;
}

.play-button:after {
    position: absolute;
    content: '';
    opacity: 0.5;
    border: 15px solid #80b927;
    width: 112px;
    height: 112px;
    border-radius: 100px;
    z-index: -1;
    animation: vedioSpin 3s linear infinite;
}

@keyframes vedioSpin {
    0% {
        transform: scale(0.5);
        opacity: 1;
    }

    100% {
        transform: scale(1.2);
        opacity: 0.2;
    }
}

.consultation-button {
    background-color: #80b927;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.consultation-button:hover {
    background-color: #6ea620;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
}

@media(max-width: 450px) {
    .app-idea-image {
        width: 100%;
        margin-bottom: 30px;
    }

    .hire-page-app-idea-box{
        padding: 50px 0;
    }
}