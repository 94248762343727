.why-us-box {
    padding: 80px 0 100px 0;
    /* background-image: url('../../../public/images/why-hire-us-banner.png'); */
    background-size: cover;
    background-position: 5%;
    /* background-color: #1b232e; */
    position: relative;
}

.why-us-box:before {
    position: absolute;
    content: '';
    background-color: #000000;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    top: 0;
    left: 0;
}

.bg-light-beam {
    background-image: url('../../../public/images/bg-one.svg') !important;
    background-size: cover;
}

.bg-web-services {
    background-image: url('../../../public/images/bg-web-services.png') !important;
    background-size: cover;
}

.why-us-box-slider p.common-text {
    font-size: 18px;
}

.why-us-text-box h1 span {
    color: #80B927;
}

.why-us-text-box h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: capitalize;
}

.why-us-second-text-box h1 span {
    color: #80B927;
}

.why-us-second-text-box h1 {
    font-size: 32px;
    color: #ffffff;
    margin-top: 30px;
    font-weight: 600;
}

.number-box h1 {
    color: #80B927;
    font-size: 40px;
    margin-top: 0 !important;
}

.number-boxes-main {
    margin-top: 30px !important;
}

.why-us-box-flex {
    display: flex;
}

.why-us-box-flex .number-box {
    width: 20%;
}

.number-box p.common-text {
    font-size: 18px;
    color: #FFFFFF;
}

.number-boxes-main.health-care-number-box .number-box h1 {
    font-size: 45px !important;
}

.number-boxes-main.health-care-number-box .number-box p.common-text {
    font-size: 16px !important;
}

p.why-us-box-text {
    /* width: 80%; */
}

.number-boxes-main .number-box:last-child:after {
    display: none;
}

.number-boxes-main .number-box:first-child {
    padding-left: 0 !important;
}

.number-boxes-main .number-box {
    padding-left: 40px !important;
}

.number-box {
    display: inline-block;
    padding-right: 50px;
    position: relative;
}

.number-box:after {
    position: absolute;
    content: '';
    width: 1.5px;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(128, 185, 39, 0) 100%);
}

.why-us-box-btn a:hover {
    background-color: #80B927;
    color: #FFFFFF;
    border-color: #80B927;
}

.why-us-box-btn a {
    border: 2px solid #ffffff;
    padding: 12px 25px;
    text-decoration: none;
    color: #FFFFFF;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.why-us-box-btn {
    margin: 40px 0 80px 0;
}

@media(max-width: 950px) {
    .why-us-text-box h1 {
        font-size: 32px;
    }

    .bg-light-beam {
        background-position: right !important;
    }

    .why-us-text-box h2.common-heading {
        margin-top: 10px;
    }

    .why-us-second-text-box h1 {
        margin-top: 10px;
    }

    .number-box p.common-text {
        font-size: 16px;
        color: #FFFFFF;
    }
}

@media(max-width: 450px) {
    .number-boxes-main {
        margin-top: 0 !important;
    }

    .number-boxes-main .number-box {
        margin-bottom: 20px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 !important;
    }

    .why-us-text-box p.common-text {
        font-size: 16px;
    }

    .why-us-box {
        padding: 50px 0 40px 0 !important;
    }

    .why-us-box-btn {
        margin: 40px 0 !important;
    }

    .why-us-box-flex .number-box {
        width: 100%;
        margin-bottom: 15px;
    }

    .number-box:after {
        display: none;
    }
}