.accordian-box-faq {
    margin-top: 30px;
}

.transform-ai-box-main.space-second-faq .transform-ai-box {
    margin-bottom: 0 !important;
}

.transform-ai-box-main.space-second-faq {
    margin-top: 80px;
    margin-bottom: 80px
}

.accordian-box-faq .accordion-item {
    margin-bottom: 15px !important;
    border-radius: 10px !important;
    overflow: hidden;
    background: #F7FCFF !important;
    border: none !important;
    box-shadow: 0px 4px 28.4px 0px #0000001A !important;
    width: 80%;
    margin: 0 auto;
}

.accordian-box-faq .accordion-item .accordion-button[aria-expanded="true"] {
    border-bottom: 1px solid #EBEBEB;
}

.accordian-box-faq .accordion-item .accordion-button {
    font-size: 18px !important;
    color: #131313;
    font-weight: 500;
    padding: 25px !important;
    box-shadow: none !important;
    background: #F7FCFF !important;
}

.ai-industry-services-box .accordion-button::after {
    background-image: none !important;
    content: '\f063' !important;
    font-family: 'FontAwesome' !important;
    font-size: 21px;
}

.ai-industry-services-box .accordian-box-faq .accordion-item .accordion-button[aria-expanded="true"]:after {
    left: -7px;
    position: relative;
    color: #80B927 !important;
}

.transform-ai-box {
    background-image: url('../../../public/images/ai-tranform.svg') !important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 50px;
    background-color: #F8FCFF;
    border-radius: 5px;
    margin-bottom: 0px;
    height: 350px;
    position: relative;
}

.transform-ai-box:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #131313;
    opacity: 0.5;
    left: 0;
    top: 0;
    border-radius: 5px;
}

.transform-ai-box p {
    font-size: 18px;
    width: 60%;
    line-height: 32px;
    position: relative;
    z-index: 99;
    color: #ffffff;
}

.transform-ai-box .view-all-button a {
    width: 250px;
    height: 50px !important;
    margin-top: 30px;
    font-size: 16px !important;
    position: relative;
    z-index: 999;
    border-color: #ffffff;
    color: #ffffff;
}

.transform-ai-box .view-all-button {
    display: flex;
    justify-content: flex-start;
}

@media(max-width: 950px) {
    .accordian-box-faq .accordion-item {
        width: 100% !important;
    }
}

@media(max-width: 450px) {
    .transform-ai-box p {
        width: 100% !important;
        font-size: 16px;
    }

    .accordian-box-faq .accordion-item .accordion-button {
        padding: 25px 30px !important;
    }

    .transform-ai-box {
        padding: 20px;
        height: 100%;
    }

    .accordian-box-faq .accordion-item {
        width: 100% !important;
    }
}