.who-we-serve-section {
    padding: 90px 0px;
    background-color: #F3F5F9;
}

.who-we-serve-section .hire-us-icon {
    padding: 15px;
}

.main-card {
    height: 100%;
}


@media(max-width: 768px) {
    .tab-item p {
        font-size: 18px;
    }
}