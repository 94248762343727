.about-testimonial-box {
    background-color: #F3FAFF;
    padding: 65px 0;
    position: relative;
}



.quote-images-box img.quote-image {
    position: relative;
    top: -9px;
    left: -3px;
}

.quote-images-box img.end-quote {
    transform: rotateY(180deg);
    position: relative;
    bottom: -3px;
    right: -3px;
}

.quote-images-box img {
    width: 16px;
    opacity: 0.8;
}

.clinet-logo-image img {
    width: 100%;
    height: 100%;
}

.clinet-logo-image {
    width: 100%;
    height: 100%;
}

.about-slider-arrow.arrow-developing-slider {
    justify-content: flex-start !important;
}

.about-testimonial-box:after {
    position: absolute;
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    width: 330px;
    height: 65%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: 0;
    z-index: 1;
}

.about-testimonial-box:before {
    position: absolute;
    content: '';
    background-image: url('../../../public/images/testimonial-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 40%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 9;
}

.about-testimonial-content-box p img {
    margin-left: 7px;
    width: 49px;
}

.about-testimonial-content-box h1 {
    text-align: left !important;
}

.about-testimonial-content-box p {
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}

.about-testimonial-content-box p i {
    color: #ffc107;
}

.client-brand-images {
    /* margin-top: 50px; */
}

.clinet-logo-image {
    margin-bottom: 20px;
}

h3.about-testimonil-heading {
    font-size: 26px;
    font-weight: 500;
    margin-top: 15px;
}

.about-testimonial-card {
    background-color: #ffffff;
    border-radius: 5px;
    height: 500px;
    padding: 70px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
}

.about-testi-image {
    width: 60px;
    height: 60px;
}

.about-testi-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100px;
}

.about-test-profile-card {
    display: flex;
    align-items: center;
}

.about-prof-text {
    padding-left: 10px;
}

.about-prof-text h2 {
    font-size: 18px;
    font-weight: 500;
    color: #78BC22;
    margin-bottom: 5px;
}

.about-prof-text p {
    font-size: 16px;
    color: #000000;
}

.brnad-logo-testi {
    display: flex;
    /* justify-content: center; */
    margin: 20px 70px;
}

.brnad-logo-testi img {
    width: 120px;
}

.center-testimonial-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    position: relative;
    z-index: 99;
}

@media (min-width: 1400px) {
    .about-testimonial-content-box {
        margin-top: 60px;
    }
}
  


/* .about-testimonial-card p:before {
    position: absolute;
    content: '\f10e';
    font-family: 'FontAwesome';
    font-size: 16px;
    top: -10px;
    left: 0;
    transform: rotateY(180deg);
}

.about-testimonial-card p:after {
    position: absolute;
    content: '\f10d';
    font-family: 'FontAwesome';
    font-size: 16px;
    bottom: -9px;
    right: 12px;
    transform: rotateY(180deg);
} */

.about-testimonial-card p {
    font-weight: 400 !important;
    padding: 0 20px;
    position: relative;
}

.about-slider-arrow {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.about-slider-arrow img {
    width: 25px;
    cursor: pointer;
}

.arrow.prev-arrow {
    margin-right: 15px;
    cursor: pointer;
}

.center-testimonial-box .swiper-slide.swiper-slide-next {
    transform: scale(0.85) !important;
}

.swiper-slide {
    transition: all 0.3s ease-in-out !important;
}

.quote-left,
.quote-right {
    color: #78BC22;
}

.quote-left {
    margin-left: -15px;
}

.fa-quote-right:before {
    position: relative;
    bottom: -15px;
}

@media(max-width: 450px) {
    .about-testimonial-box:before {
        display: none;
    }

    .about-testimonial-box:after {
        width: 100% !important;
    }

    .contact-side-form form.row.contact-side-form-box {
        padding-top: 10px;
    }

    .about-testimonial-content-box p img {
        margin-left: 0 !important;
    }

    .center-testimonial-box .swiper-slide.swiper-slide-next {
        transform: scale(1) !important;
    }
}

@media(max-width: 450px) {
    .about-testimonial-box {
        height: 100%;
    }

    .about-testimonial-box:before {
        width: 50%;
        height: 100%;
    }

    .clinet-logo-image {
        width: 100% !important;
    }

    .about-slider-arrow {
        margin-bottom: 30px;
    }

    .about-testimonial-card {
        height: 100% !important;
        padding: 30px !important;
    }
}

@media(max-width: 1100px) {
    .clinet-logo-image img {
        width: 70px;
    }
}

@media(max-width: 950px) {
    .clinet-logo-image img {
        width: 100%;
    }

    .about-testimonial-box:before {
        height: 100%;
        transform: translateY(0) !important;
        top: 0 !important;
    }

    .about-testimonial-box {
        height: inherit !important;
        padding: 50px 0;
    }

    .about-testimonial-content-box p {
        flex-wrap: wrap;
    }

    .about-testimonial-card {
        height: 100% !important;
    }

    .clinet-logo-image {
        width: 100%;
    }
}