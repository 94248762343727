.contact-section-box {
    padding: 60px 0;
    /* background-image: url('../../../public/images/contact-bg.png'); */
    /* background-image: url('../../../public/images/bottom-image.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #062640;
}

.contact-text-side.center-box-contract-section.flex-start-box {
    justify-content: flex-start !important;
}

.contact-text-box h1 span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

.contact-text-box h1 {
    font-size: 40px;
    color: #ffffff;
    line-height: 45px;
    width: 80%;
    font-weight: 600;
}

.skilled-contact-section {
    position: relative;
    padding-bottom: 25px;
    padding-top: 25px;
    height: 100%;
    width: 90%;
}

.skilled-contact-section:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #80B927 0%, #FFFFFF 32.4%, #18275B 100%);
    bottom: 0;
    border-radius: 100px;
}

.bottom-contact-image {
    position: relative !important;
    top: -250px !important;
    left: -88px !important;
    width: 360px !important;
}

.contact-left-image img {
    width: 100%;
}

.contact-left-image.bottom-image-contact {
    margin-bottom: 0 !important;
    margin-top: 50px !important;
}

.contact-left-image {
    width: 290px;
    margin-bottom: 30px;
}


.contact-side-form-box {
    color: #ffffff !important;
}

.contact-side-form-box .form-control {
    border-radius: 0 !important;
    background-color: transparent;
    border: none !important;
    border-bottom: 1.12px solid #FFFFFF !important;
    box-shadow: none !important;
    color: #b8b8b8;
    font-size: 16px;
    padding-left: 0;
}

.contact-side-form-box .form-floating label {
    padding-left: 0;
}

.contact-side-form-box .form-floating {
    margin-bottom: 10px;
}

.contact-side-form-box .form-check-input {
    margin-right: 15px;
}

.contact-side-form-box .form-floating label,
.contact-side-form-box .form-check-label {
    color: #ffffff !important;
}

.contact-section-button {
    margin-top: 30px;
}

.contact-section-button button:hover {
    background-color: #80B927 !important;
    border-color: #80B927 !important;
}

.contact-section-button button {
    width: 140px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #80B927;
    border-color: #80B927;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
}

.contact-section-button button.btn.btn-primary:hover {
    color: #80B927 !important;
    background-color: transparent !important;
}

.center-box-contract-section {
    justify-content: center !important;
}

@media(max-width: 330px) {
    .contact-left-image {
        margin-bottom: 50px;
    }
}

.custom-border-box {
    display: block;
    position: relative;
}

.custom-bottom-line-checkBox {
    display: block;
    background-color: #343a40;
    color: #ffffff;
    border-radius: 5px;
    /* padding: 20px; */
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 30px;
    height: 100px;
}

.custom-border-box input[type="checkbox"]:checked+.custom-bottom-line-checkBox {
    background-color: #80B927;
}

.custom-border-box .custom-card {
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 20px;
    border-radius: 5px;
}

.custom-border-box input[type="checkbox"]:checked+.custom-bottom-line-checkBox .custom-card-title {
    color: white;
}

.custom-form-check-input {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    border: 2px solid transparent;
    background-color: transparent;
    box-shadow: none !important;
    cursor: pointer;
    appearance: none;
}

.custom-bottom-line-checkBox::before {
    content: '';
    position: absolute;
    right: 18px;
    top: 20px;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 2px solid white;
    background-color: transparent;
    border-radius: 2px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    font-size: 8px;
    padding-left: 2.5px;
}

.custom-border-box input[type="checkbox"]:checked+.custom-bottom-line-checkBox::before {
    background-color: #80B927;
    border-color: #ffffff;
    content: '\f00c';
    font-family: 'FontAwesome';
}

.custom-border-box input[type="checkbox"]:checked+.custom-bottom-line-checkBox .custom-card-title {
    color: #ffffff;
}

.custom-card-header i {
    font-size: 56px;
}

.custom-card-body h5 {
    margin: 10px 0 0 0;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
}

.custom-card-header img {
    width: 45px;
    height: 40px;
    object-fit: contain;
}

.custom-border-box label.custom-bottom-line-checkBox.unchecked:before {
    content: '' !important;
    border: 2px solid white !important;
    background-color: transparent !important;
}

label.custom-bottom-line-checkBox.unchecked {
    background-color: #343a40 !important;
}

.contact-side-form option {
    background: #062640;
}

.height-100-vh {
    height: 100vh;
    display: flex;
    align-items: center;
}

@media(max-width: 950px) {
    .custom-border-box .custom-card {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .height-100-vh {
        height: 100% !important;
    }

    .custom-card-body h5 {
        text-align: left;
    }

    .custom-card-header img {
        width: 35px;
        height: 35px;
    }
}

@media(max-width: 380px) {
    .custom-border-box .custom-card {
        padding: 10px !important;
        flex-direction: column;
        align-items: flex-start;
    }

    .custom-card-body h5 {
        font-size: 14px;
        text-align: left;
        margin: 0 !important;
    }
}

.contact-mail-to-text img {
    margin-right: 10px;
}

.contact-mail-to-text:hover {
    color: #80B927;
}

.contact-mail-to-text {
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    width: fit-content;
}

p.contact-mail-to-text {
    color: #ffffff !important;
    cursor: inherit !important;
}