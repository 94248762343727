/* Chatbot Container */
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* Chat Button */
.chat-button {
  font-size: 24px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #80b927;
  color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-button img {
  width: 100%;
}

.chat-button:hover {
  transform: scale(1.1);
  background-color: #66921f;
}

/* Chat Window */
.chat-window {
  width: 380px;
  max-height: 500px;
  border-radius: 15px;
  overflow: hidden;
  animation: fadeInUp 0.3s ease-in-out;
  padding: 0.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background: #ffffff;
}

/* Chat Header */
.chat-header {
  border-radius: 50px !important;
  padding: 12px 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #6fa123, #062640);
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.chat-header button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.3s, transform 0.2s ease-in-out;
}

.chat-header button:hover {
  opacity: 0.8;
  transform: scale(1.1);
}

.chat-header button:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.6);
}


/* Chat Messages */
.chat-messages {
  overflow-y: auto;
  max-height: 350px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

/* Chat Bubbles */
.chat-bubble {
  max-width: 75%;
  padding: 12px;
  margin: 5px 0;
  border-radius: 15px;
  word-wrap: break-word;
  font-size: 14px;
}

/* User Message */
.user {
  align-self: flex-end;
  background-color: #062640;
  /* background: linear-gradient(135deg, #6fa123, #5b8fba); */
  color: white;
  text-align: right;
  border-radius: 15px 15px 0px 15px;
}

/* Bot Message */
.bot {
  align-self: flex-start;
  background-color: #f1f1f1;
  color: #062640;
  text-align: left;
  border-radius: 15px 15px 15px 0px;
}

/* Options */
.options {
  display: flex;
  background-color: #80b927;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 10px;
  font-weight: bold;
}

.options:hover {
  background-color: #66921f;
}

/* Chat Footer */
.chat-footer {
  padding: 10px;
  border-radius: 0 0 15px 15px;
  background: none;
  border: none;
}

/* Input Field */
.input-message-box {
  border: 1px solid #ddd;
  border-radius: 50px;
  padding: 8px 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.message-input {
  border: none !important;
  width: 100%;
  font-size: 14px;
  padding: 8px;
  outline: none;
}

/* Send Button */
.btn-circle {
  border-radius: 50px;
  padding: 8px;
  background: none;
  border: none;
  transition: 0.3s;
}

.btn-circle i {
  color: #80b927;
  font-size: 20px;
}

.btn-circle:hover i {
  color: #66921f;
}

/* Scrollbar Customization */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Typing Indicator */
.typing {
  text-align: left;
  margin-left: 10px;
}

.typing span {
  font-size: 11px;
}

.typing-icon {
  font-size: 15px;
  color: #80b927;
}

/* URL Link */
.url-link {
  color: #80b927;
  font-weight: bold;
}

.url-link:hover {
  color: #062640;
}

.btn-suggestion-design{
  padding: 6px 20px;
  background-color: transparent;
  border-radius: 50px;
}
.btn-suggestion-design:hover{
  background-color: #e2e2e2;
}

.icon-size{
  font-size: 15px;
}

.robot-heading{
  font-size: 19px;
  font-weight: 200;
}

/* Smooth Fade Animation */
@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
