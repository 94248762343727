.brand-images-box-main {
    padding: 0px 0 0 0;
    background-color: #ffffff;
}

.brand-image.width-200 {
    width: 80%;
}

.right-border-line {
    position: relative;
}

.right-border-line:before {
    position: absolute;
    content: '';
    background-color: #748494;
    height: 100%;
    width: 1px;
    right: 0;
}

.brand-image {
    width: 60%;
    height: 130px;
    margin-bottom: 30px !important;
    margin: 0 auto;
}

.brand-image img:hover {
    transform: translateY(-10px);
}

.brand-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s ease-in-out;
}

@media(max-width: 950px) {
    .brand-image {
        width: 85px;
    }

    .clutch-image-box {
        width: 100% !important;
    }

    .brand-image.width-200 {
        width: 100%;
    }

    .brand-image {
        height: 100%;
    }

    .brand-image img {
        width: 100%;
    }
}

.black-box-main {
    /* background-color: #000000; */
    background-image: url('../../../public/images/bottom-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 50px;
    color: #ffffff !important;
    /* border-radius: 5px; */
}

.brand-images-text-box h1 {
    font-size: 40px;
    color: #ffffff;
    text-align: center;
}

.brand-images-text-box p {
    color: #ffffff;
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
}

.flex-box-main-side {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.brand-images-text-box {
    width: 100%;
}

.circle-boxes-here {
    width: 35%;
    /* margin-bottom: 70px; */
}

.circle-boxes-main-box {
    display: flex !important;
    justify-content: space-around !important;
    margin-top: 65px !important;
}

.circle-box-round p {
    text-align: center;
    /* width: 50%; */
    margin: 0 auto;
    font-size: 20px;
    font-weight: 600;
}

.circle-box-round h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}

.circle-boxes-here {
    position: relative;
    width: 150px;
    height: 150px;
}

.circle-box-round {
    /* width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; */
    position: relative;
}

.animate-spin-slow {
    /* animation: spin 20s linear infinite; */
    position: absolute;
    top: -28px;
}

@media(max-width: 950px) {
    .animate-spin-slow {
        top: -38px;
        width: 130px;
        right: -50%;
        transform: translateX(-50%);
    }
}

.Brand-Icon-1,
.Brand-Icon-2,
.Brand-Icon-3 {
    width: 45%;
}

.Brand-Icon-4 {
    width: 95%;
}

.Brand-Icon-5,
.Brand-Icon-6 {
    width: 68%;
}

.brand-images-box {
    padding-top: 0px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fill-white {
    fill: #ffffff;
    font-size: 10px !important;
}

@media(max-width: 950px) {
    .circle-boxes-here {
        width: 150px;
        height: 150px;
    }

    .brand-images-text-box p {
        font-size: 18px;
    }

    .brand-images-text-box h1 {
        font-size: 32px;
    }

    .circle-box-round h2 {
        font-size: 26px;
    }

    .brand-images-text-box {
        width: 100%;
    }

    .circle-box-round {
        margin-top: -10px;
        position: relative;
        right: -21px;
        top: -6px;
    }

    .circle-box-round p {
        font-size: 16px;
    }
}

@media(max-width: 450px) {
    .flex-box-main-side {
        flex-direction: column;
    }

    .circle-box-round {
        margin-top: 2px !important;
    }

    .brand-images-box-main {
        padding-top: 0 !important;
    }

    .circle-boxes-main-box {
        flex-direction: column !important;
        margin-top: 0 !important;
    }

    .right-border-line:before {
        display: none;
    }

    .black-box-main {
        padding: 40px 30px;
    }

    .circle-boxes-here {
        margin: 0 auto;
        margin-top: 50px;
    }

    .animate-spin-slow {
        width: 100% !important;
    }

    .clutch-image-box {
        display: flex;
        justify-content: center;
        margin: 0 !important;
    }

    .clutch-image-box img {
        width: 150px;
        margin: 0 auto;
    }

    .circle-box-round {
        right: 0 !important;
    }

    .brand-images-text-box,
    .circle-boxes-main-box {
        width: 100%;
    }
}