.form-control.grey-color {
    color: #ffffff;
    font-size: 16px !important;
}

form.row.contact-side-form-box .select-arrow-down .css-1tupica-control,
form.row.contact-side-form-box .select-arrow-down .css-hlgwow {
    padding: 0px !important;
}

.select-arrow-down .css-1u9des2-indicatorSeparator {
    display: none;
}

.select-arrow-down .select__value-container.select__value-container--is-multi.css-hlgwow {
    padding: 0 !important;
    border: none !important;
}

.select-arrow-down {
    position: relative;
}

.select-arrow-down:before {
    position: absolute;
    /* content: '\f078'; */
    font-family: 'FontAwesome';
    width: 10px;
    height: 10px;
    font-size: 14px;
    right: 10px;
    top: 25px;
}

.form-control.grey-color option {
    color: #000000 !important;
}