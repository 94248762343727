.custom-section {
    position: relative;
    height: 300vh;
}

.custom-sticky-container .hire-us-box {
    width: 100% !important;
}

.custom-sticky-container {
    position: sticky;
    top: 0;
    display: flex;
    height: 97vh;
    align-items: center;
    overflow: hidden;
}

.flex-box-main .hire-us-card {
    width: 490px !important;
    margin-right: 30px !important;
    margin-top: 30px;
}

.flex-box-main {
    display: flex;
    float: left;
}

@media(max-width: 950px) {
    .custom-sticky-container {
        /* height: auto !important; */
    }
}

@media(max-width: 450px) {
    .flex-box-main .hire-us-card {
        width: 330px !important;
    }

    .custom-sticky-container {
        height: auto !important;
    }
}