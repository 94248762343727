.developing-solution {
    padding: 65px 0 85px 0;
    background-color: #ffffff;
    display: none;
}

.case-study-image {
    width: 100px !important;
    /* height: 100px !important; */
}

.case-study-image img {
    width: 100% !important;
    /* height: 100% !important; */
    /* object-fit: contain; */
}

.engagment-text-box p {
    font-size: 16px;
    margin-bottom: 0;
    color: #000c;
}

.row.border-box-industry.border-node-remove.border-javascript-remove .none-border:before {
    display: none;
}

.engagment-text-box h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
}

.view-all-button a:hover {
    background-color: #80b927 !important;
    border-color: #80b927 !important;
    color: #ffffff !important;
}

.view-all-button a {
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    color: #000000;
    text-decoration: none;
    font-size: 15px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
}

.view-all-button {
    display: flex;
    justify-content: flex-end;
}

.arrow-developing-slider img.left-image {
    transform: rotate(180deg);
    margin-right: 15px;
}

.arrow-developing-slider img {
    cursor: pointer;
}

.arrow-developing-slider {
    display: flex;
    justify-content: flex-end;
}

.developing-card-image {
    width: 100%;
    height: 100%;
}

.developing-card-image img {
    width: 100%;
    height: 100%;
}

.developing-solution-box {
    position: relative;
    width: 100%;
    height: 570px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    margin-top: 50px;
}

.developing-solution-box:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #4f4f4f;
    opacity: 0.8;
}

.text-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-title-box h2 span {
    font-weight: 300;
}

.developing-card-text-box h5 {
    font-size: 18px;
    margin-bottom: 10px;
}

.text-title-box {
    border-bottom: 2px solid #ffffff;
    padding-bottom: 10px;
}

.text-title-box h2 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
}

.text-title-box p {
    margin: 0;
    font-size: 15px;
}

.developing-card-text-box {
    padding: 50px 30px;
    position: absolute;
    z-index: 99;
    top: 0;
    width: 100%;
    color: #ffffff !important;
    transition: all 0.3s ease-in-out;
    transform: translateY(0px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.developing-text-under-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    transition: all 0.3s ease-in-out;
}

.developing-text-under-box img.cluth-logo {
    width: 65px;
}

.developing-text-under-box img {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.porfile-image {
    width: 60px;
    height: 60px;
}

.porfile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
}

.profile-text-side {
    padding-left: 15px;
}

.profile-text-side h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
}

.profile-text-side h5 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 300;
}

.profile-text-main-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translate(0px, 0px);
    transition: all 0.3s ease-in-out;
    margin-top: 30px;
}

.profile-text-main-box i:hover {
    color: #80b927;
    transform: translateX(5px);
}

.profile-text-main-box i {
    font-size: 26px;
    cursor: pointer;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
}

.profile-box-main {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.developing-card-text-box p.common-text {
    color: #ffffff;
    margin: 25px 0;
    transform: translate(0px, 0px);
    transition: all 0.3s ease-in-out;
}

/* .developing-solution-box:hover:before {
    background-color: #4f4f4f;
    opacity: 0.8;
} */

/* .developing-solution-box:hover .developing-card-text-box {
    transform: translateY(0);
}

.developing-solution-box:hover .profile-text-main-box {
    justify-content: space-between !important;
}

.developing-solution-box:hover p.common-text,
.developing-solution-box:hover .profile-text-main-box {
    transform: translate(0, 0) !important;
} */

.profile-text-side img {
    width: 100px;
}

@media(max-width: 330px) {
    .developing-solution .developing-solution-box {
        height: 380px !important;
    }

    .text-title-box h2 {
        font-size: 21px !important;
    }

    /* .developing-solution .developing-card-text-box {
        transform: translateY(50px);
    } */

    .developing-card-text-box p.common-text {
        display: none;
    }
}

@media(max-width: 450px) {
    .arrow-developing-slider {
        margin-top: 20px;
    }

    .view-all-button a {
        margin-top: 30px !important;
        margin-bottom: 20px !important;
    }

    .engagment-text-box h2 {
        font-size: 28px;
    }

    .developing-solution {
        padding: 50px 0 !important;
    }

    .developing-solution-box {
        height: 700px !important;
    }

    /* .developing-card-text-box {
        transform: translateY(425px);
    } */
}

@media(max-width: 950px) {
    .engagment-text-box h2 {
        font-size: 32px;
    }

    .developing-solution-box {
        margin-top: 30px;
    }
}

@media(max-width: 1025px) {
    .developing-solution-box {
        height: 580px;
    }

    .developing-card-image img {
        object-fit: cover;
    }
}

@media(max-width: 768px) {
    .developing-solution {
        padding: 60px 0;
    }
}