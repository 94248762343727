.road-map-box {
    padding: 80px 0;
    /* background-image: url('../../../public/images/inductry-bg.png');
    background-repeat: no-repeat;
    background-size: cover; */
    width: 100%;
}

.width-control-react .road-image-box {
    width: 100% !important;
    left: 0 !important;
    transform: scale(1.15) !important;
    top: 13% !important;
}

.big-roadmap-box {
    margin-top: 80px;
}

.road-map-box h3 {
    color: #ffffff;
}

.proven-roadmap-text {
    margin-top: 35px;
}

.proven-roadmap-text p {
    color: #ffffff;
    text-align: right;
}

.proven-roadmap-text h2 {
    color: #ffffff;
    font-size: 21px;
    text-align: right;
}

.adjust-size-sqa .road-image-box {
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    top: 54%;
}

.road-image-box {
    position: absolute;
    width: 90%;
    left: 35%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.road-image-box img {
    width: 100%;
}

.prover-box-map-left {
    padding-top: 110px;
}

.proven-roadmap-text.road-map-left-space {
    margin-bottom: 20px;
}

.right-box-text h2,
.right-box-text p {
    text-align: left !important;
}


.data-and-analytics-roadmap .proven-roadmap-text.road-map-left-space {
    margin-bottom: 20px !important;
}

.data-and-analytics-roadmap .road-image-box {
    width: 480px !important;
    top: 55% !important;
    left: 55% !important;
}

.space-top-set .road-image-box {
    top: 47% !important;
}

.width-control .road-image-box {
    width: 87%;
    top: 56%;
}

.row.width-control-python {
    margin-top: 50px;
}

.row.width-control-python .right-box-text .proven-roadmap-text {
    margin-bottom: 80px;
}

.row.width-control-python .proven-roadmap-text {
    margin-bottom: 50px !important;
    margin-top: 0 !important;
}

.row.width-control-python .road-image-box {
    width: 90%;
    top: 0;
    left: 37%;
    transform: translateX(-50%);
}

.width-control-roadmap-6 .prover-box-map-left {
    padding-top: 92px !important;
}

.width-control-roadmap-6 .road-image-box {
    width: 100% !important;
    left: 0 !important;
    height: 510px !important;
    top: 30px !important;
    transform: translateY(0) !important;
}

@media(max-width: 950px) {
    .none-on-tab-box {
        display: none;
    }

    .width-control-roadmap-6 .prover-box-map-left {
        padding-top: 0 !important;
    }

    .prover-box-map-left {
        padding-top: 0 !important;
    }

    .proven-roadmap-text p,
    .proven-roadmap-text h2 {
        text-align: center !important;
    }

    .proven-roadmap-text h2 {
        -webkit-text-fill-color: #0000;
        text-fill-color: #0000;
        background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 600;
    }
}

@media(max-width: 450px) {
    .road-image-box {
        display: none;
    }

    .road-map-box {
        padding: 50px 0 !important;
    }

    .proven-roadmap-text h2,
    .proven-roadmap-text p,
    .right-box-text h2,
    .right-box-text p {
        text-align: center !important;
    }

    .proven-roadmap-text {
        margin-top: 0;
        margin-top: 20px;
    }

    .prover-box-map-left {
        padding-top: 0;
    }
}

@media(max-width: 1100px) {
    .road-image-box img {
        width: 90%;
        margin-left: 35px;
    }
}