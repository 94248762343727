.modal-body-wrapper {
  padding: 2rem;
  background: #1c232d;
  color: white;
  border-radius: 20px;
  position: relative;
  z-index: 9999;
  overflow-y: auto;
}

.custom-btn-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.modal-body-wrapper p {
  font-size: 16px;
  font-weight: 300;
}

.modal-body-wrapper a {
  text-decoration: none;
  color: #80b927;
}

.cookies-accept-btn {
  height: 42px;
  width: fit-content;
  padding: 0rem 1rem;
  background-color: #80b927;
  border: 1px solid transparent;
  border-radius: 8px;
  color: white;
  transition: all 0.3s ease-in-out;
}

.cookies-accept-btn:hover {
  color: #80b927;
  border: 1px solid #80b927;
  background-color: transparent;
}

.customize-cookies-btn {
  height: 42px;
  width: fit-content;
  padding: 0rem 1rem;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 8px;
  color: white;
  transition: all 0.3s ease-in-out;
}

.customize-cookies-btn:hover {
  color: black;
  border: 1px solid transparent;
  background-color: white;
}

.custom-modal-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.custom-pop-up .popup-content {
  position: fixed !important;
  bottom: 10px;
  right: 10px;
}
/* Adjust size for mobile screens */
@media (max-width: 768px) {
  .custom-pop-up .popup-content {
    width: 90%;
    right: 5%;
    bottom: 20px;
  }
}
