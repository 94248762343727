.ceo-intro-main-box.meet-our-team-box {
    background-color: #ffffff !important;
    background: #ffffff !important;
}

.intro-text h1 {
    text-align: left;
}

.intro-text p {
    font-size: 28px;
    color: #3d3d3d;
    font-weight: 300;
    margin: 10px 0 0 0;
}

.intro-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ceo-image:before {
    position: absolute;
    content: '';
    background-image: url('../../../public/images/life-at-bitsclan/mouse.svg');
    width: 251px;
    height: 300px;
    background-repeat: no-repeat;
    right: -125px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.ceo-image {
    width: 85%;
    height: 600px;
    /* margin: 0 auto; */
    position: relative;
}

.ceo-image img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: -1px 0px 40px 40px rgba(102, 102, 102, 0.16);
    position: relative;
    z-index: 999;
}

@media(max-width: 1100px) {
    .ceo-image:before {
        width: 100% !important;
        right: 0;
    }
}

@media(max-width: 950px) {
    .ceo-image {
        height: 430px;
    }
}

@media(max-width: 450px) {
    .ceo-image {
        height: 100% !important;
        width: 100% !important;
    }

    .ceo-image:before {
        width: 100% !important;
        left: 0 !important;
    }

    .intro-text p {
        font-size: 16px;
        margin-bottom: 30px;
    }
}