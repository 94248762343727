.road-map-box {
    padding: 80px 0;
    /* background-image: url('../../../public/images/inductry-bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-image: none !important;
}

.ai-page-roadmap {
    /* height: 100vh; */
    padding: 70px 0 140px 0 !important;
}

.road-map-box.ai-page-roadmap h1.engagment-model-text {
    text-align: center;
    color: #000 !important;
    font-size: 40px;
    font-weight: 600;
}

.road-image-box.ai-development-road-map {
    width: 780px !important;
    left: 52% !important;
    top: 60% !important;
}

.road-map-box h3 {
    color: #000 !important;
    margin-bottom: 0px;
    font-size: 18px;
}

.proven-roadmap-text {
    margin-top: 35px;
}

.proven-roadmap-text p {
    color: #000 !important;
    text-align: right;
}

.proven-roadmap-text h2 {
    color: #000 !important;
    font-size: 21px;
    text-align: right;
}

.road-image-box {
    position: absolute;
    width: 680px;
    left: 50%;
    top: 57%;
    transform: translate(-50%, -50%);
}

.road-image-box img {
    width: 100%;
}

.road-map-box .prover-box-map-left {
    padding-top: 130px;
}

.proven-roadmap-text.road-map-left-space {
    margin-bottom: 100px;
}

.right-box-text h2,
.right-box-text p {
    text-align: left !important;
}

.none-web-box {
    display: none;
}

.road-map-box .proven-roadmap-text {
    margin-top: 90px;
}

.road-map-box p.common-text {
    font-size: 18px;
    font-weight: 400 !important;
}

.road-map-box .proven-roadmap-text h2 {
    font-size: 26px;
}

@media(max-width: 950px) {
    .none-web-box {
        display: block;
    }

    .road-image-box.ai-development-road-map {
        width: 100% !important;
    }

    .none-tab {
        display: none;
    }

    .ai-page-roadmap {
        height: auto !important;
    }
}

@media(max-width: 950px) {
    .none-tab-imge {
        display: none;
    }

    .road-map-box h3,
    .road-map-box p.common-text {
        font-size: 16px;
    }

    .road-map-box .proven-roadmap-text {
        margin-top: 40px;
    }

    .ai-page-roadmap {
        padding: 60px 0 !important;
    }

    .road-map-box.ai-page-roadmap h1.engagment-model-text {
        font-size: 32px;
    }
}

@media(max-width: 450px) {
    .road-map-box {
        padding: 50px 0 !important;
    }
}