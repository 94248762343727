.engagement-card-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

.we-do-engagement {
    background-image: url('../../../public/images/bottom-image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 60px 0px;
}

.we-do-card {
    border: 1px solid #fff;
    border-radius: 14px;
    padding: 35px 15px;
    background: none;
    transition: background 0.3s ease-in-out;
    width: 30%;
    min-width: 300px;
}

.we-do-card:hover {
    background: #80b927;
}

.we-do-engagement h2 {
    font-size: 40px;
    padding-bottom: 50px;
}

.we-do-card h3 {
    font-size: 25px;
}

.we-do-card a {
    font-size: 22px;
    color: #fff;
    text-decoration: none;
}

.we-do-card a i {
    font-size: 14px;
    padding-left: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.we-do-card:hover a i {
    opacity: 1;
}

@media(max-width: 450px) {
    .engagement-card-flex {
        flex-wrap: wrap;
    }
}

@media(max-width: 768px) {
    .we-do-engagement h2 {
        font-size: 32px;
    }

    .engagement-card-flex {
        gap: 20px;
    }

    .we-do-card a {
        font-size: 18px;
    }
}