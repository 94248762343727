.dev-ops-hire-card h3 {
    font-size: 20px;
    margin: 0 0 10px 0;
    font-weight: 600;
}

.dev-ops-hire-card p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    height: auto !important;
}