.ai-services-box {
    padding: 80px 0 80px 0;
    background-color: #ffffff;
}

.generated-ai-card-two {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 16%);
    height: 100%;
    border-radius: 5px !important;
}

.generated-ai-card-two.generated-ai-card.green-card {
    /* background-color: #80B927; */
    background-color: #062640;
}

.generated-ai-card-two.generated-ai-card.green-card h1,
.generated-ai-card-two.generated-ai-card.green-card p {
    color: #ffffff !important;
}

.padding-top-section {
    padding-top: 20px !important;
}

h1.main-heading-text {
    font-size: 40px;
    font-weight: 600;
}

a.common-green-btn i {
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
}

a.common-green-btn:hover i {
    margin-left: 10px;
}

a.common-green-btn {
    color: #80B927;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.generated-ai-card h1 {
    font-size: 26px;
    color: #131313;
    font-weight: 500;
    margin-bottom: 10px;
}

.generated-ai-card:hover {
    transform: translateY(-10px);
}

.generated-ai-card {
    padding: 20px;
    height: 170px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.generated-ai-card.bg-1,
.generated-ai-card.bg-5,
.generated-ai-card.bg-9 {
    border: 1px solid #4A71FB;
    background-color: #FDFDFF;
}

.generated-ai-card.bg-2,
.generated-ai-card.bg-6 {
    background: #FFFEFA;
    border: 1px solid #FECB01;
}

.generated-ai-card p {
    line-height: 27px;
    color: #7a7a7a !important;
    font-size: 18px;
}

.generated-ai-card.bg-3,
.generated-ai-card.bg-7 {
    border: 1px solid #80B927;
    background: #FDFFFA;
    /* margin-top: 20px; */
}

.generated-ai-card.bg-4,
.generated-ai-card.bg-8 {
    /* margin-top: 20px; */
    border: 1px solid #B627B9;
    background: #FFF9FF;
}

.center-box-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.arrow-developing-slider.arrow-slide-box {
    margin-top: 30px;
}

.ai-services-box.ai-development-box-main .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
    /* height: 515px; */
}

.ai-development-box-main .generated-ai-card {
    height: 320px !important;
    margin-top: 25px !important;
}

@media(max-width: 950px) {
    .center-box-text {
        margin-bottom: 30px;
    }

    h1.main-heading-text {
        font-size: 32px;
    }
}

@media(max-width: 450px) {
    .generated-ai-card h1 {
        font-size: 22px !important;
    }
}

@media(max-width: 330px) {
    .web-app-services-box .ai-services-box .generated-ai-card {
        width: 99% !important;
        height: 340px !important;
    }
}

@media(max-width: 450px) {
    h1.main-heading-text {
        font-size: 28px !important;
    }

    .ai-services-box {
        padding: 50px 0 !important;
    }

    .ai-services-box .generated-ai-card {
        width: 99% !important;
        height: 250px !important;
    }

    .center-box-text {
        justify-content: flex-start !important;
    }
}

/* .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper-ai-services .col-md-6:hover{
    transform: translateY(-10px);
}
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper-ai-services .col-md-6{
    transition: all 0.3s ease-in-out;
} */