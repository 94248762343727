.hire-page-hiring-card {
    /* background-color: #FDFDFF; */
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #4A71FB
}

.hire-page-hiring-card-top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hire-page-content-text h3 {
    color: #80b927;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 3px;
}

.hire-page-content-text p {
    color: #292929;
    font-size: 16px;
    margin: 0;
}

.hire-page-icon-text img {
    width: 70px;
    height: 70px;
    padding: 20px;
    background-color: #80b927;
    border-radius: 10px 10px 0 0;
    object-fit: contain;
}

.hire-page-hiring-card-top-section.rounded-10 img{
    border-radius: 0 0 10px 10px;
}

.hire-page-content-text h4 {
    font-size: 18px;
    font-weight: 500;
    color: #80b927;
}

.hire-page-hiring-card-icon img {
    width: 70px;
    padding: 20px;
    background-color: #80b927;
}

@media(max-width: 1100px) {
    .hire-page-hiring-card {
        height: 100%;
    }
}

@media(max-width: 450px) {
    .bottom-space-20 {
        margin-bottom: 30px;
    }

    .guaranteed-results {
        padding: 50px 0;
    }
}

@media(max-width: 950px) {
    .hire-page-hiring-card {
        margin-bottom: 30px;
    }
}