@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

body {
  font-family: "Outfit", sans-serif !important;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: inherit !important;
}

.construction-card-text.quality-text-box h2 {
  font-size: 26px;
  font-weight: 500;
}

.tab-content-box .swiper-wrapper {
  justify-content: space-evenly;
}

.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.space-remove-top-box .big-roadmap-box {
  margin-top: 0 !important;
}

.space-top-future-solution {
  margin-top: 80px;
}

.road-map-space-box {
  margin-top: 80px;
}

h1.common-heading {
  font-size: 26px;
  font-weight: 500;
  margin: 15px 0;
  line-height: 30px;
  text-transform: capitalize;
}

p.common-text {
  font-size: 18px;
  color: #292929;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-software-solution-box .about-testimonial-box {
  height: 680px !important;
}

/* .custom-software-solution-box .about-testimonial-box:before {
  top: 0px !important;
  height: 100% !important;
  transform: translateY(0) !important;
} */

.web-app-services-box-second .mySwiper {
  height: 90vh !important;
}

.web-app-services-box .mySwiper {
  height: 80vh !important;
}

.compress-services-box .mySwiper {
  height: 80vh !important;
}

h4.banner-sub-heading.heading-space-top {
  margin-top: 50px;
}

.health-care-industry-detail {
  padding: 80px 0 !important;
}

html {
  scroll-behavior: smooth;
}

/* RESPONSIVE */

@media(max-width: 1100px) {
  .our-solution-main .solution-text-main h3 {
    font-size: 32px;
  }

  .build-something-row .solution-text-together h1:before {
    right: 44px;
  }
}

@media(max-width: 950px) {
  .custom-software-solution-box .about-testimonial-box {
    height: inherit !important;
  }

  .solution-text-together h1:before {
    right: -50% !important;
  }

  p.common-text {
    font-size: 16px;
  }

  .modal-header button {
    right: 8px !important;
    top: 8px !important;
    border-radius: 2px !important;
  }
}

@media(max-width: 450px) {
  .why-us-text-box h1 {
    font-size: 28px !important;
  }

  .clutch-widget {
    display: none;
  }

  .health-care-software-solution:before,
  .health-care-software-solution:after {
    width: 100% !important;
    left: 0 !important;
  }

  .health-card-content,
  .health-care-container-box,
  .health-care-software-solution,
  .health-care-industry-detail {
    padding: 50px 0 !important;
  }

  h3.health-care-content {
    margin-top: 0 !important;
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .health-care-industry-image {
    padding: 20px !important;
    margin-top: 30px !important;
  }

  h3.sub-heading-box {
    width: 100% !important;
  }

  .brand-icon-industry {
    width: 140px !important;
    height: 100px !important;
  }

  .brand-icon-industry img {
    width: 60px !important;
  }

  .health-care-software-text h1 {
    font-size: 28px !important;
    margin-top: 20px !important;
  }

  .build-something-together {
    height: 100% !important;
  }

  .solution-text-together h1 {
    width: 100% !important;
  }

  .case-study-banner {
    height: 300px;
  }

  .about-content-side-box {
    flex-wrap: wrap;
  }

  .about-case-text {
    width: 50%;
    text-align: center;
  }

  .comapany-black-side {
    padding: 30px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .our-chalenge-text,
  .our-chalenge-text p {
    width: 100% !important;
  }

  .comapany-black-side img.comapny-image-second {
    position: inherit !important;
    /* right: -40% !important; */
    left: 100px !important;
    bottom: -37px !important;
  }

  .our-soluton-text h1 {
    font-size: 28px !important;
  }

  .our-solution-main,
  .build-something-together {
    padding: 50px 0 !important;
  }

  .line-image-solution:before {
    display: none !important;
  }

  .build-something-together .build-something-row {
    flex-direction: column-reverse !important;
  }

  .solution-text-together img,
  .solution-text-together h1:before {
    display: none;
  }

  .comapny-together-content p {
    width: 85% !important;
    /* display: flex !important; */
  }

  .comapny-together-content {
    padding-left: 30px !important;
  }

  .solution-text-together {
    margin-bottom: 30px;
  }

  .our-chalenge-text h1 {
    font-size: 28px !important;
    margin-bottom: 10px !important;
    margin-top: 30px;
  }

  .about-case-text:before {
    display: none;
  }

  .case-study-banner-text h1 {
    font-size: 28px !important;
    text-align: center;
  }

  .reverse-mobile-style {
    flex-direction: column-reverse;
  }

  .solution-text-together h1 {
    font-size: 28px !important;
  }

  .company-logo-image {
    margin-bottom: 20px !important;
  }

  .road-map-space-box,
  .big-roadmap-box {
    margin-top: 0 !important;
  }

  .shedule-call-model-box .d-flex.justify-content-center img {
    width: 70%;
  }

  .shedule-call-model-box .modal-body h1 {
    font-size: 26px !important;
  }

  h1.engagment-model-text {
    font-size: 28px !important;
  }
}

@media(max-width: 1100px) {
  .about-content-side-box .about-case-text {
    padding: 30px 20px 0 20px;
  }

  .about-case-text:before {
    right: 0 !important;
  }
}

@media(max-width: 375px) {

  .why-us-second-text-box h1 {
    font-size: 23px;
  }

  .number-box h1 {
    font-size: 26px;
  }

  .number-boxes-main {
    flex-wrap: wrap;
  }

  .arrow-developing-slider {
    margin-top: 30px;
  }
}

.form-check-input:checked {
  background-color: #80b927 !important;
  border-color: #80b927 !important;
  box-shadow: none !important;
}

.about-testimonial-content-box p.common-text {
  font-size: 18px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s;
}

.contact-banner-side .contact-side-form-box input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}

input {
  appearance: none;
  -webkit-appearance: none;
}

button.navbar-toggler {
  box-shadow: none !important;
}

/*******************BLOG DETAILS BOX**********************/

.blog-detail-image-banner:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(63deg, rgb(6, 38, 64) 60%, rgb(255, 255, 255));
  opacity: 0.5;
}

.blog-detail-image-banner {
  width: 100%;
  height: 88vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.banner-text-box h1 {
  font-size: 45px;
  color: #ffffff;
}

.banner-text-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.blog-other-details {
  padding: 80px 0;
}

.social-icons-box i {
  margin-right: 20px;
  font-size: 45px;
}

.social-icons-box a i {
  color: #292929;
}

.social-icons-box {
  display: flex;
  margin: 20px 0 10px 0;
}

h2.social-icon-heading {
  font-weight: 500;
  font-size: 26px;
  color: #393939;
  margin: 0 0 10px 0;
}

.blog-image img {
  width: 170px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}

.blogs-show-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.blog-image-text h1 {
  color: #393939;
  font-size: 20px;
  font-weight: 500;
  width: 85%;
  margin-bottom: 10px;
  line-height: 30px;
}

.blog-image-text {
  padding-left: 20px;
}

.blog-content-box p {
  font-size: 18px;
  color: #212529;
  line-height: 1.7;
  font-weight: 400;
  margin: 0 0 20px 0;
}

.blog-image-text p {
  color: #7e7e7e;
  font-size: 14px;
}

.blog-image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.blog-image-box {
  width: 80%;
  height: 350px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.name-arrow h4 {
  font-size: 20px;
}

@media(max-width: 950px) {

  .blog-detail-image-banner,
  .case-study-banner {
    height: 400px !important;
  }

  .none-tab-show {
    display: none;
  }

  .case-study-banner-text h1 {
    text-align: center;
  }

  .solution-text-together img {
    top: 46px !important;
    left: -53px !important;
  }

  .about-case-text:before {
    right: -20% !important;
  }

  .comapany-black-side {
    padding: 30px !important;
  }

  .our-chalenge-text p {
    width: 100% !important;
  }

  .comapany-black-side img.comapny-image-second {
    position: relative !important;
    left: 59%;
  }

  .about-content-side-box {
    width: 100% !important;
    flex-wrap: wrap;
  }

  .about-case-text:before {
    display: none;
  }

  .about-case-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .banner-text-box h1 {
    font-size: 32px;
    text-align: center;
  }

  .blog-image-box {
    width: 100%;
  }
}

@media(max-width: 450px) {
  .space-top-future-solution {
    margin-top: 0;
  }

  .blog-other-details {
    padding: 50px 0;
  }

  .banner-text-box h1 {
    font-size: 28px;
  }
}

/*CASE STUDY PAGE STYLE*/

.case-study-banner {
  width: 100%;
  height: 88vh;
  /* background-image: url('../public/images/trend4.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* filter: grayscale(1);/ */
  position: relative;
}

.case-study-banner:before {
  position: absolute;
  content: '';
  background-color: #000000;
  opacity: 0.6;
  width: 100%;
  height: 100%;
}

.case-study-banner-text h1 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 56px;
  font-weight: 600;
  position: relative;
  text-align: center;
}

.case-study-banner-text {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-study-details-box {
  padding: 60px 0 80px 0;
}

.about-case-text {
  padding: 50px 0 0 0;
  position: relative;
}

.about-content-side-box .about-case-text:last-child::before {
  display: none;
}

.about-case-text:before {
  position: absolute;
  content: '';
  border-left: 2px solid transparent;
  height: 75%;
  border-image-source: linear-gradient(180deg, #FFFFFF 0%, #80B927 45.5%, #FFFFFF 100%);
  border-image-slice: 1;
  right: -50px;
  top: 50%;
  transform: translateY(-22%);
}

.about-case-text p {
  margin: 0;
  font-style: italic;
  color: #000c;
  font-weight: 400;
  text-align: center;
}

.about-case-text h1 {
  color: #80B927;
  font-size: 26px;
  font-weight: 600;
  margin: 0 0 10px 0;
  text-align: center;
}

.about-content-side-box {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto;
}

.comapny-image {
  width: 100%;
  height: 100%;
}

.comapny-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comapany-black-side {
  /* background-color: #000000; */
  height: 100%;
  padding: 100px;
  color: #ffffff;
  position: relative;
  background-image: url('../public/images/inductry-bg.png');
  background-position: center;
}

.comapany-black-side img.comapny-image-second {
  width: 180px !important;
  height: 180px !important;
  position: absolute;
  right: 100px;
  bottom: 0px;
}

.comapany-black-side img {
  width: 65px;
  height: 65px;
}

.our-chalenge-text p {
  color: #ffffff !important;
  width: 80%;
}

.our-chalenge-text h1 {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 20px;
}

.our-chalenge-text {
  width: 80%;
  margin: 0 auto;
}

.our-solution-main {
  background-color: #f3f5f9;
  padding: 80px 0 120px 0;
}

.our-soluton-text h1:before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #80B927;
  border-radius: 100px;
  right: -12px;
  bottom: 8px;
}

.our-soluton-text h1 {
  color: #000000;
  font-size: 45px;
  position: relative;
  display: inline-block;
}

.our-soluton-text p {
  margin-bottom: 20px;
  color: #000000;
  font-weight: 400;
}

.solution-text-main {
  display: flex;
  align-items: center;
  height: 100%;
}

.solution-text-main h3 {
  font-size: 45px;
  color: #000000;
  margin: 0;
  line-height: 1.3;
}

.line-image-solution {
  position: relative;
  height: 100%;
}

.line-image-solution:before {
  position: absolute;
  content: '';
  background-image: url('../public/images/SVG/solution-line.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 500px;
  height: 100%;
  top: 35px;
  left: -100px;
}

.content-side-box-together {
  background-image: url('../public/images/inductry-bg.png');
  background-position: center;
  padding: 80px 0;
}

.company-logo-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.company-logo-image img {
  width: 100%;
  height: 100%;
}

.comapny-together-content p {
  font-size: 26px;
  font-weight: 300;
  color: #ffffff;
  width: 90%;
  margin: 0 auto;
  position: relative;
  display: inline;
}

.comapny-together-content p:before {
  position: absolute;
  content: '\f10d';
  font-family: 'FontAwesome';
  background-repeat: no-repeat;
  font-size: 23px;
  left: -25px;
  top: -10px;
  color: #CCE3A9;
}

.comapny-together-content p:after {
  position: absolute;
  content: '\f10d';
  font-family: 'FontAwesome';
  background-repeat: no-repeat;
  font-size: 23px;
  right: -25px;
  bottom: -10px;
  color: #CCE3A9;
  transform: rotateY(180deg);
}

.company-text-owner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;
}

.company-text-owner p {
  font-size: 18px;
  color: #ffffff;
  margin: 0;
  font-weight: 300;
}

.company-text-owner h1 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 3px;
}

.build-something-together {
  width: 100%;
  height: 700px;
  background-image: url('../public/images/case-study-detail.png');
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
}

.solution-text-together h1:before {
  position: absolute;
  content: '';
  background-color: #80B927;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  bottom: 8px;
  right: 45%;
}

.solution-text-together h1 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 45px;
  font-weight: 600;
  position: relative;
  width: 80%;
}

.solution-text-together p {
  color: #ffffff;
  margin: 0;
}

.solution-text-together img {
  width: 50px;
  height: 50px;
  position: relative;
  top: -8px;
  left: -35px;
}

.together-form-box {
  background-color: #00294F;
  padding: 30px;
}

.solution-text-together {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-left: 60px;
}

.about-text-box h1.main-heading span {
  color: #0D71A8FF;
  font-weight: 700 !important;
}

/********************************************************************************************
*********************************************************************************************
 INDUSTRY DETAIL PAGE
*********************************************************************************************
*********************************************************************************************/

.health-card-content {
  padding: 80px 0;
  background-image: url('../public/images/industry-details/detail-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.health-care-content-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #4A71FB;
}

.health-care-content-card p.common-text {
  font-weight: 300;
  margin-bottom: 30px;
}

.health-care-content-card h3 {
  font-weight: 500;
  font-size: 23px;
}

h3.health-care-content span {
  color: #80B927;
  font-weight: 500;
}

h3.health-care-content {
  font-size: 23px;
  font-weight: 300;
  line-height: 40px;
  margin: 0;
  width: 85%;
}

.health-care-counter-box {
  padding: 80px 0;
  /* background-image: url('../public/images/industry-details/overlay.png'); */
  background-color: #072037c9;
  width: 100%;
  height: 100%;
}

.health-care-counter-box-wrap {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

h4.health-card-sub-heading span {
  -webkit-text-fill-color: #0000;
  text-fill-color: #0000;
  background: linear-gradient(84.91deg, #80B927 -0.19%, #C1FD63 63.05%);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 500;
}

h4.health-card-sub-heading {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  margin-top: 40px;
}

.number-boxes-main.health-care-number-box .number-box {
  text-align: center;
}

.number-boxes-main.health-care-number-box {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.brand-icon-industry img {
  width: 80px;
}

.brand-icons-box-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.brand-icon-industry {
  background-color: #F6F6F6;
  padding: 20px;
  width: 190px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 0 10px 15px 10px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.brand-icon-industry:hover {
  border-width: 1px;
  border-style: solid;
  border-image-source: linear-gradient(84.91deg, #80B927 44.81%, #FFE600 67.05%);
  border-image-slice: 1;
  background-color: #ffffff;
  box-shadow: 0px 3.56px 18.05px 0px #0000001A;
}

.bg-color-industry-detail .hire-us-box {
  background: transparent !important;
}

.bg-color-industry-detail {
  background: linear-gradient(0deg, #F9FDFF 0%, #F3F5F9 100%);
  padding-bottom: 10px;
}

.health-care-container-box {
  padding: 80px 0;
}

.health-care-image {
  width: 100%;
  height: 100%;
}

.health-care-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.health-care-software-text p {
  font-size: 18px;
  color: #292929;
  line-height: 30px;
}

.health-care-software-text h1 {
  font-size: 36px;
}

.health-care-software-solution {
  padding: 80px 0;
  background-color: #1B232E;
  position: relative;
}

.health-care-software-solution:after {
  position: absolute;
  content: '';
  background-image: url('../public/images/industry-details/solution-icon1.png');
  background-repeat: no-repeat;
  width: 415px;
  height: 100px;
  right: 0;
  top: 35px;
}

.health-care-software-solution:before {
  position: absolute;
  content: '';
  background-image: url('../public/images/industry-details/solution-icon2.png');
  background-repeat: no-repeat;
  width: 415px;
  height: 100px;
  left: 30px;
  bottom: 15px;
}

.healcare-solution-card {
  background: #272E39;
  padding: 30px;
}

.healcare-solution-card {
  text-align: center;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  background-clip: padding-box;
  height: 100%;
}

.healcare-solution-card:hover {
  background-clip: padding-box;
  border-image-source: linear-gradient(236.87deg, #FECB01 0%, #FFFFFF 49.56%, #80B927 100%);
  border-image-slice: 1;
}

.healcare-solution-card h2 {
  font-size: 23px;
}

.healcare-solution-card p {
  font-weight: 300;
  margin-top: 20px;
  color: #ffffff;
  font-size: 16px;
}

.tab-item.d-flex.flex-row.gap-2.align-items-center p {
  font-size: 18px;
  color: #000000;
}

h1.industries-main-heading {
  font-weight: 600;
}

.css-mmihdb-placeholder {
  margin-left: -6px !important;
  padding-bottom: 10px !important;
}

@media(max-width: 1100px) {
  .comapany-black-side img.comapny-image-second {
    right: 20px;
  }
}

/* BLOG DETAILS CSS */

.blog-detial-image {
  position: relative;
  overflow: hidden;
}

.blog-detial-image img {
  width: 100%;
  height: 585px;
  object-fit: cover;
  border-radius: 15px;
}

.blog-detail-image-text {
  padding: 40px 30px;
  background-color: transparent;
  backdrop-filter: blur(20px);
  color: #ffffff;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 15px 15px;
}

.blog-detail-image-text span:before {
  position: absolute;
  content: '';
  background-color: #80B927;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.blog-detail-image-text span {
  background-color: #F9FFEE;
  padding: 7px 10px 10px 40px;
  position: relative;
  color: #80B927;
  border-radius: 100px;
}

.blog-detail-image-text h2 {
  font-size: 28px;
  margin: 20px 0 10px 0;
  font-weight: 700;
}

.blog-detail-image-text p {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
}

.blog-detail-text-box h4 {
  font-size: 26px;
  font-weight: 600;
  margin-top: 20px;
}

.blog-detail-text-box p {
  margin-bottom: 15px;
}

.blog-detail-text-box p span {
  color: #80B927;
}

.blog-detial-profile-card {
  background-color: #80B927;
  padding: 30px;
  border-radius: 15px;
}

.blog-detail-profile-picture img {
  width: 120px;
  height: 120px;
  border-radius: 15px;
  object-fit: cover;
}

.blog-detial-profile-card {
  margin-bottom: 30px;
}

.blog-detial-image-text h2 {
  color: #ffffff;
  font-size: 28px;
  margin: 13px 0;
}

.blog-detial-image-text p {
  color: #ffffff;
  font-weight: 300 !important;
}

.blog-detail-profile-picture i {
  background-color: #0077B5;
  font-size: 28px;
  color: #ffffff;
  padding: 8px 10px;
  border-radius: 5px;
  margin-left: 25px;
  cursor: pointer;
}

.blog-detail-profile-picture {
  display: flex;
  align-items: flex-end;
}

.blog-detial-profile-card h4 {
  color: #ffffff;
  font-size: 23px;
  margin-bottom: 0;
  font-weight: 400;
}

.social-icons-blog-detail-page i {
  font-size: 36px;
  color: #ffffff;
  margin-right: 15px;
  margin-top: 15px;
  cursor: pointer;
}

.blog-detail-card-list h6 {
  color: #000000;
  font-weight: 600;
  font-size: 23px;
}

.blog-detail-card-list ul li:first-child {
  color: #80B927;
}

.blog-detail-card-list ul li {
  list-style: none;
  line-height: 35px;
  list-style: none;
}

.blog-detail-card-list ul {
  padding-left: 0;
}

.blog-detial-common-image img {
  width: 70%;
  height: 400px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.popup-content {
  background-color: transparent !important;
  border: none !important;
}

.cross-btn-close {
  background-color: #81ba22;
  opacity: 1;
  color: #ffffff;
  padding: 7px 15px;
  top: -20px;
  right: -20px;
  position: absolute;
  border: none;
  border-radius: 5px;
  font-size: 20px;
}

.left-align-content-box .bg-light-grey p {
  text-align: left !important;
  padding-left: 30px;
  color: #000000;
}

.left-align-content-box ul {
  padding-left: 50px;
}

span.active-box,
span.active-box i {
  /* color: #80B927 !important; */
}

span.active-box i {
  color: #80B927 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-left: 3px !important;
}

span.active-box:before {
  transform: scaleX(1) !important;
}

.slider-link {
  margin-top: 20px;
}

ul.blog-detial-list {
  padding-left: 20px;
}

ul.blog-detial-list li {
  font-size: 18px;
  margin-bottom: 10px;
}