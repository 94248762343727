.bitsclan-ai-box {
    padding: 65px 0 75px 0;
    background: linear-gradient(0deg, #F9FDFF 0%, #F3F5F9 100%);
}

.bitsclan-ai-box .quality-matter-box .construction-icon img {
    width: 60px !important;
    margin-bottom: 15px;
}

.quality-matter-box {
    padding: 30px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    margin-top: 30px;
    position: relative;
    height: 93%;
}

.quality-matter-box:before {
    position: absolute;
    content: '';
    width: 93%;
    height: 3px;
    background: linear-gradient(90deg, #18275B 0%, #80B927 48.51%, #FFFFFF 100%);
    right: 0;
    bottom: 0;
}

.quality-matter-box:hover:before {
    display: none;
}

.quality-matter-box:hover {
    background-color: #012E56;
}

.quality-box-main-blue {
    background-color: #012E56;
}

.quality-matter-box:hover .construction-icon img {
    filter: brightness(0) invert(1);
}

.quality-matter-box:hover .construction-card-text.quality-text-box h2,
.quality-matter-box:hover .construction-card-text.quality-text-box p {
    color: #F3F5F9;
}

.quality-matter-box:hover .construction-card-text.quality-text-box h2 span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

.quality-button-box a:hover i {
    transform: translateX(8px);
}

.quality-button-box a i {
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
}

.quality-button-box a {
    color: #80B927;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
}

.quality-button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media(max-width: 450px) {
    .quality-matter-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .bitsclan-ai-box {
        padding: 50px 0 !important;
    }
}