.email-modal-body-wrapper{
  padding: 2rem;
  background: white;
  color: white;
  border-radius: 20px;
  position: relative;
  z-index: 9999;
}

.email-custom-btn-close{
  position: absolute;
  top: -1rem;
  right: -1rem;
  cursor: pointer;
  color: white;
  width: 40px;
  height: 40px;
  background-color: #80b927;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-modal-body-wrapper p{
  font-size: 16px;
  font-weight: 300;
}

.email-modal-body-wrapper a{
  text-decoration: none;
  color: #80b927;
}

.custom-email-modal-footer{
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-email-modal-box .form-control {
  border-radius: 0 !important;
  /* background-color: transparent; */
  border: none;
  border-bottom: 1.12px solid #000000 !important;
  box-shadow: none !important;
  font-size: 14px;
  padding-left: 4px;
  color: #000000;
}

.custom-email-modal-box .form-floating label {
  padding-left: 0;
}

.custom-email-modal-box .form-floating {
  margin-bottom: 10px;
}

.custom-email-modal-box .form-check-input {
  margin-right: 15px;
}

.custom-email-modal-box .form-floating label,
.custom-email-modal-box .form-check-label {
  color: #000000 !important;
}


