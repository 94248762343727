.guaranteed-results {
  background-image: url("../../../public/images/inductry-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f3f5f9;
  width: 100%;
  padding: 80px 0;
}

.guarented-bg-change {
  background-color: #f3f5f9 !important;
  background-image: none !important;
}

.guarented-bg-change h1.guaranteed-results-heading,
.guarented-bg-change p.common-text,
.guarented-bg-change .blue-box-text-main h4,
.guarented-bg-change .blue-box-text-main p {
  color: #000000 !important;
}

h1.guaranteed-results-heading span {
  -webkit-text-fill-color: #0000;
  text-fill-color: #0000;
  background: linear-gradient(84.91deg, #80b927 44.81%, #c1fd63 67.05%);
  -webkit-background-clip: text;
  background-clip: text;
}

h1.guaranteed-results-heading {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
}

.guaranteed-results p.common-text {
  color: #ffffff;
  text-align: center;
}

ul.guaranted-list {
  padding: 0;
  margin-top: 20px !important;
}

ul.guaranted-list li img {
  width: 20px !important;
  margin-right: 10px;
}

ul.guaranted-list li {
  list-style: none;
  font-size: 18px;
  color: #ffffff;
  width: 33%;
  margin-top: 30px;
  float: left;
  font-weight: 400;
  padding-left: 50px;
}

@media (max-width: 360px) {
  ul.guaranted-list li {
    font-size: 15px !important;
  }
}

@media (max-width: 950px) {
  ul.guaranted-list li {
    font-size: 16px;
    width: 50%;
    padding-left: 0;
  }
}

@media (max-width: 450px) {
  ul.guaranted-list li {
    width: 100%;
    padding-left: 20px;
    font-size: 16px;
  }

  h1.guaranteed-results-heading {
    font-size: 28px;
  }

  ul.guaranted-list {
    margin-top: 0;
  }
}

.blue-box-text-main h4 img {
  width: 20px;
  margin-top: -7px;
}

.blue-box-text-main h4 {
  font-size: 22px;
  color: #ffffff;
  margin-top: 25px;
  font-weight: 400;
  text-align: center;
}

.blue-box-text-main p {
  color: #ffffff;
  color: 16px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.blue-box-text-main {
  margin-top: 35px;
}
