.types-of-build-main {
    padding: 60px 0px;
    background-color: #fff;
}

.types-of-build-sideimg img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    position: sticky;
    top: 140px;
}

.type-of-app p {
    font-size: 18px;
}

.types-of-build-content .accordion-button {
    font-size: 30px;
    font-weight: 600;
    color: #80B927;
    border: none;
    box-shadow: none;
    padding-top: 15px;
    transition: color 0.3s ease, padding 0.3s ease, border-bottom 0.3s ease;
}

.types-of-build-content .accordion-button .name-arrow {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
}

.types-of-build-content .accordion-button .name-arrow i {
    transform: rotate(0deg);
    font-size: 20px;
    transition: transform 0.3s ease;
}

.types-of-build-content .accordion-button.collapsed .name-arrow i {
    transform: rotate(180deg);
}

.types-of-build-content .accordion-button.collapsed {
    color: #000;
    padding: 10px 0px;
    border-bottom: 1px solid #CBCBCB;
}

.types-of-build-content .accordion-collapse {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, padding 0.3s ease;
}

.types-of-build-content .accordion-collapse.show {
    max-height: 1000px;
    /* Ensure this value accommodates the maximum content height */
    padding: 10px 0 0 0;
}

.types-of-build-content .accordion-body {
    padding: 5px 20px 0px 0px;
    font-size: 22px;
    color: #000;
    font-weight: 300;
    transition: opacity 0.3s ease;
    opacity: 1;
}

.types-of-build-content .accordion-collapse:not(.show) .accordion-body {
    opacity: 0;
}

.types-of-build-content .accordion-body ul {
    padding: 0px;
}

.types-of-build-content .accordion-body h5 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
}

.types-of-build-content .list-item {
    font-size: 18px;
    display: flex;
    margin: 15px 0px;
    align-items: center;
}

.types-of-build-content .list-item span {
    color: #fff;
    font-size: 10px;
    padding: 5px 5px 3px 5px;
    line-height: 0px;
    margin-right: 15px;
    background-color: #80B927;
    border-radius: 3px;
}

.types-of-build-content h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 53px;
}

@media(max-width: 770px) {
    .types-of-build-content h2 {
        font-size: 32px;
        text-align: center;
    }

    .types-of-build-content .accordion-button {
        font-size: 24px;
    }

    .types-of-build-content .accordion-body {
        font-size: 18px;
    }

    .types-of-build-content .accordion-body h5 {
        font-size: 18px;
    }

    .types-of-build-content .list-item {
        font-size: 16px;
    }
}