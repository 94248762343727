.life-at-bitsclan-box {
    padding: 80px 0;
    background-image: url('../../../public/images/banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.life-banner-box h1 span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

.life-banner-box h1 {
    font-size: 45px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.life-banner-box p {
    text-align: center;
    color: #ffffff;
    margin-bottom: 30px !important;
    width: 80%;
    margin: 0 auto;
}

@media(max-width: 950px) {
    .life-banner-box h1 {
        font-size: 32px;
        width: 100%;
        margin-bottom: 20px;
    }
}

@media(max-width: 450px) {

    .meet-team-image {
        width: 100% !important;
        margin-top: 20px;
    }

    .meet-our-team-box {
        padding: 50px 0 !important;
    }

    .life-banner-box p {
        width: 100%;
    }
}