.perks-and-benifits {
    padding: 80px 0;
    background-image: url('../../../public/images/banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.perk-card:hover p,
.perk-card:hover h3 {
    color: #131313;
}

.perk-card:hover .perk-icon img {
    filter: brightness(0);
}

.perk-card:hover {
    background-color: #FDFDFF;
    border: 1.5px solid #80B927;
}

.perk-card {
    padding: 20px;
    border: 1.5px solid transparent;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.perk-icon {
    width: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.perk-icon img {
    width: 100%;
    filter: brightness(0) invert(1);
}

.perk-card p {
    text-align: center;
    color: #ffffff;
    font-weight: 300;
}

.perk-card h3 {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
}

.perk-icon-leftside .border-cards:nth-child(4),
.perk-icon-leftside .border-cards:nth-child(8) {
    border-right: none !important;
}

.perk-icon-leftside .border-cards:nth-child(5),
.perk-icon-leftside .border-cards:nth-child(6),
.perk-icon-leftside .border-cards:nth-child(7),
.perk-icon-leftside .border-cards:nth-child(8) {
    border-bottom: none !important;
}

.perk-icon-leftside .border-cards {
    border-bottom: 1px solid #80B927;
    border-right: 1px solid #80B927;
}

/* .perk-icon-leftside {
    position: relative;
}

.perk-icon-leftside:before {
    position: absolute;
    content: '';
    background: linear-gradient(180deg, #041128 0%, #80B927 23.82%, #2E2E2E 51.32%, #80B927 75.82%, #05203B 100%);
    width: 100%;
    height: 2px;
} */

@media(max-width: 950px) {
    .perk-icon-leftside .border-cards {
        border: none !important;
    }
}

@media(max-width: 450px) {

    .perks-and-benifits .perk-icon-leftside .border-cards {
        border-bottom: 1px solid #80B927 !important;
    }

    .perks-and-benifits .perk-icon-leftside .border-cards:last-child {
        border-bottom: none !important;
    }
}