.hire-steps-box {
    padding: 80px 0;
}

.hire-step-card {
    position: relative;
}

.hire-page-setps-box .steps-here:last-child .hire-step-card:before {
    display: none;
}

.hire-step-card:before {
    position: absolute;
    content: '';
    background-image: url('../../../public/images/hire-page-setps-icons/the-line.svg');
    background-repeat: no-repeat;
    background-size: 240px;
    width: 85%;
    height: 30px;
    left: 65%;
    top: 18px;
}

.hire-step-card h2 {
    font-size: 26px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 7px;
}

.hire-step-card p {
    text-align: center;
}

.setps-icon {
    display: flex;
    justify-content: center;
    margin: 50px 0 10px 0;
}

.setps-icon img {
    width: 60px;
}

@media(max-width: 330px) {
    .hire-steps-box .row.mt-5 {
        margin-top: 30px !important;
    }
}

@media(max-width: 450px) {
    .hire-steps-box {
        padding: 50px 0 !important;
    }
}

@media(max-width: 950px) {
    .hire-step-card:before {
        display: none;
    }
}