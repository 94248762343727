.life-image {
    width: 100%;
    height: 600px;
    margin-bottom: 30px;
}

.life-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.life-text-box {
    margin-top: 40px;
    margin-bottom: 40px;
}

.life-text-box p {
    font-size: 18px;
    margin: 15px 0 0 0;
    font-weight: 300;
}

.life-text-box h2 {
    font-size: 32px;
    margin: 0;
}

.life-image.left-life-image {
    height: 430px;
}

.top-image-design {
    margin-top: -12.5%;
}

@media(max-width: 450px) {

    .life-image,
    .life-image.left-life-image {
        height: 300px;
    }

    .top-image-design {
        margin-top: 0
    }

    .life-text-box h2 {
        font-size: 28px;
    }
}