.contact-banner-box {
    background-image: url('../../../public/images/banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
}

.flex-center-column {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.contact-banner-awards.flex-end-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
}

.contact-side-form.contact-banner-form {
    background-color: #F7FCFF;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #EBEBEB;
}

.contact-side-form.contact-banner-form .contact-section-button button {
    width: 220px !important;
}

.contact-side-form.contact-banner-form .contact-side-form-box .form-control {
    border-color: #292929 !important;
    color: #292929 !important;
}

.contact-side-form.contact-banner-form .contact-side-form-box .form-floating label,
.contact-side-form.contact-banner-form.contact-banner-side label.form-check-label {
    color: #292929 !important;
}


.contact-us-form-feild-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-us-form-feild-wrapper label {
    color: #333333;
}

.contact-us-form-feild-wrapper input.contact-form-input:focus-within,
.contact-us-form-feild-wrapper textarea.contact-form-input:focus-within {
    border: 1.4px solid #80b927 !important;
}

.contact-us-form-feild-wrapper input {
    border: 1.4px solid #E9E9E9;
    border-radius: 8px;
    height: 56px;
    padding-left: 1rem;
    transition: all 0.3s ease-in-out;
    outline: none;
    background-color: transparent;
}

.contact-us-form-feild-wrapper textarea {
    border: 1.4px solid #E9E9E9;
    border-radius: 8px;
    height: 100px;
    padding-left: 1rem;
    padding-top: 0.7rem;
    background-color: transparent;
    outline: none;
    transition: all 0.3s ease-in-out;
}

.contact-us-radio-wrapper {
    border: 1.4px solid #E9E9E9;
    border-radius: 8px;
    height: 56px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-us-radio {
    width: 14px !important;
    height: 18px !important;
    border-radius: 100px !important;
}

@media(max-width: 950px) {
    .contact-side-form.contact-banner-form {
        margin-bottom: 0 !important;
        margin-top: 50px !important;
    }

    .how-it-works-box {
        padding: 50px 0 !important;
    }
}