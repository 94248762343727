.ai-banner-box {
    background-image: url('../../../public/images/ai-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
    overflow: hidden;
    height: 100vh;
    display: flex;
    align-items: center;
}

.ai-development-box h1 span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

.ai-development-box h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 15px;
}

.ai-development-box p {
    margin-bottom: 25px;
    color: #ffffff;
}

.ai-dev-image img {
    width: 100%;
    height: 100%;
    border: 2px solid red !important;
}

.ai-dev-image {
    width: 100%;
    height: 600px;
    position: relative;
    top: -46px;
    right: -112px;
}

@media(max-width: 950px) {
    .ai-banner-box {
        background-position: center;
        height: auto !important;
    }
}

@media(max-width: 450px) {
    .ai-development-box h1 {
        font-size: 28px !important;
    }

    .ai-banner-box {
        background-position: center !important;
        padding: 50px 0 !important;
    }
}

.engagment-card-button-not-only {
    margin-top: 10px;
}

.engagment-card-button-not-only a {
    font-size: 18px;
}