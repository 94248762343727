.footer-box {
    background-image: url('../../../public/images/footer-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #000000; */
    padding: 80px 0 30px 0;
    /* height: 800px; */
}

h1.footer-heading {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 18px;
    font-weight: 700;
}

ul.accordian-footer-list {
    padding: 0;
}

ul.accordian-footer-list li {
    list-style: none;
    padding: 10px 0;
}

ul.accordian-footer-list li a {
    color: #ffffff;
    text-decoration: none;
}

/*  */

/* .footer-accordian button.accordion-button[aria-expanded=true]:after {
    color: #80B927 !important;
    background-image: none;
    content: '\f078';
    font-family: 'FontAwesome';
} */

.footer-accordian .accordion-button:not(.collapsed)::after {
    /* position: absolute;
    content: '\f078' !important;
    font-family: 'FontAwesome' !important;
    background-image: none !important; */
    transform: inherit !important;
    /* content: '\f077' !important; */
    content: '\f0d8' !important;
}

.footer-accordian button.accordion-button[aria-expanded=true] {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
    /* font-weight: 700 !important; */
}

.footer-accordian button.accordion-button::after {
    margin-left: 6px !important;
    background-image: none;
    /* content: '\f078'; */
    content: '\f0d7';
    font-family: 'FontAwesome';
    color: #ffffff;
    font-size: 13px;
    /* position: relative; */
    top: 2px;
}

.footer-accordian button.accordion-button {
    padding: 10px 0 !important;
    box-shadow: none !important;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
}

.footer-accordian .accordion-body {
    padding: 0;
}

.footer-accordian .accordion-item {
    background: transparent;
    border: none !important;
}

.footer-accordian button.accordion-button {
    background-color: transparent !important;
}

ul.footer-list {
    padding: 0;
}

ul.footer-list li {
    list-style: none;
    padding: 7px 0;
}

ul.footer-list li a:hover {
    color: #80B927;
}

ul.footer-list li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.trust-box-text {
    position: relative;
}

.trust-box-text h1 {
    font-size: 23px;
    font-weight: 500;
    color: #ffffff;
    z-index: 9;
    position: relative;
}

.trust-logo-box {
    margin-top: 15px;
    position: relative;
}

.get-social-text {
    padding-top: 30px;
}

.get-social-text h1 {
    font-size: 23px;
    color: #ffffff;
}

ul.social-icon {
    padding-left: 0 !important;
    display: flex;
}

ul.social-icon li {
    list-style: none;
    margin-right: 15px;
}

ul.social-icon li a {
    color: #ffffff;
    font-size: 26px;
}

.copyright-box-main {
    padding: 30px 0 0 0;
}

.copyright-box-main p {
    text-align: center;
    font-size: 15px;
    letter-spacing: 5px;
    color: #ffffff;
    margin: 0;
}

.border-top-footer {
    position: relative;
    margin-top: 30px;
}

.border-top-footer:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(217, 217, 217, 0) 101.07%);
}

@media(max-width: 450px) {
    .trust-box-text {
        padding-top: 10px;
    }

    .footer-box {
        padding: 50px 0 30px 0 !important;
    }
}

.footer-box .accordion-button:hover:after {
    color: #80B927 !important;
}

.footer-box .accordion-button:hover {
    color: #80B927;
}

.footer-box .accordion-button {
    position: relative;
    transition: all 0.3s ease-in-out;
}

.trust-logo-box img {
    width: 90px;
    margin-right: 30px;
}

.footer-heading a:hover i {
    margin-left: 10px;
}

.footer-heading a {
    transition: all 0.3s ease-in-out;
}

.footer-heading a i {
    margin-left: 5px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.accordian-footer-list li a {
    transition: all 0.3s ease-in-out;
}

.accordian-footer-list li a:hover {
    color: #80B927;
}

.footer-list-industries-scroller {
    max-height: 300px;
    overflow-y: scroll;
    transition: all 0.3s ease-in-out;
}

.footer-list-industries-scroller:hover {
    overflow-y: auto;
}

.footer-list-industries-scroller::-webkit-scrollbar {
    width: 5px;
}

.footer-list-industries-scroller::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
}

.footer-list-industries-scroller:hover::-webkit-scrollbar-thumb {
    background-color: #cccccc;
}

.footer-list-industries-scroller::-webkit-scrollbar-track {
    background: transparent;
}

.footer-list-industries-scroller:hover {
    overflow-y: auto;
}

.footer-list-box-text li a span i {
    transition: all 0.3s ease-in-out;
}

.footer-list-box-text li a:hover span i {
    transform: translateX(5px);
}

.footer-list-box-text li a {
    font-weight: 700;
    color: #80B927 !important;
    font-size: 18px !important;
}