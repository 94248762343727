.engagement-models-box {
    /* background-color: #062640; */
    position: relative;
    padding: 80px 0 100px 0;
    /* height: 100vh; */
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    background-image: url("../../../public/images/engagement-models-box-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.engagement-models-box.in-view-class {
    /* position: sticky;
    top: 0;
    z-index: 1; */
}

h3.sub-heading-box {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    line-height: 30px;
}

.engagment-models-box:before {
    position: absolute;
    content: '';
    background: url('../../../public/images/engagment-left.png');
    width: 440px;
    height: 100px;
    right: 0;
    background-repeat: no-repeat;
}

.engagment-models-box:after {
    position: absolute;
    content: '';
    background: url('../../../public/images/engagment-right.png');
    width: 440px;
    height: 100px;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
}

h1.engagment-model-text {
    text-align: center;
    color: #ffffff;
    font-size: 40px;
    font-weight: 600;
}

h1.engagment-model-text span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 -0.19%, #C1FD63 63.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

.staff-card-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 10px 0 #4c525c;
}

.staff-card-box {
    padding: 50px 30px;
    border-radius: 5px;
    /* background: #272E39; */
    background: #272E39;
    position: relative;
    z-index: 99;
    margin-top: 50px;
    /* height: 90%; */
    min-height: 360px;
    transition: all 0.3s ease-in-out;
}

.staff-card-image img {
    width: 100%;
    /* filter: invert(1); */
}

.staff-card-image {
    width: 60px;
    margin: 0 auto;
}

.staff-card-box h3 {
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    text-transform: capitalize;
}

.staff-card-box p {
    color: #fff;
    text-align: center;
    font-weight: 400;
    margin-bottom: 0;
    height: 100px;
    font-size: 18px;
}

.engagment-card-btn a i {
    margin-left: 5px;
    font-size: 12px;
}

.engagment-card-btn a:hover {
    background-color: #ffffff !important;
    color: #272E39 !important;
}

.engagment-card-btn a {
    width: 170px;
    height: 40px;
    border: 1.7px solid #131313;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    color: #131313;
    transition: all 0.3s ease-in-out;
}

.engagment-card-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.common-white-btn {
    color: white;
    padding: 8px 20px;
    border: 2px solid white;
    border-radius: 10px;
    text-decoration: none;
}

.common-white-btn:hover {
    color: #80B927;
    border: 2px solid #80B927;
}

@media(max-width: 450px) {
    .engagment-models-box {
        padding: 50px 0 75px 0;
    }

    .staff-card-box {
        margin-top: 30px;
        padding: 40px 30px !important;
        min-height: auto !important;
    }

    .staff-card-box p {
        font-size: 16px;
        height: inherit !important;
    }

    h3.sub-heading-box {
        font-size: 16px;
        line-height: 1.7;
    }
}

@media(max-width: 950px) {
    h1.engagment-model-text {
        font-size: 32px;
    }

    h3.sub-heading-box {
        font-size: 16px;
        width: 85%;
        margin: 0 auto;
    }
}

@media(max-width: 375px) {
    h1.engagment-model-text {
        font-size: 28px;
    }
}

.engagment-card-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.engagment-card-button a {
    /* color: #131313 !important; */
    transition: all 0.3s ease-in-out;
}

@media(max-width: 1100px) {
    .staff-card-box p {
        height: 150px;
    }

    .staff-card-box {
        height: 90%;
    }
}