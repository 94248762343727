.why-us-box {
    padding: 80px 0 100px 0;
    background-image: url('../../../public/images/why-us-states1.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: right;
    position: relative;
    z-index: 2;
}

.clutch-icons {
    display: flex;
    justify-content: flex-end;
    transition: all 0.3s ease-in-out;
}

.clutch-icons img {
    width: 130px;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
}

.clutch-icons img:hover {
    transform: translateY(-10px);
}

.why-us-text-box p.common-text {
    color: #ffffff;
    margin: 10px 0 15px 0;
    font-size: 18px;
}

.why-us-text-box h2.common-heading {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    margin: 20px 0 0 0;
}

.why-us-text-box h1 span {
    color: #80B927;
}

.why-us-text-box h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;
}

.why-us-second-text-box h1 span {
    color: #80B927;
}

.number-boxes-main {
    margin-top: 30px !important;
}

.number-box p.common-text {
    color: #FFFFFF;
}

.number-boxes-main .number-box:last-child:after {
    display: none;
}

.number-boxes-main .number-box:first-child {
    padding-left: 0 !important;
}

.number-boxes-main .number-box {
    padding-left: 40px !important;
}

.number-box {
    display: inline-block;
    padding-right: 50px;
    position: relative;
}

.number-box:after {
    position: absolute;
    content: '';
    width: 1.5px;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(128, 185, 39, 0) 100%);
}

@media(max-width: 950px) {
    .clutch-icons img {
        margin: 0 auto;
        margin-bottom: 25px
    }
}

@media(max-width: 380px) {
    .clutch-icons img {
        width: 100px;
    }
}

@media(max-width: 450px) {
    .number-boxes-main {
        flex-wrap: wrap;
    }

    .why-us-second-text-box h1 {
        margin-top: 0 !important;
        text-align: center;
    }

    .why-us-swiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
        margin-bottom: 20px;
    }

    .why-us-box {
        padding: 50px 0 70px 0 !important;
    }

    .number-boxes-main .number-box {
        width: 100%;
    }

    .number-boxes-main .number-box:nth-child(2):after {
        display: none !important;
    }

    .number-box p.common-text {
        text-align: center;
    }

    .why-us-text-box p.common-text {
        font-size: 16px;
    }
}

@media(max-width: 1025px) {
    .why-us-box {
        background-position: right;
    }

    .number-box {
        padding-right: 25px !important;
    }

    .number-boxes-main {
        display: flex;
        justify-content: space-between;
    }

    .why-us-second-text-box .number-boxes-main .number-box {
        padding-left: 20px !important;
    }
}

.why-us-swiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 7px;
    position: relative !important;
    display: flex;
    margin-top: 10px;
}

.why-us-box span.swiper-pagination-bullet {
    background-color: #FFFFFF !important;
    opacity: 1 !important;
    transition: all 0.3s ease-in-out;
}

.why-us-box span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #80B927 !important;
    width: 17px;
    border-radius: 100px;
}