.portfolio-main-box {
    padding: 80px 0;
    background-color: #f4f6fa;
}

a.common-green-btn.details-btn i {
    position: relative;
    top: 1px;
}

.project-card-main:hover {
    border-color: #80B927;
}

.project-card-main {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    height: 100%;
}

.project-card-main:hover a.common-green-btn.details-btn i {
    transform: translateX(5px);
}

.project-card-main:hover a.common-green-btn.details-btn {
    color: #80B927 !important;
}

a.common-green-btn.details-btn:hover {
    color: #80B927 !important;
}

a.common-green-btn.details-btn {
    color: #000000 !important;
    cursor: pointer;
    font-size: 18px;
}

.project-image {
    width: 100%;
    height: 200px;
}

.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.project-card-main h2 {
    margin: 20px 0 5px 0;
    font-size: 26px;
    font-weight: 500;
}

.project-card-main p {
    margin-bottom: 15px !important;
    opacity: 0.8;
    font-weight: 300;
}

.view-all-button {
    display: flex;
    justify-content: center;
}

.view-all-button span:hover {
    background-color: #80b927 !important;
    border-color: #80b927 !important;
    color: #ffffff !important;
}

.view-all-button span {
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000;
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
    cursor: pointer;
}

.project-details-box {
    position: sticky;
    top: 30px;
}

.project-details-box h2 {
    font-size: 32px;
    color: #80b927;
    font-weight: 500;
    margin-top: 20px;
}

.project-details-box p {
    opacity: 0.7;
}

.project-details-box {
    border-radius: 5px;
}

.project-image-main {
    width: 100%;
    height: 100%;
    position: relative;
}

.project-image-main img {
    width: 100%;
    height: 400px;
    object-fit: contain;
    border-radius: 5px;
}

span.cross-detail-button i {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #ffffff;
    cursor: pointer;
    font-size: 23px;
}

.active-detail-card {
    background-color: #245FB4 !important;
}

.project-card-main.active-detail-card p,
.project-card-main.active-detail-card h2,
.project-card-main.active-detail-card a.common-green-btn.details-btn {
    color: #ffffff !important;
    opacity: 1 !important;
}

span.cross-detail-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
}

.project-detials-card .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    justify-content: flex-end;
    padding-right: 35px;
    margin-bottom: 20px;
}

.project-detials-card span.swiper-pagination-bullet {
    opacity: 1 !important;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
}

.project-detials-card span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 30px !important;
    background-color: #80B927 !important;
    border-radius: 10px !important;
}