.poineering-change-box {
    /* background: linear-gradient(0deg, #F9FDFF 0%, #F3F5F9 100%); */
    padding: 80px 0 50px 0;
    background-color: #f3f5f9;
}

.poineering-list li img {
    width: 28px;
}

.poineering-list li:hover img {
    filter: invert(1);
}

li.active-poineer-list img {
    filter: invert(1);
}

.poineering-list li span {
    border-left: 1px solid lightgray;
    padding-left: 10px;
    margin-left: 10px;
}

.pioneer-image-box {
    position: relative;
    width: 100%;
    height: 100%;
}

.pioneer-image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pioneer-image-box:before {
    position: absolute;
    content: '';
    background: rgb(6, 38, 64);
    background: linear-gradient(63deg, rgba(6, 38, 64, 1) 60%, rgba(255, 255, 255, 1) 100%);
    opacity: 0.5;
    width: 100%;
    height: 100%;
}

.pioner-new-image {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background-image: none !important;
    padding: 0 !important;
}

.pioner-text-box {
    top: 0;
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: #00000042;
}

.pioner-text-box h2 {
    color: #ffffff !important;
}

.poinering-change-box h1 {
    font-size: 40px;
    font-weight: 400;
}

.poinering-change-box h1 span {
    color: #000 !important;
    font-weight: 600;
}

ul.poineering-list {
    padding: 0;
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
}

ul.poineering-list::-webkit-scrollbar {
    width: 0;
    background: transparent;
    width: 5px;
    transition: all 0.3s ease-in-out;
}

/* ul.poineering-list:hover::-webkit-scrollbar {
    transition: all 0.3s ease-in-out;
} */

ul.poineering-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
}

ul.poineering-list::-webkit-scrollbar-thumb {
    background: #8C8C8C;
    /* background: #888; */
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
    width: 10px;
}

/* ul.poineering-list::-webkit-scrollbar-thumb:hover {
    transition: all 0.3s ease-in-out;
} */

ul.poineering-list::-webkit-scrollbar {
    width: 0;
    background: transparent;
    transition: all 0.3s ease-in-out;
    width: 7px;
}

/* ul.poineering-list:hover::-webkit-scrollbar {
    transition: all 0.3s ease-in-out;
} */

ul.poineering-list::-webkit-scrollbar-track {
    background: #fff;
    /* background: #f1f1f1; */
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
    border: 1px solid lightgray;
}

ul.poineering-list::-webkit-scrollbar-thumb {
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
}

/* ul.poineering-list::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: all 0.3s ease-in-out;
} */

ul.poineering-list li.active-poineer-list {
    color: #fff !important;
    background-color: #80B927;
}

ul.poineering-list li:hover {
    background-color: #80B927;
    color: #ffffff;
}

ul.poineering-list li {
    list-style: none;
    padding: 15px;
    margin: 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
}

/* ul.poineering-list li:before {
    position: absolute;
    content: '';
    height: 0.8px;
    width: 100%;
    background: linear-gradient(90deg, #292929 46.38%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    opacity: 0.5;
} */

.pioneer-chnage-box {
    background-image: url('../../../public/images/pioner-image.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    overflow: hidden; */
    /* padding: 30px; */
}

.pioneer-chnage-box:before {
    position: absolute;
    /* content: ''; */
    background: linear-gradient(230.36deg, #052849 0%, #090F15 100%);
    opacity: 0.7;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.pioneer-chnage-box img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

/* .pioneer-icon {
    position: relative;
    width: 60px;
    margin-top: 50px;
} */

.pioneer-icon img {
    width: 45px;
    height: 45px;
    object-fit: contain;
}

.pioneer-icon {
    position: relative;
    width: 75px;
    height: 75px;
    margin-top: 50px;
    background: #F3F5F9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .pioneer-chnage-box h2 {
    position: relative;
    color: #ffffff;
    font-size: 26px;
    margin: 20px 0;
} */

.pioneer-chnage-box p.common-text {
    position: relative;
    color: #ffffff;
    width: 70%;
}

.pioneer-chnage-box a:hover {
    background-color: #ffffff;
}

.pioneer-chnage-box a {
    background-color: #80B927;
    padding: 15px 30px;
    border-radius: 5px;
    margin-top: 30px;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
}

.pioneer-chnage-box a:hover {
    color: #80B927;
}

.pioneer-chnage-box a i {
    transition: all 0.3s ease-in-out;
    margin-left: 5px;
    position: relative;
    top: 2px;
}

.pioneer-chnage-box a:hover i {
    transform: translateX(7px);
}

.pioneering-main-img {
    background-position: center;
    background-size: cover !important;
    height: 100%;
    background-repeat: no-repeat;
}


@media(max-width: 950px) {
    .pioneer-chnage-box p.common-text {
        width: 100% !important;
    }

}


@media(max-width: 480px) {
    .pioneer-chnage-box h2 {
        font-size: 32px;
    }

    .poineering-change-box{
        padding: 50px 0;
    }

    .poinering-change-box h1 {
        font-size: 28px !important;
    }

    .pioneer-chnage-box p.common-text {
        width: 100%;
        margin-bottom: 50px;
        font-size: 16px;
    }

    .pioneer-chnage-box a {
        margin-bottom: 0;
    }
}


@media(max-width: 330px) {
    .pioneer-chnage-box p.common-text {
        width: 100%;
        margin-bottom: 50px;
        font-size: 16px;
    }
}