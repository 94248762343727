.ai-services-box.custom-software-development-box .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
    height: inherit !important;
    border: 3px solid red !important;
}

.custom-software-development-box {
    background-color: #F9FDFF !important;
}

.mySwiper-two {
    padding-top: 15px;
}

.main-heading-text span {
    color: #80B927;
}

.ai-services-box.custom-software-development-box .mySwiper {
    height: 80vh;
}

.ai-services-box.custom-software-development-box .mySwiper .swiper-wrapper {
    flex-direction: column;
}

.ai-services-box.custom-software-development-box .generated-ai-card.bg-four {
    /* margin-top: 43px !important; */
}

.arrow-developing-slider.custom-software.arrow-slide-box {
    transform: rotate(90deg);
    position: relative;
    bottom: -80%;
}

@media(max-width: 950px) {
    .image-box-custom-software img {
        height: 100%;
        object-fit: cover;
    }

    .ai-services-box.custom-software-development-box .mySwiper {
        height: 43vh !important;
    }

    .generated-ai-card {
        height: 190px !important;
    }

    .text-box-custom-software {
        width: 85% !important;
    }

    .image-box-custom-software {
        height: 300px;
        border-radius: 3px !important;
        overflow: hidden;
    }
}

@media(max-width: 330px) {
    .web-app-services-box .ai-services-box.custom-software-development-box .mySwiper {
        height: 1100px !important;
    }
}

@media(max-width: 450px) {
    .ai-services-box.custom-software-development-box .mySwiper {
        height: 830px !important;
    }

    .ai-services-box .generated-ai-card {
        /* height: 100% !important; */
    }

    .arrow-developing-slider.custom-software.arrow-slide-box {
        transform: inherit !important;
        bottom: 0 !important;
    }
}