.typetesing-box {
    padding: 70px 0 40px 0;
    background-color: #f3f5f9;
}

.type-card-image {
    width: 100%;
    height: 250px;
    overflow: hidden;
    border-radius: 10px;
}

.type-card-image img {

    object-fit: cover;
    transition: all 0.3s ease-in-out;
}

.type-setting-card {
    /* background-color: #ffffff; */
    border-radius: 5px;
    /* box-shadow: 0px 4px 27.8px 0px #0000001A; */
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    /* height: 90%; */
    /* border: 2px solid red; */
}

.type-setting-card:hover .type-card-image img {
    /* transform: scale(1.15); */
}

.type-card-text {
    padding: 40px 25px;
}

.content-box-type-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-box-type-card p {
    margin-bottom: 15px;
    font-size: 14px;
    color: #636363;
}

.content-box-type-card span {
    margin-bottom: 0;
    font-size: 14px;
    color: #636363;
}

.type-card-text h3 {
    font-size: 23px;
    margin: 30px 0 15px 0;
    font-weight: 500;
    line-height: 35px;
}

.type-card-text i.fa-solid.fa-arrow-right {
    font-size: 23px;
    margin-top: 30px;
    color: #80B927;
}

.type-setting-card.side-typing-card-box i.fa-solid.fa-arrow-right {
    margin-top: 0 !important;
}

.type-setting-card.side-typing-card-box .type-card-text a:hover {
    text-decoration: underline;
}

.type-setting-card.side-typing-card-box .type-card-text a {
    font-size: 26px !important;
    line-height: inherit !important;
    color: #272e34;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

/* .type-setting-card.side-typing-card-box .type-card-image img {
    height: 100% !important;
} */

.type-setting-card.side-typing-card-box .type-card-text {
    padding: 30px 0px !important;
}

.type-setting-card.side-typing-card-box {
    margin-bottom: 25px;
    margin-top: 50px;
}

.typetesing-box .view-all-button {
    justify-content: center;
    margin-top: 20px;
}

.hire-for-us-slider {
    background-color: #F3F5F9;
    padding: 80px 30px 50px 100px;
    height: 100%;
}

.hire-for-us-slider h3.sub-heading {
    font-size: 18px;
    text-align: left;
}

.hire-for-us-slider h1.main-heading {
    text-align: left;
}

.hire-for-us-slider .type-card-hire-image img {
    width: 40px;
    height: 40px;
}

.type-card-hire-text a {
    font-size: 26px;
    font-weight: 500;
    color: black !important;
    margin-top: 30px;
    display: inline-block;
}

.side-typing-card-box .slider-link a {
    font-size: 18px;
    text-decoration: none;
    color: #80B927 !important;
    transition: all 0.3s ease-in-out;
}

.side-typing-card-box .slider-link a i {
    transition: all 0.3s ease-in-out;
    margin-left: 5px;
    position: relative;
    top: 2px;
}

.side-typing-card-box .slider-link a:hover i {
    transform: translateX(8px);
}

.hire-us-side-bar {
    background-image: url('../../../public/images/hire-experts/hire-us-side-bar.png');
    height: 100%;
    background-position: center;
    background-size: cover;
    color: #ffffff;
    padding: 70px 100px 50px 60px;
}

p.sidebar-badge {
    padding: 10px 20px;
    background-color: #FFFFFF75;
    display: inline-block;
    border-radius: 4px;
    font-size: 15px;
}

h2.sidebar-title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;
}

p.sidebar-content {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.sidebar-link {
    color: #80B927;
    display: flex;
    align-items: center;
    gap: 10px;
}

.sidebar-link:hover a {
    color: #ffffff;
}

.sidebar-link:hover i {
    color: #ffffff;
}

.sidebar-link a {
    text-decoration: none;
    color: #80B927;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.sidebar-link i {
    margin-top: 5px;
}

.type-card-hire-text {
    height: fit-content;
}

/* .main-hire-us-for .swiper-slide{
    height: initial;
}
.main-hire-us-for .swiper-slide a{
    height: 100%;
    display: inline-block;
} */
@media(max-width: 1120px) {

    .hire-us-side-bar {
        padding: 50px 80px 50px 80px;
    }

    .hire-for-us-slider {
        background-color: #F3F5F9;
        padding: 50px 80px 50px 80px;
    }

}

@media(max-width: 1120px) {

    .hire-us-side-bar {
        padding: 50px 30px 50px 30px;
    }

    .hire-for-us-slider {
        background-color: #F3F5F9;
        padding: 50px 30px 50px 30px;
    }

    .small-blog-spacing {
        padding: 10px !important;
    }

    .type-card-hire-text {
        /* height: 210px; */
    }

}

@media(max-width: 769px) {
    .type-card-hire-text {
        /* height: 240px; */
    }
}

@media(max-width: 450px) {

    .content-box-type-card {
        display: flex !important;
        align-items: flex-start !important;
    }

    .arrow-right.arrows-slider,
    .arrow-left.arrows-slider {
        display: none;
    }

    .typetesing-box {
        padding: 50px 0 25px 0;
    }

    .type-setting-card.side-typing-card-box {
        margin-bottom: 0 !important;
    }

    .content-box-type-card span {
        margin-top: 15px;
    }

    /* .type-card-image img,
    .typetesing-box .type-setting-card.side-typing-card-box .type-card-image img {
        height: 100% !important;
    } */

    .type-card-text {
        padding: 20px !important;
    }

    .type-card-text i.fa-solid.fa-arrow-right {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .type-card-text i.fa-solid.fa-arrow-right {
        margin-top: 0 !important;
    }

    .type-card-text h3 {
        font-size: 16px !important;
        line-height: inherit !important;
        margin: 10px 0 15px 0 !important;
    }

    .profile-text-side h5 {
        font-size: 14px;
    }

    .small-blog-spacing {
        padding: 15px 20px 15px !important;
    }

    .why-us-second-text-box .number-boxes-main .number-box {
        padding-left: 15px !important;
    }

    .number-box p.common-text {
        font-size: 16px;
        color: #FFFFFF;
    }

    .type-card-hire-text {
        height: auto;
        padding-bottom: 10px;
    }

    .small-blog {
        height: 100%;
    }
}


@media(max-width: 330px) {
    .content-box-type-card {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .content-box-type-card span {
        margin-top: 20px !important;
    }

    .typetesing-box {
        padding: 50px 0 0 0 !important;
    }

}


.insight-size-box {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 100px;
}

.insights-box span {
    color: #292929;
    margin-left: 0px;
    font-size: 18px;
    font-weight: 300;
}

.insights-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.insights-box img {
    width: 100%;
    height: 100%;
}

.arrows-slider img {
    cursor: pointer;
    width: 30px;
}

.arrows-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.arrow-right img {
    transform: rotateZ(180deg);
}