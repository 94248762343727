.dark-road-map-box {
  padding: 80px 0;
  background-image: url("../../../public/images/inductry-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 70px 0 140px 0;
}

.dark-road-map-box h1.dark-engagment-model-text {
  text-align: center;
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
}

.dark-engagment-model-text span {
  color: #80b927;
}

.dark-road-image-box.dark-ai-development-road-map {
  width: 780px;
  left: 52%;
  top: 60%;
}

.dark-road-map-box h3 {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
}

.dark-proven-roadmap-text {
  margin-top: 35px;
}

.dark-proven-roadmap-text p {
  color: white;
  text-align: right;
}

.dark-proven-roadmap-text h2 {
  color: white;
  font-size: 21px;
  text-align: right;
}

.dark-road-image-box {
  position: absolute;
  width: 680px;
  left: 50%;
  top: 57%;
  transform: translate(-50%, -50%);
}

.dark-road-image-box img {
  width: 100%;
}

.dark-road-map-box .dark-prover-box-map-left {
  padding-top: 130px;
}

.dark-proven-roadmap-text.dark-road-map-left-space {
  margin-bottom: 100px;
}

.dark-right-box-text h2,
.dark-right-box-text p {
  text-align: left;
}

.dark-none-web-box {
  display: none;
}

.dark-road-map-box .dark-proven-roadmap-text {
  margin-top: 90px;
}

.dark-road-map-box p.dark-common-text {
  font-size: 18px;
  font-weight: 400;
}

.dark-road-map-box .dark-proven-roadmap-text h2 {
  font-size: 26px;
}

@media (max-width: 1100px) {
  .dark-road-image-box img {
    width: 90%;
    margin-left: 35px;
  }

  .dark-road-image-box.dark-ai-development-road-map {
    top: 50%;
  }
}

@media (max-width: 950px) {
  .dark-none-web-box {
    display: block;
  }

  .dark-road-image-box.dark-ai-development-road-map {
    width: 100%;
  }

  .dark-none-tab {
    display: none;
  }

  .dark-ai-page-roadmap {
    height: auto;
  }
}

@media (max-width: 950px) {
  .none-tab-box {
    display: none;
  }
}

@media (max-width: 950px) {
  .dark-road-map-box h1.dark-engagment-model-text {
    font-size: 32px;
  }

  .dark-road-map-box {
    padding: 60px 0;
  }

  .dark-proven-roadmap-text.dark-road-map-left-space {
    margin-bottom: 30px;
  }

  .dark-road-map-box .dark-proven-roadmap-text h2 {
    text-align: center !important;
  }

  .dark-proven-roadmap-text.dark-road-map-left-space.first p.dark-common-text,
  .dark-proven-roadmap-text.third p.darkcommon-text {
    text-align: center !important;
    font-weight: 300;
  }

  .dark-road-map-box .dark-proven-roadmap-text {
    margin-top: 30px;
  }

  .dark-road-map-box .dark-prover-box-map-left {
    padding-top: 0;
  }

  .dark-road-map-box h3,
  .dark-road-map-box p.dark-common-text {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    line-height: 30px;
  }
}

@media (max-width: 450px) {
  .dark-road-map-box {
    padding: 50px 0;
  }


}