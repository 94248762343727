.hire-us-box {
    background-color: #F3F5F9;
    padding: 80px 0 80px 0;
}

.hire-us-box h3 {
    width: 88% !important;
    margin: 0 auto;
}

.home-card-box .swiper.swiper-initialized.swiper-horizontal.mySwiper {
    padding-top: 10px !important;
}

h1.main-heading {
    text-align: center;
    font-size: 40px;
    margin-bottom: 5px;
    font-weight: 600;
    text-transform: none;
    color: #131313;
}

h3.sub-heading {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #000c;
    margin-top: 10px;
    line-height: 28px;
}

.hire-us-card.border-1 {
    border: 1px solid #4A71FB !important;
    background-color: #FDFDFF;
}

.hire-us-card.border-2 {
    border: 1px solid #FECB01;
    background-color: #FFFEFA;
}

.hire-us-card.border-3 {
    border: 1px solid #80B927;
    background-color: #FDFFFA;
}

.hire-us-card {
    padding: 30px;
    border-radius: 5px;
    width: 97%;
    height: 100%;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.hire-us-card:hover {
    transform: translateY(-10px);
}

.hire-us-icon {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    background: #F3F5F9;
    padding: 15px;
}

.hire-us-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.hire-us-card p.common-text {
    /* height: 130px; */
}

.hire-us-card a i {
    margin-left: 7px;
    transition: all 0.3s ease-in-out;
}

.hire-us-card a:hover i {
    transform: translateX(6px);
}

.hire-us-card a {
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #80B927;
    font-weight: 600;
    text-decoration: none;
    margin-top: 20px;
    border: none !important;
    outline: none !important;
}

.swiper-button-next:after {
    font-family: 'FontAwesome' !important;
    content: '\f30b' !important;
}

.swiper-button-prev:after {
    font-family: 'FontAwesome' !important;
    content: '\f30a' !important;
}

.hire-us-swiper {
    position: relative;
}

.arrow-prev-button img,
.arrow-next-button img {
    /* font-size: 23px; */
    cursor: pointer;
    color: #80B927;
}

.arrow-prev-button img {
    position: absolute;
    left: -50px;
    top: 48%;
    transform: rotate(180deg) translateY(-50%);
}

.arrow-next-button img {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
}

.arrow-next-button,
.arrow-prev-button {
    display: inline-block;
}

.none-web {
    display: none;
}

.col-md-12.hire-us-swiper .swiper-slide {
    height: auto !important;
}

.hire-us-cta-btn a {
    width: fit-content !important;
    height: 45px !important;
    background-color: #80B927 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    text-decoration: none !important;
    margin-left: 20px !important;
    transition: all 0.3s ease-in-out !important;
    border: 2px solid transparent !important;
    padding: 0px 10px !important;
    font-size: 16px !important;
}

.hire-us-cta-btn a:hover {
    color: #80B927 !important;
    background-color: transparent !important;
    border-color: #80B927 !important;
}


@media(max-width: 320px) {
    .row.mt-5 {
        margin-top: 0 !important;
    }
}

@media(max-width: 330px) {
    h3.sub-heading {
        margin-bottom: 20px;
    }
}

@media(max-width: 450px) {
    .hire-us-card {
        width: 92% !important;
        height: inherit !important;
    }

    .hire-us-box {
        padding: 50px 0 30px 0;
    }

    .hire-us-card p.common-text {
        font-size: 16px !important;
    }

    .hire-us-box h3 {
        width: 100% !important;
        margin-bottom: 0 !important;
        font-size: 16px;
    }
}

@media(max-width: 450px) {
    h1.main-heading {
        font-size: 28px !important;
    }
}

@media(max-width: 950px) {
    .none-web {
        display: block;
        margin-bottom: 30px !important;
    }

    h1.main-heading {
        font-size: 32px;
    }

    .hire-us-card p.common-text {
        height: auto !important;
    }

    .hire-us-swiper .arrow-next-button,
    .hire-us-swiper .arrow-prev-button {
        display: none;
    }
}

@media(max-width: 768px) {
    h3.sub-heading {
        font-size: 16px;
    }

    .hire-us-box {
        padding: 60px 0;
    }
}

.web-app-hire-us .hire-us-card p.common-text {
    height: auto !important;
}

@media(max-width: 1100px) {
    .hire-us-card p.common-text {
        height: auto;
    }
}