.hire-banner-box {
    background-image: url('../../../public/images/banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
    height: 100% !important;
    background-position: center;
}

.hire-banner-box.bgGradient-green .reverse-box-main {
    position: relative;
    z-index: 9;
}

.hire-banner-box.bgGradient-green {
    position: relative;
    /* background: rgb(52, 76, 16); */
}

.hire-banner-box.bgGradient-green:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
    z-index: 1;
    background: linear-gradient(45deg, rgba(52, 76, 16, 1) 0%, rgba(0, 52, 90, 1) 100%);
}

.hire-page-banner-button {
    display: flex;
}

.width-control-heading {
    width: 100%;
}

.space-top-banner-hire-page {
    margin: 10px 0 20px 0;
}

.heading-space-top {
    margin-top: 50px;
}

h3.subHeading-banner {
    color: #80B927;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;
}

h3.common-text {
    font-size: 18px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 15px;
}

h1.banner-heading {
    font-size: 60px;
    color: #ffffff;
    font-weight: 500;
    margin: 0 0 0px 0;
}

h1.banner-heading span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

h2.banner-sub-heading {
    font-size: 18px;
    color: #FCFCFC;
    font-weight: 400;
    line-height: 1.6;
}

a.banner-button-box {
    background-color: #ffffff;
    font-size: 18px;
    text-transform: capitalize;
    text-decoration: none;
    border-radius: 5px;
    color: #80B927;
    padding: 10px 40px 12px 40px;
    font-weight: 400;
}

h4.banner-sub-heading {
    color: #FCFCFC;
    margin-top: 0;
    font-size: 23px;
    margin-right: 103px;
    margin-bottom: 20px;
}

.logo-boxes-banner-small img {
    width: 70px;
    margin-right: 15px;
}

.logo-boxes-banner img {
    width: 150px;
}

.banner-vedio-side-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.banner-vedio-side-image img {
    width: 80%;
}

.flex-end-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.banner-text-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    margin-top: 20px;
}

.banner-text-list p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    margin-right: 20px;
    padding-left: 20px;
}

.banner-text-list p:before {
    position: absolute;
    content: '\f00c';
    font-family: 'FontAwesome';
    color: #80B927;
    left: 0;
}

h3.green-heading-text {
    color: #80B927;
    font-size: 26px;
}

.hire-us-banner-heading{
    color: #ffffff;
    font-size: 60px;
}


@media(max-width: 950px) {
    .main-banner-box .banner-vedio-side-image img {
        width: 100% !important;
    }

    .about-testimonial-box:before {
        height: 95% !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
    }

    .ai-services-box.custom-software-development-box .generated-ai-card {
        /* height: 235px !important; */
        height: 22vh !important;
    }

    .ai-services-box.custom-software-development-box .generated-ai-card h1 {
        font-size: 22px;
    }

    .ai-services-box.custom-software-development-box .mySwiper {
        height: 50vh !important;
    }

    .banner-text-list p {
        width: 100%;
        margin-bottom: 25px;
    }

    .space-top-banner-hire-page {
        margin: 60px 0 0 0;
    }


    h2.banner-sub-heading {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .banner-text-list {
        flex-direction: column;
        margin: 0 !important;
    }

    .width-control-heading {
        width: 100%;
    }
}

@media(max-width: 450px) {
    .banner-vedio-side-image img {
        width: 100% !important;
        margin-top: 50px;
    }

    .hire-page-banner-button {
        flex-direction: column;
    }

    .space-top-banner-hire-page {
        margin: 35px 0 0 0 !important;
    }

    .define-ruby-and-rails {
        padding: 50px 0 0 0 !important;
    }

    a.banner-button-box {
        padding: 10px 20px 10px !important;
    }

    h4.banner-sub-heading {
        margin-top: 20px !important;
    }

    .banner-text-list p {
        width: 100% !important;
        margin-bottom: 30px !important;
    }

    .banner-text-list {
        display: block !important;
    }

    .main-banner-box .banner-vedio-side-image img {
        width: 100% !important;
    }

    .banner-text-list {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    .heading-space-top {
        margin-top: 0;
    }

    .ai-services-box.custom-software-development-box .mySwiper {
        height: 70vh !important;
    }

    .ai-services-box.custom-software-development-box .generated-ai-card {
        height: 230px !important;
    }
}