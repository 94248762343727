.our-locations-box {
    padding: 80px 0;
    /* display: none; */
}

.location-card-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 30px 0 #80B927;
}

.location-card-box {
    background-color: #80B927;
    padding: 30px;
    border-radius: 20px;
    height: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.location-card-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.location-icon {
    /* width: 230px;
    height: 120px; */
    margin: 0 auto;
    padding-bottom: 20px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.location-icon img {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.location-card-box h3 {
    color: #ffffff;
    font-size: 21px;
    font-weight: 600;
    margin: 0px 0 5px 0;
}

.location-card-box h4 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
}

.location-card-box p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
}

.location-card-box.last-child:before {
    display: none;
}

.location-card-box.none-border-box:before {
    display: none;
}

@media(max-width: 950px) {
    .location-card-box:before {
        display: none !important;
    }
}

.location-icon img {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.location-icon img.fade-out {
    opacity: 0;
}

@media(max-width: 450px) {
    .space-bottom {
        margin-bottom: 20px !important;
        width: 90%;
        margin: 0 auto;
    }
}