.trends-insights h3,
.trends-insights p,
.trends-insights span,
.trends-insights a {
  color: #fff;
  text-decoration: none !important;
  font-size: 14px;
}

.overlay h3 {
  font-size: 20px !important;
  line-height: 30px;
}

.content p {
  font-size: 18px;
}

.trends-insights {
  background-color: #f3f5f9;
  padding: 80px 0px;
}

.trends-insights h3 {
  font-size: 18px;
}

.trends-insights .overlay {
  border-radius: 12px;
  background-color: #000000b0;
  height: 100%;
}

.trends-insights .overlay img {
  width: 24px;
  margin-right: 5px;
}

.small-blog-spacing {
  padding: 10px;
}

.big-blog-spacing {
  padding: 10px 20px;
}

.small-blog {
  border-radius: 12px;
  height: 220px;
  background-position: center;
  background-size: cover;
  transition: all 0.3s ease-in-out;
}

.small-blog:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 10px 0 #4c525c;
}

.big-blog {
  border-radius: 12px;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: all 0.3s ease-in-out;
}

.big-blog:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 10px 0 #4c525c;
}

.trends-insights .big-blog .overlay {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: flex-end;
}

.trends-insights .small-blog .overlay {
  display: flex;
  padding: 40px 30px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.trends-insights h3.sub-heading {
  color: black;
}

.trends-insights .big-blog .overlay .content {
  width: 68%;
}

.trends-insights.mobile-dev-page {
  background-color: #fff;
}

.ai-industry-services-box.mobile-dev-page {
  background: linear-gradient(#f3f5f9, #f9fdff);
}

@media (max-width: 1120px) {
  .big-blog-spacing {
    padding: 10px 0px !important;
  }
}

@media (max-width: 950px) {
  .trends-insights .big-blog .overlay .content {
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .trends-insights .big-blog .overlay .content {
    width: 100%;
  }

  .why-choose-bitsclan-main .why-top-content p {
    margin-bottom: 0;
  }

  .trends-insights.space-top-padding {
    padding-top: 40px !important;
  }

  .trends-insights {
    padding-top: 0 !important;
  }

  .big-blog-spacing {
    padding: 10px 20px !important;
  }
}