.development-process-box {
    padding: 80px 0 60px 0;
    background-image: url('../../../public/images/poeple-bg-layer.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.process-box-main {
    padding: 60px 30px;
    background-color: #F0F0F0;
    border: 2px solid #80B927;
    border-radius: 5px;
}

.process-box-main h1 span {
    color: #80B927;
}

.process-box-main h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 15px;
}

.requirement-gathering-card {
    padding: 25px;
    border-radius: 5px;
    width: 85%;
    height: 220px;
    margin: 0 auto;
    background-color: #F0F0F0 !important;
    transition: all 0.3s ease-in-out;
    border: 2px solid transparent !important;
}

.requirement-gathering-card:hover {
    background-color: #062640 !important;
    border-color: #ffffff !important;
}

.requirement-gathering-card:hover img {
    filter: invert(1);
}

.requirement-gathering-card.icon-change:hover img {
    filter: invert(1) !important;
}

/* 
.requirement-gathering-card.icon-change .icon-image img {
    filter: invert(1);
} */

.requirement-gathering-card:hover h2,
.requirement-gathering-card:hover p {
    color: #ffffff !important;
}

.requirement-gathering-card.bg-grey {
    background-color: #899099 !important;
    border: 2px solid #80B927 !important;
    color: #ffffff !important;
}

.icon-side-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon-side-top h2 {
    color: #ffffff;
    font-size: 36px !important;
    margin: 0 !important;
}

.icon-image img {
    width: 45px;
}

.requirement-gathering-card p {
    margin: 0;
    font-weight: 300 !important;
    color: #292929 !important;
    font-size: 18px;
}

.requirement-gathering-card h2 {
    color: #292929;
    font-size: 26px;
    margin-top: 10px;
}

.requirement-gathering-card.bg-grey h2 {
    font-size: 21px;
    color: #ffffff !important;
    margin-top: 10px;
}

.requirement-gathering-card.bg-grey p {
    color: #ffffff !important;
}

.flex-center-box {
    display: flex;
    align-items: center;
}

.development-process-box .mySwiper-2 {
    height: 100vh;
    max-height: 750px;
}

.development-process-box .mySwiper-2 .swiper-wrapper {
    flex-direction: column;
}

.custom-dev-arrow {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: -83%;
}

.custom-dev-arrow img {
    width: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

@media(max-width: 950px) {
    .requirement-gathering-card h2 {
        font-size: 18px !important;
    }

    .requirement-gathering-card p {
        font-size: 14px;
    }

    .requirement-gathering-card {
        padding: 20px !important;
        width: 85% !important;
        /* height: 190px; */
        height: 18.5vh;
    }

    .development-process-box .custom-dev-arrow {
        flex-direction: row;
        bottom: 0 !important;
    }

    .development-process-box .custom-dev-arrow img {
        transform: rotate(90deg);
    }

    .development-process-box .custom-dev-arrow img.left-image {
        right: -75px;
        position: relative;
    }

    .development-process-box .mySwiper-2 {
        height: 60vh;
    }

    .process-box-main {
        padding: 30px !important;
    }

    .process-box-main h1 {
        font-size: 21px !important;
    }
}

@media(max-width: 450px) {
    .development-process-box .requirement-gathering-card {
        width: 85% !important;
        height: 220px !important;
        margin-right: auto !important;
    }

    .requirement-gathering-card {
        height: 100% !important;
    }

    .development-process-box .mySwiper-2 {
        height: 720px !important;
    }

    .development-process-box {
        padding: 70px 0 35px 0 !important;
    }

    .process-box-main {
        margin-bottom: 30px !important;
    }
}

.development-process-box .swiper-pagination-bullet {
    transition: all 0.3s ease-in-out;
}

.development-process-box .swiper-pagination-bullet {
    background-color: #899099 !important;
    opacity: 1 !important;
}

.development-process-box .swiper-pagination-bullet-active {
    background-color: #80B927 !important;
    height: 20px !important;
    border-radius: 10px !important;
}