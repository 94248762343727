.page-scroller-section {
  background-color: #80b927;
  padding: 15px 0px;
}

.page-scroller-section p {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  margin: 0px;
  cursor: pointer;
}

.page-scroller-section p i {
  font-size: 14px;
  padding-left: 5px;
}


.page-scroller-section {
  position: relative;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  /* Smooth transition for sticky behavior */
}

.page-scroller-section.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional: Adds a shadow for better visibility */
}

.scroller-dropdown {
  display: none;
  transition: all 0.3s ease-in-out;
}

.scroller-dropdown.show {
  display: block;
  width: 300px;
  position: absolute;
  background-color: #80b927;
  padding: 20px;
  margin-top: 10px;
}

.mobile-scroller .dropdown-toggle i {
  transition: transform 0.3s ease-in-out;
  color: #fff;
  margin: 0px 10px;
}

.mobile-scroller .dropdown-toggle {
  color: #fff;
  font-size: 20px;
}

.mobile-scroller .dropdown-toggle i.rotate {
  transform: rotate(180deg);
}

.mobile-scroller {
  display: none;
}

.scroller-link {
  width: fit-content;
  margin: 0 auto;
}

.desktop-scroller {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media(max-width: 1025px) {
  .scroller-link {
    width: 100%;
    line-height: 35px;
  }
}

@media(max-width: 1025px) {
  .mobile-scroller {
    display: block;
  }

  .desktop-scroller {
    display: none;
  }
}