.analytics-solutions-box {
    background-image: url('../../../public/images/banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
    margin-top: 75px;
}

.analytics-textbox-border h1 {
    font-size: 45px;
    color: #ffffff;
    font-weight: 600;
}

.analytics-textbox-border h1 span {
    color: #80B927 !important;
}

.analytics-textbox-border p {
    color: #ffffff;
}

.analytics-image-box {
    display: flex;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;
    width: 70px;
    height: 70px;
}

.analytics-image-box img {
    width: 100%;
    height: 100%;
}

.analytics-image-box:hover h3 {
    color: #80B927 !important;
}

.analytics-image-box h3 {
    margin-bottom: 0;
    font-size: 16px;
    color: #ffffff;
    margin-left: 15px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}

.crm-analytics-box {
    background-color: #F3F5F9;
    padding: 50px;
    border-radius: 5px;
}

.crm-icon-box {
    width: 95px;
    height: 95px;
}

.crm-icon-box img {
    background: #0B2648 !important;
    border-radius: 5px;
    padding: 18px 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.crm-analytics-box h2 {
    font-size: 23px;
    font-weight: 500;
    margin: 15px 0;
}

.explor-industries-btn {
    text-align: right;
    margin-top: 60px;
}

.explor-industries-btn a i {
    transform: translateX(5px);
    transition: all 0.3s ease-in-out;
    margin-top: 2px;
}

.explor-industries-btn a:hover {
    color: #80B927 !important;
}

.explor-industries-btn a:hover i {
    transform: translateX(10px);
}

.explor-industries-btn a {
    color: #243636;
    font-size: 16px;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.analytics-image-box.active-tab h3 {
    color: #80B927 !important;
}

@media(max-width: 1100px) {
    .analytics-image-box img {
        width: 45px;
        height: 45px;
    }

    .analytics-image-box h3 {
        margin-left: 6px;
    }
}

@media(max-width: 450px) {
    .crm-analytics-box {
        padding: 30px;
    }

    .analytics-image-box img {
        width: 40px !important;
        height: 40px !important;
    }
}

@media(max-width: 950px) {
    .space-bottom-box {
        margin-bottom: 50px;
    }
}