.typetesing-box {
    padding: 80px 0 80px 0;
    background-color: #f3f5f9;
}

.type-card-image {
    width: 100%;
    height: 250px;
    overflow: hidden;
    border-radius: 10px;
}

.type-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}

.type-setting-card {
    /* background-color: #ffffff; */
    border-radius: 5px;
    /* box-shadow: 0px 4px 27.8px 0px #0000001A; */
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    /* height: 90%; */
    /* border: 2px solid red; */
}

.type-setting-card:hover .type-card-image img {
    /* transform: scale(1.15); */
}

.type-card-text {
    padding: 40px 25px;
}

.content-box-type-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-box-type-card p {
    margin-bottom: 15px;
    font-size: 14px;
    color: #636363;
}

.content-box-type-card span {
    margin-bottom: 0;
    font-size: 14px;
    color: #636363;
}

.type-card-text h3 {
    font-size: 23px;
    margin: 30px 0 15px 0;
    font-weight: 500;
    line-height: 35px;
}

.type-card-text i.fa-solid.fa-arrow-right {
    font-size: 23px;
    margin-top: 30px;
    color: #80B927;
}

.type-setting-card.side-typing-card-box i.fa-solid.fa-arrow-right {
    margin-top: 0 !important;
}

.type-setting-card.side-typing-card-box .type-card-text a:hover {
    text-decoration: underline;
}

.type-setting-card.side-typing-card-box .type-card-text a {
    font-size: 21px !important;
    line-height: inherit !important;
    color: #272e34;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.type-setting-card.side-typing-card-box .type-card-image img {
    height: 100% !important;
}

.type-setting-card.side-typing-card-box .type-card-text {
    padding: 30px 10px !important;
}

.type-setting-card.side-typing-card-box {
    margin-bottom: 25px;
    margin-top: 30px;
}

.typetesing-box .view-all-button {
    justify-content: center;
    margin-top: 20px;
}

@media(max-width: 330px) {
    .typetesing-box {
        padding: 50px 0 0 0 !important;
    }
}

@media(max-width: 450px) {

    .content-box-type-card {
        display: flex !important;
        align-items: flex-start !important;
    }

    .arrow-right.arrows-slider,
    .arrow-left.arrows-slider {
        display: none;
    }

    .typetesing-box {
        padding: 50px 0 25px 0;
    }

    .type-setting-card.side-typing-card-box {
        margin-bottom: 0 !important;
    }

    .content-box-type-card span {
        margin-top: 15px;
    }

    .type-card-image img,
    .typetesing-box .type-setting-card.side-typing-card-box .type-card-image img {
        height: 100% !important;
    }

    .type-card-text {
        padding: 20px !important;
    }

    .type-card-text i.fa-solid.fa-arrow-right {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .type-card-text i.fa-solid.fa-arrow-right {
        margin-top: 0 !important;
    }

    .type-card-text h3 {
        font-size: 16px !important;
        line-height: inherit !important;
        margin: 10px 0 15px 0 !important;
    }
}

@media(max-width: 330px) {
    .content-box-type-card {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .content-box-type-card span {
        margin-top: 20px !important;
    }

}


.insight-size-box {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 100px;
}

.insights-box span {
    color: #52606d;
    margin-left: 10px;
    font-size: 17px;
}

.insights-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.insights-box img {
    width: 100%;
    height: 100%;
}

.arrows-slider img {
    cursor: pointer;
    width: 30px;
}

.arrows-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.arrow-right img {
    transform: rotateZ(180deg);
}