.skill-experts-box {
    background-color: #F3FAFF;
    margin-top: 50px;
}

.block-chain-cover-stack .icon-text-main-box {
    width: 40%;
}

.links-boxes:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 5px;
    background-color: #D5D5D5;
    bottom: 0px;
    z-index: -1;
    border-radius: 100px;
}

.links-boxes {
    /* border-bottom: 3px solid #D5D5D5; */
    /* overflow: hidden; */
    margin-top: 20px;
    position: relative;
}

ul.links-boxes-lists {
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    margin: 0 auto;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 10px;
}

li.active {
    color: #80B927 !important;
    font-weight: 700;
}

ul.links-boxes-lists li.active:before {
    opacity: 1 !important;
}

ul.links-boxes-lists.serve-industries-page li::before {
    display: none !important;
}

ul.links-boxes-lists li:before {
    position: absolute;
    content: '';
    background-color: #80B927;
    bottom: -1px;
    right: 0;
    width: 100%;
    height: 3px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

ul.links-boxes-lists li {
    color: #292929;
    font-size: 16px;
    padding: 10px 5px 10px 5px;
    list-style: none;
    cursor: pointer;
    border-radius: 8px 8px 0px 0px;
    position: relative;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    padding-right: 30px;
}

ul.links-boxes-lists li:hover {
    color: #80B927 !important;
}

ul.links-boxes-lists li:hover:before {
    opacity: 1 !important;
}

.tab-content-box {
    padding: 50px 0 30px 0;
}

.icon-text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-text-box.width-70 img {
    width: 50px;
}

.icon-text-box:hover img {
    transform: scale(1.15);
}

.icon-text-box img {
    width: 100%;
    object-fit: contain;
    transition: all 0.3s ease-in-out;
}

.icon-text-box h1 {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;
    font-weight: 400;
}

.frontend-tabs-box {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}

.sheduel-button-individual {
    display: flex;
    justify-content: center;
    margin-top: 70px;
}

.sheduel-button-individual a {
    border: 2px solid #07C28E;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    width: 220px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 5px;
}

.cover-the-stack-image-box {
    width: 100px;
    height: 60px;
    margin: 0 auto;
}

.cover-the-stack-image-box img {
    width: 100%;
    height: 100%;
}

/* .tab-content-box .arrow-right.arrows-slider.arrow-stack-slider {
    display: none;
} */

/* .tab-content-box .arrows-slider.arrow-stack-slider-next {
    display: none;
} */

.cover-the-stack-box:before {
    z-index: 1 !important;
    height: 3px !important;
    top: 42px;
}

.cover-the-stack-box ul.links-boxes-lists li {
    padding: 10px !important;
}

.cover-the-stack-box ul.links-boxes-lists li.active:before {
    z-index: 99 !important;
}

@media(max-width: 1025px) {
    /* .tab-content-box .arrow-right.arrows-slider.arrow-stack-slider {
        display: block;
    } */

    .tab-content-box .arrows-slider.arrow-stack-slider-next {
        display: block;
    }
}

@media(max-width: 950px) {
    .cover-the-stack-image-box {
        display: flex;
        justify-content: center;
    }

    ul.links-boxes-lists {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .cover-the-stack-image-box {
        display: flex;
        justify-content: center;
    }

    ul.links-boxes-lists li {
        white-space: nowrap;
        margin-right: 10px;
    }
}

@media(max-width: 450px) {
    .frontend-tabs-box {
        flex-wrap: wrap;
        justify-content: center;
    }

    .tab-content-box .swiper-slide {
        display: flex;
        justify-content: space-around;
    }

    .tab-content-box {
        padding: 30px 0 0 0 !important;
    }

    .icon-text-box {
        width: 100%;
        margin-bottom: 20px;
    }

    .arrow-right.arrows-slider.arrow-stack-slider,
    .arrows-slider.arrow-stack-slider-next {
        display: none;
    }

    .flex-end-mb {
        display: flex;
        justify-content: flex-end;
        width: 50px;
        position: absolute;
        right: 0;
        bottom: -30px;
        z-index: 99;
    }

    .flex-left-arrow {
        display: flex;
        justify-content: flex-end;
        position: relative;
        bottom: -138px;
        left: -47px;
    }
}

@media(max-width: 950px) {
    .frontend-tabs-box {
        width: 100%;
    }

    .icon-text-box img {
        width: 40px;
    }

    .icon-text-box h1 {
        font-size: 16px;
    }

    .tab-content-box {
        padding: 50px 0 0 0;
    }

    .sheduel-button-individual {
        margin-top: 40px;
    }
}

.tab-content-box .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
    /* width: 70% !important; */
}

.arrow-stack-slider {
    justify-content: flex-end !important;
}

.arrow-stack-slider-next {
    justify-content: flex-start !important;
}

.icon-text-main-box .cover-the-stack-image-box {
    width: 50px !important;
}

.icon-text-main-box .icon-text-box {
    width: 20% !important;
    margin-top: 50px;
}

.icon-text-main-box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 70%;
    margin: 0 auto;
}

@media(max-width: 450px) {

    .icon-text-main-box,
    .block-chain-cover-stack .icon-text-main-box {
        width: 100%;
    }

    .icon-text-main-box .icon-text-box {
        width: 40% !important;
    }
}