.navbar-box-main {
    height: 90px;
    background: linear-gradient(237.22deg, #E0F9B8 0%, rgba(224, 249, 184, 0) 80.06%);
}

.dropdown-toggle::after {
    display: none;
}

.header-link-green a {
    width: 150px;
    height: 45px;
    background-color: #80B927;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #ffffff;
    text-decoration: none;
    margin-left: 20px;
    transition: all 0.3s ease-in-out;
    border: 2px solid transparent;
}

.header-link-green a:hover {
    color: #80B927;
    background-color: transparent;
    border-color: #80B927;
}

ul.navbar-nav.ms-auto.mb-2.mb-lg-0 li {
    padding-left: 15px;
}

.dropdown-menu li {
    margin-left: 0 !important;
}

nav.navbar.navbar-expand-lg.navbar-box-main ul.dropdown-menu {
    padding: 0;
    overflow: hidden;
}

nav.navbar.navbar-expand-lg.navbar-box-main ul.dropdown-menu li a {
    padding: 10px;
    transition: all 0.3s ease-in-out;
    color: #0000008c;
    font-size: 15px;
    font-weight: 500;
}

nav.navbar.navbar-expand-lg.navbar-box-main ul.dropdown-menu li:last-child:before {
    display: none;
}

nav.navbar.navbar-expand-lg.navbar-box-main ul.dropdown-menu li:hover a {
    background: transparent;
    color: #80B927 !important;
}

nav.navbar.navbar-expand-lg.navbar-box-main ul.dropdown-menu li:hover:before {
    width: 100%;
    background-color: #80b927 !important;
}

nav.navbar.navbar-expand-lg.navbar-box-main ul.dropdown-menu li:before {
    position: absolute;
    content: '';
    width: 0%;
    height: 2px;
    top: 42px;
    background-color: #555555;
    /* right: 50%;
    transform: scaleX(50%); */
    left: 0;
    transition: all 0.3s ease-in-out;
}

nav.navbar.navbar-expand-lg.navbar-box-main ul.dropdown-menu li {
    padding: 0;
    position: relative;
}

li.header-link-green.transparent-btn-header a {
    background-color: transparent;
    color: #80b927;
    border-color: #80b927;
}

li.header-link-green.transparent-btn-header a:hover {
    background-color: #80b927;
    color: #ffffff;
}

@media(max-width: 1200px) {
    .navbar-box-main {
        height: 100% !important;
        padding: 20px 0 !important;
    }

    .header-link-green a {
        margin-left: 0 !important;
        margin-top: 15px !important;
    }
}

.navbar-box-main li.nav-item a:hover {
    color: #80B927 !important;
}

.navbar-box-main li.nav-item a {
    color: #000000;
    transition: all 0.3s ease-in-out;
}

.modal-dialog.shedule-call-model-box.modal-dialog-centered {
    max-width: 900px;
}

.modal-dialog.shedule-call-model-box.modal-dialog-centered .modal-content .modal-body {
    padding: 35px 25px !important;
}

.modal-dialog.shedule-call-model-box.modal-dialog-centered .modal-content .modal-header {
    border: none !important;
    padding: 0 !important;
}

.modal-dialog.shedule-call-model-box.modal-dialog-centered .modal-content {
    border-radius: 5px;
}

.shedule-card-box:hover .icon-box img {
    transform: rotateY(360deg);
}

.icon-box {
    width: 90px;
    height: 60px;
}

.icon-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.5s ease-in-out;
}

.shedule-card-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 40px 0 #80B927;
    border-color: transparent !important;
}

.shedule-card-box {
    height: 200px;
    width: 100%;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%) !important;
}

.modal-dialog.shedule-call-model-box.modal-dialog-centered .modal-content .modal-header button {
    position: absolute;
    right: -13px;
    background: #80B927;
    opacity: 1;
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 21px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
    z-index: 999;
    box-shadow: none !important;
}

.icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}

.icon-box i {
    font-size: 62px;
    color: #80b927;
    transition: all 0.5s ease-in-out;
}

.shedule-card-box span {
    font-size: 18px;
    margin-top: 20px;
    font-weight: 500;
    color: #000000;
}

h2.sheduel-call-heading {
    text-align: center;
    margin: 0 0 50px 0;
    font-size: 26px;
    font-weight: 500;
}

button.submit-button:hover {
    color: #80B927 !important;
    border-color: #80B927 !important;
    background-color: transparent !important;
}

button.submit-button {
    background-color: #80B927;
    border: 2px solid transparent;
    color: #ffffff;
    padding: 10px 50px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.shedule-email-form .form-control:focus-within {
    border-color: #80B927 !important;
}

.shedule-email-form .form-control {
    border-radius: 5px;
    box-shadow: none !important;
    transition: all 0.3s ease-in-out;
    border-width: 1.5px;
    border-color: #c1c1c1;
    height: 45px;
    color: #a4a5a7 !important;
}

.input-group.shedule-call-inputs {
    margin-bottom: 30px;
    margin-top: 10px;
}

.input-group.shedule-call-inputs select {
    width: 25%;
    margin-right: 20px;
    border-color: #c1c1c1 !important;
    padding: 0 10px;
    outline: none;
    color: #a4a5a7 !important;
    -webkit-appearance: none;
    /* Remove arrow in Chrome/Safari */
    -moz-appearance: none;
    /* Remove arrow in Firefox */
    appearance: none;
}

.shedule-email-form textarea {
    height: 100px !important;
    margin-bottom: 30px;
}