.devops-road-map-box {
  padding: 80px 0;
  background-image: url("../../../public/images/inductry-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 70px 0 140px 0;
}

.devops-road-map-box h1.devops-engagment-model-text {
  text-align: center;
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
}

.devops-road-image-box.devops-ai-development-road-map {
  width: 780px;
  left: 52%;
  top: 60%;
}

.devops-road-map-box h3 {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
}

.devops-proven-roadmap-text {
  margin-top: 35px;
}

.devops-proven-roadmap-text p {
  color: white;
  text-align: right;
}

.devops-proven-roadmap-text h2 {
  color: white;
  font-size: 21px;
  text-align: right;
}

.devops-road-image-box {
  position: absolute;
  width: 680px;
  left: 50%;
  top: 57%;
  transform: translate(-50%, -50%);
}

.devops-road-image-box img {
  width: 72%;
}

.devops-road-map-box .devops-prover-box-map-left {
  padding-top: 130px;
}

.devops-proven-roadmap-text.devops-road-map-left-space {
  margin-bottom: 100px;
}

.devops-right-box-text h2,
.devops-right-box-text p {
  text-align: left;
}

.devops-none-web-box {
  display: none;
}

.devops-road-map-box .devops-proven-roadmap-text {
  margin-top: 90px;
}

.devops-road-map-box p.devops-common-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.devops-road-map-box .devops-proven-roadmap-text h2 {
  font-size: 26px;
}

@media (max-width: 1100px) {
  .devops-road-image-box img {
    width: 65%;
    margin-left: 35px;
    margin-top: -120px;
  }
}

@media (max-width: 950px) {
  .devops-none-web-box {
    display: block;
  }

  .devops-road-image-box.devops-ai-development-road-map {
    width: 100%;
  }

  .devops-none-tab {
    display: none;
  }

  .devops-ai-page-roadmap {
    height: auto;
  }
}

@media (max-width: 950px) {
  .devops-road-map-box .devops-proven-roadmap-text {
    margin-top: 30px;
  }

  .devops-road-map-box .devops-proven-roadmap-text h2 {
    font-size: 25px;
    text-align: center !important;
  }

  .devops-proven-roadmap-text p {
    font-size: 16px !important;
    font-weight: 300;
    text-align: center !important;
    line-height: 30px !important;
  }

  .devops-road-map-box h1.devops-engagment-model-text {
    font-size: 32px;
  }

  .devops-road-map-box h3 {
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 30px;
  }

  .devops-road-map-box .devops-prover-box-map-left {
    padding-top: 0;
  }

  .devops-proven-roadmap-text.devops-road-map-left-space {
    margin-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .devops-road-map-box {
    padding: 50px 0;
  }
}