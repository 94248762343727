/* Custom container styling */
.custom-container {
  display: flex;
  width: 100%;
  height: 350px;
  /* Adjust height as needed */
}

.main-enterprise-services {
  padding: 80px 0;
}

/* Default column styling */
.custom-column {
  flex: 1;
  /* Equal width for all non-expanded columns */
  transition: flex 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  /* Position for absolute child */
  overflow: hidden;
  margin: 12px;
  border-radius: 13px;
}

/* Default active column */
.custom-column.default-active {
  flex: 3;
  /* 50% width */
}

/* Hovered (or active) column */
.custom-column.active {
  flex: 3;
  /* Expand hovered/active column */
}

/* Adjust non-active columns */
.custom-container .custom-column:not(.active):not(.default-active) {
  flex: 1;
  /* Shrink non-active columns */
}

/* Content styling */
.column-content {
  position: absolute;
  text-align: center;
  color: black;
  font-size: 1rem;
  height: 100%;
  transition: font-size 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* Show content of active or default-active column */
.default-active .inter-box h1.main-heading,
.active .inter-box h1.main-heading {
  font-size: 26px;
  font-weight: 500;
  transition: font-size 0.3s ease-in-out, font-weight 0.3s ease-in-out;
}

.default-active .inter-box h3.sub-heading,
.active .inter-box h3.sub-heading {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.custom-container h1.main-heading {
  text-align: left;
  transition: text-align 0.3s ease-in-out;
}

.custom-container h3.sub-heading {
  text-align: left;
  transition: text-align 0.3s ease-in-out, opacity 0.5s ease-in-out;
}

.inter-box h1.main-heading {
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 400;
  transition: color 0.3s ease-in-out, font-size 0.3s ease-in-out;
}

.inter-box h3.sub-heading {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: white;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, font-size 0.3s ease-in-out;
}

.inter-box {
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: background-size 0.3s ease-in-out, background-position 0.3s ease-in-out;
}

.inter-box a:hover i {
  margin-left: 10px;
}

.inter-box a i {
  margin-left: 5px;
  position: relative;
  top: 2px;
  transition: all 0.3s ease-in-out;
}

.inter-box a {
  text-decoration: none;
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, font-size 0.3s ease-in-out;
}

.active .inter-box a,
.default-active .inter-box a {
  opacity: 1;
}

.active .inter-box-overlay,
.default-active .inter-box-overlay {
  padding: 60px 40px;
  transition: padding 0.3s ease-in-out;
}

.inter-box-overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 20px;
  height: 100%;
  justify-content: space-between;
  background: rgb(52, 76, 16);
  background: linear-gradient(45deg, rgba(52, 76, 16, 0.7008053221288515) 45%, rgba(0, 53, 90, 0.7) 100%);
  transition: background 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.main-enterprise-services .main-heading {
  text-align: left;
}

.main-enterprise-services .sub-heading {
  text-align: left;
}

@media(max-width: 992px) {
  .custom-column.active {
    flex: 45% !important;
  }

  .column-content {
    width: 100%;
  }

  .custom-column {
    flex: 45% !important;
    /* height: -webkit-fill-available; */
  }

  .custom-container {
    flex-wrap: wrap;
  }

  .default-active .inter-box h1.main-heading,
  .active .inter-box h1.main-heading {
    font-size: 24px;
    font-weight: 400;
  }

  .inter-box h1.main-heading {
    font-size: 24px !important;
  }

  .inter-box h3.sub-heading {
    font-size: 16px;
    line-height: 22px;
    opacity: 1;
  }

  .inter-box a {
    opacity: 1;
    font-size: 18px;
  }

  .inter-box-overlay {
    padding: 20px 20px;
  }

  .inter-box-overlay h1 {
    font-size: 20px;
    font-weight: 600;
  }

  .active .inter-box-overlay,
  .default-active .inter-box-overlay {
    padding: 20px 20px;
  }

  .custom-container {
    height: 580px;
  }
}

@media(max-width: 769px) {
  .custom-column.active {
    flex: 45% !important;
  }

  .custom-column {
    flex: 45% !important;
    /* height: -webkit-fill-available; */
  }

  .custom-container {
    flex-wrap: wrap;
  }

  .default-active .inter-box h1.main-heading,
  .active .inter-box h1.main-heading {
    font-size: 24px;
    font-weight: 400;
  }

  .inter-box h1.main-heading {
    font-size: 20px !important;
    font-weight: 600 !important;
  }

  .inter-box h3.sub-heading {
    font-size: 16px;
    line-height: 22px;
    opacity: 1;
  }

  .inter-box a {
    opacity: 1;
    font-size: 18px;
  }

  .inter-box-overlay {
    padding: 20px 20px;
  }

  .active .inter-box-overlay,
  .default-active .inter-box-overlay {
    padding: 20px 20px;
  }

  .custom-container {
    height: 580px;
  }

}

@media(max-width: 1100px) {
  .custom-column {
    margin: 5px;
  }
}

@media(max-width: 480px) {
  .custom-container {
    height: auto !important;
    display: block !important;
  }

  .inter-box-overlay .sub-heading {
    margin-bottom: 0;
  }

  .custom-column {
    height: 200px !important;
  }
}