.meet-our-team-box {
    background: linear-gradient(0deg, #F9FDFF 0%, #F3F5F9 100%);
    padding: 80px 0;
}

.left-side-box h1 {
    text-align: left !important;
    font-weight: 500 !important;
    margin-bottom: 20px !important;
}

.left-side-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.meet-team-image{
    width: 90%;
    margin-left: auto !important;
}

.meet-team-image img{
    width: 100%;
}