.contact-section-box {
    padding: 60px 0 80px 0;
    /* background-image: url('../../../public/images/contact-bg.png'); */
    /* background-image: url('../../../public/images/bottom-image.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #062640;
}

.contact-text-box h1 span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

.contact-text-box {
    position: relative;
    z-index: 2;
}

.contact-text-box h1 {
    font-size: 40px;
    color: #ffffff;
    line-height: 45px;
    width: 80%;
    font-weight: 600;
    line-height: 55px;
}

.skilled-contact-section h2 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 10px;
}

.skilled-contact-section p {
    font-size: 18px;
    color: #ffffff;
    font-weight: 300;
    line-height: 22.22px;
}

.skilled-contact-section {
    position: relative;
    padding-bottom: 25px;
    padding-top: 25px;
    height: 100%;
    width: 90%;
}

.skilled-contact-section:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #80B927 0%, #FFFFFF 32.4%, #18275B 100%);
    bottom: 0;
    border-radius: 100px;
}

.contact-left-image img {
    width: 100%;
}

.contact-left-image.bottom-image-contact {
    margin-bottom: 0 !important;
    margin-top: 50px !important;
}

.contact-section-box.position-relative .contact-left-image {
    width: 390px !important;
}

.contact-left-image {
    width: 290px;
    margin-bottom: 30px;
}

/* .contact-text-side {
    border: 2px solid red;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
} */

.contact-side-form-box {
    color: #ffffff !important;
}

.contact-side-form-box .form-control {
    border-radius: 0 !important;
    background-color: transparent;
    border: none !important;
    border-bottom: 1.12px solid #FFFFFF !important;
    box-shadow: none !important;
    color: #ffffff;
    font-size: 16px;
    padding-left: 4px;
}

.contact-side-form-box .form-floating label {
    padding-left: 0;
}

.contact-side-form-box .form-floating {
    margin-bottom: 10px;
}

.contact-side-form-box .form-check-input {
    margin-right: 15px;
}

.contact-side-form-box .form-floating label,
.contact-side-form-box .form-check-label {
    color: #ffffff !important;
}

.contact-section-button {
    margin-top: 30px;
}

.contact-section-button button:hover {
    background-color: #80B927 !important;
    border-color: #80B927 !important;
}

.contact-section-button button {
    width: 140px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #80B927 !important;
    border-color: #80B927 !important;
    border-radius: 5px;
}

.contact-section-box.position-relative .contact-left-image {
    position: absolute;
    left: 0;
    top: 0;
}

.contact-section-box.position-relative .contact-text-side {
    justify-content: center !important;
}

.contact-section-box .contact-text-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

@media(max-width: 1100px) {
    .contact-text-box h1 {
        font-size: 36px;
    }
}

@media(max-width: 950px) {
    .contact-section-box .contact-text-side {
        justify-content: flex-start !important;
    }

    .contact-section-box.position-relative .contact-left-image {
        width: 100% !important;
    }

    .contact-section-box {
        padding-bottom: 80px !important;
    }

    .contact-left-image {
        margin-bottom: 20px;
    }
}

@media(max-width: 768px) {
    .contact-section-box {
        padding: 60px 0;
    }

    .contact-text-box h1 {
        font-size: 32px;
    }
}

@media(max-width: 450px) {
    .contact-text-box h1 {
        font-size: 28px;
        line-height: inherit !important;
    }

    .contact-section-box {
        padding: 50px 0 !important;
    }
}