.how-it-works-box.home-logo {
    padding: 80px 0 0 0 !important;
}

.contact-brand-image.home-page-contact-logo {
    height: 150px !important;
    background-image: none;
    padding: 10px !important;
}

.contact-brand-image {
    background-image: none !important;
}

@media(max-width: 450px) {
    .contact-brand-image {
        height: auto !important;
    }

    .how-it-works-box.home-logo {
        padding: 60px 0 0 0 !important;
    }
}