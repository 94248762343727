.health-care-industry-detail {
    padding: 80px 0 0 0;
}

.industry-page-image .banner-vedio-side-image img {
    width: 100% !important;
}

.health-care-industry-image {
    background-image: url('../../../public/images/industry-details/acordian-bg.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 330px;
    padding: 40px 50px;
    border-radius: 30px;
    color: #ffffff;
}

.health-care-industry-image h1 {
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 15px;
}

.health-care-industry-image p {
    color: #ffffff;
    font-weight: 300;
}

.industry-detail-acordian .accordion-item {
    border: none !important;
    border-radius: 0px !important;
}

.industry-detail-acordian .accordion-item .accordion-header button {
    border-bottom: 1px solid #D5D5D5 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 15px 5px;
    color: #000000 !important;
}

.industry-detail-acordian .accordion-item .accordion-body {
    padding: 15px 5px !important;
}

.industry-detail-acordian .accordion-item .accordion-body p {
    font-weight: 300;
}

.industry-detail-acordian .accordion-button:not(.collapsed)::after {
    transform: rotate(90deg) !important;
}

.industry-detail-acordian .accordion-button::after {
    background-image: none;
    content: '\f105';
    font-family: 'FontAwesome';
    font-size: 18px;
    font-weight: 300;
}

@media(max-width: 1100px) {
    .health-care-industry-image h1 {
        font-size: 30px;
    }

    .health-care-industry-image {
        padding: 30px;
    }
}

@media(max-width: 950px) {
    .health-care-industry-image {
        height: 100%;
    }
}