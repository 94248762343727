.high-values {
    background-color: #F3F5F9;
    padding: 60px 0px;
}

.high-values h2 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.high-values p {
    font-size: 18px;
    text-align: center;
}

.value-box {
    /* background: linear-gradient(to right, #80B927, #FFE500); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding: 20px 0px;
    gap: 12px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 6%);
    padding: 30px 10px;
}

.value-box:before {
    content: "";
    background: #fff;
    position: absolute;
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    z-index: -1;
    border-radius: 9px;
    top: 1.5px;
    left: 2px;
}

.high-value-boxes {
    display: flex;
    grid-template-columns: repeat(5, 1fr);
    /* 5 equal columns */
    grid-template-rows: repeat(2, auto);
    /* 2 rows */
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
}

/* 
.value-box img {
    width: 60px;
} */

.value-box h5 {
    margin: 0px;
    color: #80B927;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    padding: 0 10px;
}

.value-box {
    width: 180px;
}

.high-values-btn {
    text-align: center;
    padding-top: 60px;
}

.high-values-btn a {
    color: white;
    background-color: #80B927;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 5px;
    font-size: 21px;
    font-weight: 500;
    box-shadow: 2px 2px 8px #00000059;
}

@media(max-width: 450px) {
    .value-box {
        width: 160px !important;
    }

    .high-value-boxes {
        justify-content: center;
    }
}

@media(max-width: 768px) {
    .value-box h5 {
        font-size: 18px;
    }

    .high-values h2 {
        font-size: 32px;
    }

    .high-values p {
        font-size: 16px;
    }

    p.px-3.pb-5 {
        margin-bottom: 0px !important;
    }

    .value-box {
        width: 200px;
    }

    /* 
    .value-box img {
        width: 50px;
    } */
}