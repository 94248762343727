.engagment-model-scroller-box {
    background-color: #F3F5F9;
    padding: 80px 0;
}

.engagment-model-scroller-leftbar h2 {
    color: #80B927;
    font-size: 40px;
    font-weight: 600;
    margin: 0 0 10px 0;
    background-color: #000000;
    display: block;
    color: #000000;
    --gradient-position: 50%;
    background: -webkit-linear-gradient(348deg, #80B927 0%, #80B927 28.66%, #001838 32.66%, #001838 33%, #80B927 37%, #80B927 45%, #001838 47.5%, #001838 48%, #80B927 50.5%, #80B927 64%, #001838 69%, #80B927 70%);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 200% 100%, 100% 100%;
    background-position: var(--gradient-position) 50%, 50% 50%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 5;
}

.engagment-model-scroller-leftbar h4 {
    font-size: 26px;
    margin: 25px 0 10px 0;
    font-weight: 500;
}

.engagment-model-scroller-leftbar ul {
    padding: 0;
}

.engagment-model-scroller-leftbar ul li {
    list-style: none;
    line-height: 35px;
    padding-left: 30px;
    position: relative;
    font-size: 18px;
}

.engagment-model-scroller-leftbar ul li:before {
    position: absolute;
    content: '';
    background-color: #80B927;
    width: 13px;
    height: 13px;
    border-radius: 100px;
    left: 0;
    top: 11px;
}

.engagment-model-scroller-side {
    margin-bottom: 30px;
}

.engagment-model-scroller-side h1 {
    font-size: 40px;
    font-weight: 600;
}

.engagment-mode-scroller-images {
    background-image: url('../../../public/images/engagment-model-images/bg-image-engagement-model.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    color: #ffffff;
    width: 77%;
    padding: 30px 25px;
}

.engagement-model-bg-image-box {
    position: relative;
    margin-top: 25px;
}

.engagment-mode-scroller-images h3 {
    font-size: 26px;
    color: #ffffff;
    font-weight: 500;
}

.engagment-mode-scroller-images ul {
    padding: 0;
}

.engagment-mode-scroller-images ul li {
    list-style: none;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 300;
    width: 79%;
    margin-bottom: 10px;
    position: relative;
}

.engagment-mode-scroller-images ul li:before {
    position: absolute;
    content: '';
    background-image: url('../../../public/images/engagment-model-images/check-icon-tick.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    left: -7px;
    top: 7px;
}

.side-image-engagement-model-scroller img {
    width: 260px;
    height: 190px;
    object-fit: cover;
    border-radius: 20px;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
}

.side-line-bar {
    position: relative;
}

.side-line-bar:before {
    position: absolute;
    content: '';
    background-color: #B4B4B4;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.sticy-bar-left {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.engagment-model-btn {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    background-color: #80b927;
    font-family: "poppins";
    width: 190px;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 0.6s ease-in-out;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.engagment-model-btn:hover {
    color: #80b927;
    background-color: white;
    gap: 1.5rem;
    border: 1px solid #80b927;
}

.engagment-model-btn i {
    display: none;
    /* color: #80b927;
    transition: all 0.3s ease-in-out; */
}

.engagment-model-btn:hover i {
    display: block;
}

@media(max-width: 950px) {
    .engagment-model-scroller-box {
        padding: 60px 0;
    }

    .side-line-bar:before {
        display: none;
    }

    .engagment-model-scroller-leftbar h2 {
        font-size: 32px;
    }

    .engagment-model-scroller-leftbar h4 {
        font-size: 26px;
    }

    .engagment-model-scroller-side h1 {
        margin: 10px 0;
    }
}

@media(max-width: 450px) {
    .engagment-model-scroller-side h1 {
        font-size: 26px;
    }

    .engagment-model-scroller-leftbar h4 {
        font-size: 23px;
    }

    .side-image-engagement-model-scroller img {
        display: none;
    }

    .engagment-mode-scroller-images,
    .engagment-mode-scroller-images ul li {
        width: 100%;
    }

    .engagment-model-scroller-leftbar ul li {
        line-height: inherit;
        margin-bottom: 5px;
        padding-left: 25px;
    }

    .engagment-model-scroller-leftbar ul li:before {
        width: 10px;
        height: 10px;
        left: 3px;
    }

    .engagment-mode-scroller-images ul li:before {
        width: 15px;
        left: -2px;
    }
}