.floating-button-wrapper {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.floating-button {
  font-size: 24px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  border: none;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  cursor: pointer;
}

.floating-button:hover {
  transform: scale(1.1);
}

@media (max-width: 500px) {

  /* Your styles here */
  .floating-button-wrapper {
    /* flex-direction: column; */
  }

  .chatbot-container {
    right: 17px;
    margin: 0 !important;
    bottom: 85px !important;
  }

  .floating-button {
    font-size: 18px;
    width: 45px;
    height: 45px;
  }

}