.ai-industry-services-box {
    padding: 80px 0 90px 0 !important;
}

.accordian-box-faq {
    margin-top: 30px;
}

.accordian-box-faq .accordion-item {
    margin-bottom: 15px !important;
    border-radius: 3px !important;
    overflow: hidden;
    background: #F7FCFF !important;
    border: none !important;
    box-shadow: 0px 4px 28.4px 0px #0000001A !important;
    width: 80%;
    margin: 0 auto;
}

.accordian-box-faq .accordion-item .accordion-button[aria-expanded="true"] {
    border-bottom: 1px solid #EBEBEB;
}

.accordian-box-faq .accordion-item .accordion-button {
    font-size: 18px !important;
    color: #131313;
    font-weight: 500;
    padding: 25px !important;
    box-shadow: none !important;
    background: #F7FCFF !important;
}

.ai-industry-services-box .accordion-button::after {
    background-image: none !important;
    content: '\f063' !important;
    font-family: 'FontAwesome' !important;
    font-size: 21px;
}

.ai-industry-services-box .accordian-box-faq .accordion-item .accordion-button[aria-expanded="true"]:after {
    left: -7px;
    position: relative;
    color: #80B927 !important;
}

.transform-ai-box {
    background-image: url('../../../public/images/ai-tranform.svg') !important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 50px;
    background-color: #F8FCFF;
    border-radius: 5px;
    margin-bottom: 80px !important;
    height: 350px;
}

.transform-ai-box p {
    font-size: 21px;
    width: 60%;
    line-height: 32px;
}

.transform-ai-box .view-all-button a {
    width: 220px;
    height: 45px !important;
    margin-top: 30px;
    font-size: 16px !important;
}

.transform-ai-box .view-all-button {
    display: flex;
    justify-content: flex-start;
}


.accordian-box-faq .accordion-item:last-child {
    margin-bottom: 0 !important;
}

@media(max-width: 950px) {
    .transform-ai-box {
        background-position-x: right !important;
    }
}

@media(max-width: 450px) {
    .accordian-box-faq .accordion-item {
        width: 100% !important;
    }

    .transform-ai-box-main {
        margin-bottom: 40px;
    }
}