.understand-engagment-model-box {
    padding: 80px 0;
}

h2.sub-heading-green {
    font-size: 26px;
    font-weight: 500;
    color: #80B927;
    text-align: center;
    margin-top: 30px;
}

.check-icon-tick img {
    width: 32px;
    height: 32px;
    margin: 0 auto;
}

.understand-engagmentmodel-card {
    border: 2px solid #80B927;
    padding: 20px;
    border-radius: 20px;
    height: 100%;
}

.check-icon-tick {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.understand-engagmentmodel-card p {
    text-align: center;
    font-size: 18px;
}

@media(max-width: 1100px) {
    .understand-engagment-model-box {
        padding: 60px 0;
    }
}

@media(max-width: 950px) {
    h2.sub-heading-green {
        font-size: 26px;
    }

    .understand-engagmentmodel-card p{
        font-size: 16px;
    }
}

@media(max-width: 450px) {
    .understand-engagment-model-box {
        padding: 40px 0;
    }
}