.why-choose-bitsclan-main {
  background-image: url("../../../public/images/bottom-image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 60px 0px;
}

.who-we-are-page-content p {
  font-size: 18px;
}

.who-we-are-page-content ul li {
  font-size: 18px;
}

a.btn-left-space {
  margin-left: 0 !important;
}

.tab-menu {
  padding: 10px 0;
}

.tab-item {
  padding: 15px;
  cursor: pointer;
  font-size: 25px;
}

.tab-item img {
  width: 34px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  margin-right: 15px;
}

.tab-item.active img {
  opacity: 1;
}

.tab-item:hover {
  color: #80b927;
}

.tab-item:hover {
  background-color: #ffffff38;
  border-radius: 5px;
  margin-right: 30px;
  box-shadow: 0 0 40px 0 rgb(255 255 255 / 20%) !important;
}

.tab-item.active p {
  color: #ffffff !important;
}

.tab-item.active {
  background-color: #80b927 !important;
  font-size: 26px;
  border-radius: 5px;
  margin-right: 30px;
}

.border-right-box {
  border-right: 2px solid #80b9275e;
}

.why-tab-content-box {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #ffffff3a;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 40px 30px;
}

.tab-content .why-tab-content-box p {
  font-size: 18px;
  width: 80%;
  font-weight: 400;
}

.tab-content .why-tab-content-box a {
  color: #fff;
  background-color: #80b927;
  text-decoration: none;
  font-size: 20px;
  padding: 7px 25px;
  border-radius: 6px;
}

.why-top-content p {
  font-size: 18px;
  margin-bottom: 50px;
  font-weight: 400;
  line-height: 30px;
}

.why-top-content h2 {
  font-size: 40px;
  font-weight: 600;
}

@media (max-width: 950px) {
  .why-top-content h2 {
    font-size: 32px;
  }

  .tab-content .why-tab-content-box p {
    font-size: 16px;
    width: 100%;
  }

  .tab-content .why-tab-content-box {
    padding: 30px 20px;
  }

  .why-top-content p {
    font-size: 16px;
  }
}

.why-tab-content-box.bg-light-grey {
  background-color: #4b5f7ab5 !important;
  background-image: none;
  align-items: flex-start;
  text-align: left;
  padding: 40px;
  justify-content: flex-start;
}

.why-tab-content-box.bg-light-grey p {
  font-size: 16px;
  line-height: 30px;
  width: 100%;
  margin-bottom: 0;
}

.why-tab-content-box.bg-light-grey ul {
  /* padding-left: 0; */
}

.why-tab-content-box.bg-light-grey ul li:before {
  position: absolute;
  content: '';
  background-image: url('../../../public/images/favicon.png');
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
  left: -32px;
}

.why-tab-content-box.bg-light-grey ul {
  margin-top: 10px;
}

.why-tab-content-box.bg-light-grey ul li {
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
  list-style: none;
}

@media(max-width: 450px) {
  .border-right-box {
    border-right: none;
    border-bottom: 2px solid #80b9275e;
    margin-bottom: 30px;
  }

  .why-tab-content-box {
    width: 95%;
    margin: 0 auto;
    margin-top: 30px !important;
    padding: 20px;
  }

  .tab-item.active {
    margin-right: 0 !important;
  }

  .tab-content .hire-us-cta-btn a {
    margin-left: 0 !important;
  }
}