.main-banner-box {
    background-image: url('../../../public/images/banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
    height: 88vh;
}

.flex-center-banner-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .data-and-analytics .banner-vedio-side-image img {
    width: 100% !important;
} */

h3.subHeading-banner {
    color: #80B927;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;
}

.mega-menu {
    border: none !important;
}

h3.common-text {
    font-size: 18px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 15px;
}

h1.banner-heading {
    font-size: 56px;
    color: #ffffff;
    font-weight: 400;
    margin: 0 0 15px 0;
}

h1.banner-heading span {
    -webkit-text-fill-color: #0000;
    text-fill-color: #0000;
    background: linear-gradient(84.91deg, #80B927 44.81%, #C1FD63 67.05%);
    -webkit-background-clip: text;
    background-clip: text;
}

h2.banner-sub-heading {
    font-size: 22px;
    color: #FCFCFC;
    font-weight: 400;
    line-height: 1.6;
}

a.what-we-do-banner-button {
    background-color: #ffffff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    border-radius: 5px;
    color: #80B927;
    padding: 10px 40px;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

a.what-we-do-banner-button:hover {
    background-color: transparent;
    border: 2px solid #80B927;
}

h4.banner-sub-heading {
    color: #FCFCFC;
    margin-top: 0;
    font-size: 23px;
    margin-right: 103px;
    margin-bottom: 20px;
}

.logo-boxes-banner-small img {
    width: 70px;
    margin-right: 15px;
}

.logo-boxes-banner img {
    width: 150px;
    /* margin-right: 20px; */
}

.banner-vedio-side-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.industry-banner .banner-vedio-side-image img {
    width: 100% !important;
}

.banner-vedio-side-image img {
    width: 60% !important;
}

.flex-end-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.dev-ops-services h1.banner-heading {
    font-size: 60px;
}

@media(max-width: 950px) {
    .main-banner-box .banner-vedio-side-image img {
        width: 100% !important;
    }
}

@media(max-width: 450px) {
    .banner-vedio-side-image img {
        width: 100% !important;
        margin-top: 50px;
    }

    a.what-we-do-banner-button {
        padding: 10px 25px;
    }

    h4.banner-sub-heading {
        margin-top: 20px !important;
    }
}