/* Full-Page Mega Menu */
.mega-menu {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: auto;
  padding: 80px 0 !important;
  background-color: #ffffff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  display: none;
  padding: 0 !important;
  z-index: 9999;
  overflow-y: auto;
  border-radius: 0 !important;
}

a.hire-expert-button i {
  margin-left: 5px;
  font-size: 13px;
  position: relative;
  top: 1px;
}

a.hire-expert-button:hover {
  background-color: transparent;
  border-color: #80B927;
}

a.hire-expert-button {
  width: 150px;
  height: 45px;
  background-color: #80B927;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #ffffff !important;
  transition: all 0.3s ease-in-out;
}

.navbar-box-main li.nav-item a i {
  transition: all 0.2s ease-in-out;
}

.navbar-box-main li.nav-item a i.up-arrow {
  transform: rotate(180deg);
}

.mega-header-main-list li {
  height: 100%;
  display: flex;
  align-items: center;
}

.mega-header-main-list {
  height: 100%;
}

.navbar-box-main {
  height: 90px;
  background: linear-gradient(237.22deg,
      #e0f9b8 0%,
      rgba(224, 249, 184, 0) 80.06%);
  padding: 0;
}

.mega-menu {
  background: linear-gradient(237.22deg,
      #e0f9b8 0%,
      rgba(224, 249, 184, 0) 80.06%);
  background-color: #ffffff;
}

.mega-menu.show {
  display: block;
}

.mega-menu .row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.mega-menu h6 {
  font-weight: bold;
  margin-bottom: 10px;
}

.mega-menu p {
  font-size: 14px;
  color: #333;
}

.mega-menu img {
  width: 100%;
  border-radius: 5px;
}

.mega-menu .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
}

body.mega-menu-open {
  overflow-x: hidden;
}

ul.mega-menu-list-left li span {
  transition: all 0.3s ease-in-out;
  position: relative;
}

ul.mega-menu-list-left li span.mega-menu-sub-text:before {
  display: none !important;
}

ul.mega-menu-list-left li:hover span:before {
  transform: scaleX(1);
}

ul.mega-menu-list-left li span:before {
  position: absolute;
  content: "";
  background-color: #80b927;
  height: 2px;
  width: 100%;
  border-radius: 10px;
  top: 25px;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.3s ease-in-out;
}

ul.mega-menu-list-left li span.mega-menu-sub-text {
  color: #525252;
  font-size: 16px;
  width: 99%;
  display: flex;
  font-weight: 400;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
}

ul.mega-menu-list-left li a {
  color: #000000 !important;
}

ul.mega-menu-list-left li:hover a span {
  color: #80b927;
}

ul.mega-menu-list-left li {
  list-style: none;
  font-size: 18px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  padding-left: 0 !important;
}

ul.mega-menu-list-left {
  padding: 0;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #ffffff;
}

.country-dropdown {
  top: 90px;
  width: 100px;
}

.country-dropdown li {
  cursor: pointer;
}

.country-dropdown li:last-child {
  margin-bottom: 10px;
}

.country-dropdown li:hover {
  color: #80b927;
}

@media (max-width: 950px) {
  .mega-menu {
    padding: 20px;
  }

  .space-top-box-main {
    margin-top: 0 !important;
  }

  .mega-menu.show {
    width: 100%;
    padding: 30px 20px !important;
  }

  ul.mega-menu-list-left {
    padding: 0 !important;
  }

  .mega-header-main-list li {
    flex-direction: column;
    align-items: flex-start;
  }
}

ul.mega-menu-list-left {
  padding: 30px 0;
}

ul.mega-menu-list-left li a i {
  font-size: 13px;
  color: #b5b5b5;
  transition: all 0.3s ease-in-out;
}

ul.mega-menu-list-left li a:hover i {
  color: #80b927 !important;
}

ul.mega-menu-list-left li a:hover {
  color: #80b927 !important;
}

ul.mega-menu-list-left li a {
  text-decoration: none;
  color: #b5b5b5;
  padding: 0;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}

.side-mega-menu-box {
  padding: 30px 20px;
}

h3.mega-header-heading {
  font-size: 21px;
  color: #000000;
  margin: 0 0 33px 0;
}

.mega-menu p {
  font-size: 13px;
  color: #676767;
  margin: 2px 0 0 0;
}

.space-top-box-main {
  margin-top: 58px;
}

.country-url {
  margin-top: 13px;
  text-decoration: none;
  text-underline-offset: 5px;
  transition: all 0.3s ease-in-out !important;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.country-url i {
  display: none;
}

.country-url:hover p {

  text-decoration: underline;
}

.country-url:hover i {
  display: block;
}

.country-url:hover {
  gap: 1rem;
}