@import url("https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-y: visible;
  overflow-x: hidden;
}

#container {
  height: 100vh;
  /* set width based on sections */
  width: 400%;
  display: flex;
  flex-wrap: nowrap;
}

.horizontal-section {
  background-color: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.both-bars {
  position: relative;
}

/* Scroll Bar Container */
.scroll-bar-container {
  position: absolute;
  bottom: 70px;
  left: 51vw;
  transform: translateX(-50vw);
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
}

/* Scroll Bar Progress */
.scroll-bar-progress {
  height: 100%;
  width: 25%;
  /* Default to 25% */
  background-color: #80b927;
  transition: width 0.8s ease-out;
}

/* Scroll Bar Text */
.scroll-bar-text {
  position: absolute;
  bottom: 10px;
  left: 0vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 2;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  /* pointer-events: none; */
}

.scroll-bar-text span {
  padding-left: 20px;
  width: 25%;
}

/* bannner style  */

.main-banner-box {
  padding: 80px 0 100px 0 !important;
  height: auto;
  display: flex;
  align-items: center;
}

.main-banner-vedio-image img {
  width: 70% !important;
}

h1.banner-heading.main-banner-heading {
  font-size: 60px !important;
}

.scroll-banner h3.common-text {
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 15px;
}

.scroll-bannerh2 h1.banner-heading {
  font-size: 62px;
  color: #ffffff;
  font-weight: 600;
  margin: 0 0 15px 0;
}

h1.banner-heading span {
  -webkit-text-fill-color: #0000;
  text-fill-color: #0000;
  background: linear-gradient(84.91deg, #80b927 44.81%, #c1fd63 67.05%);
  -webkit-background-clip: text;
  background-clip: text;
}

.scroll-banner h2.banner-sub-heading {
  font-size: 28px;
  color: #fcfcfc;
  font-weight: 300;
}

.banner-button-box-2 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  background-color: #80b927;
  font-family: "poppins";
  width: 290px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.6s ease-in-out;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.banner-button-box-2:hover {
  color: #80b927;
  background-color: white;
  gap: 1.5rem;
  border: 1px solid #80b927;
}
/* .banner-button-box-2 i {
  display: none;
  color: #80b927;
  transition: all 0.3s ease-in-out;
}

.banner-button-box-2:hover i {
  display: block;
} */


/* .banner-button-box-2 i {
  color: #80B927;
  font-size: 14px;
  position: absolute;
  top: 30px;
  right: -20px;
} */

.scroll-banner .main-banner-box-new.banner-bg-image-new {
  padding-bottom: 40px;
}

.scroll-banner .common-text img {
  margin: -6px 3px 0px 0px;
}

h4.banner-sub-heading {
  color: #fcfcfc;
  margin-top: 50px;
  font-size: 23px;
  margin-right: 103px;
  margin-bottom: 20px;
}

.logo-boxes-banner-small img {
  width: 70px;
  margin-right: 15px;
}

.logo-boxes-banner img {
  width: 150px;
}

.banner-vedio-side-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.banner-vedio-side-image img {
  width: 65%;
}

.flex-end-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.row-space-top {
  margin-top: 50px;
}

.banner-vedio-side-image iframe {
  width: 900px;
  height: 500px;
}

.scroll-bar-text span.active {
  color: #80b927;
  /* Active color */
  font-weight: 700;
  /* Optional: emphasize active span */
}

.banner-model-box .modal-header {
  border: none !important;
}

.banner-model-box .modal-body {
  padding: 0 !important;
}

.banner-model-box {
  background-color: transparent;
  max-width: 900px !important;
}

.banner-model-box .modal-content {
  background-color: transparent !important;
  border: none;
}

.banner-model-box button.btn-close {
  position: absolute;
  right: 0;
  background-color: #80b927;
  opacity: 1 !important;
  font-size: 19px;
  top: 24px;
  z-index: 99;
  background-image: none !important;
  padding: 0;
  width: 40px;
  height: 40px;
  color: #ffffff;
  box-shadow: none !important;
}

.banner-vedio-icon-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.banner-vedio-icon-image img {
  width: 100px;
  /* border: 14px solid #80B927; */
  border-radius: 100px;
  position: relative;
  z-index: 2;
  box-shadow: 0 0px 50px 0 #ffffff6e;
}

span.vedio-icon-border {
  width: 180px;
  height: 180px;
  /* border: 15px solid #81b92768; */
  position: absolute;
  border-radius: 100px;
  /* animation: vedioSpin 2s linear infinite; */
}

span.vedio-icon-border-2 {
  width: 150px;
  height: 150px;
  /* border: 15px solid #81b9279b; */
  position: absolute;
  border-radius: 100px;
  /* animation: vedioSpin 4s linear infinite; */
}

.mobile-horizontal-section {
  /* height: auto; */
  height: 700px;
  /* display: flex; */
  align-items: center;
  padding: 50px 0px 130px 0;
  /* display: none; */
  background-position: center;
  background-size: cover;
}

.mobile-slier {
  position: relative;
}

@media (max-width: 950px) {
  h1.banner-heading {
    font-size: 32px !important;
    line-height: 40px;
  }

  .mobile-horizontal-section {
    height: 550px !important;
  }

  .main-banner-box {
    height: inherit !important;
  }

  .main-banner-box .banner-vedio-side-image img {
    width: 100% !important;
  }

  h1.banner-heading.main-banner-heading {
    font-size: 32px !important;
  }
}

@media (max-width: 1400px) {
  .scroll-bar-text {
    font-size: 16px;
  }
}

@media (max-width: 1280px) {
  .scroll-bar-text {
    font-size: 15px;
  }
}

@media (max-width: 1120px) {
  .scroll-bar-text {
    font-size: 14px;
  }

  .scroll-banner h2.banner-sub-heading {
    font-size: 22px;
  }

  h1.banner-heading.main-banner-heading {
    font-size: 42px !important;
  }

  .scroll-banner h3.common-text {
    font-size: 18px;
  }

  .banner-button-box-2 {
    font-size: 16px;
  }

  .banner-button-box-2 i {
    top: 26px;
  }

  .scroll-banner h3.common-text {
    line-height: 36px;
  }

  h1.banner-heading.main-banner-heading {
    line-height: normal;
  }

  .mobile-horizontal-section {
    height: 700px;
    padding: 50px 0px 110px 0;
  }

  .scroll-bar-container {
    /* bottom: 25px; */
    left: 51vw;
    width: 98%;
  }

  .scroll-bar-text {
    bottom: 10px;
    left: 0vw;
    width: 100%;
  }

  .trust-box-text:before {
    width: 255px;
  }
}

@media (max-width: 992px) {
  .scroll-bar-text span {
    display: none;
    font-size: 18px;
    padding: 0px;
  }

  .scroll-bar-text .active {
    display: block;
    width: 100%;
  }

  .scroll-bar-container {
    /* bottom: 25px; */
    left: 51vw;
    width: 98%;
  }

  .scroll-bar-text {
    bottom: 10px;
    left: 2vw;
    width: 100%;
  }

  .page-scroller-section a {
    font-size: 18px;
  }
}

@media (max-width: 770px) {
  #container {
    height: 100vh;
    width: 400%;
    /* Adjust if needed */
  }

  .horizontal-section {
    height: 100vh;
    width: 100vw;
  }

  .scroll-bar-container {
    /* bottom: 25px; */
    left: 52vw;
    width: 97%;
  }

  .scroll-bar-text {
    /* bottom: -40px; */
    left: 3vw;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .flex-end-box {
    align-items: flex-start !important;
    margin-top: 10px !important;
  }

  .mobile-slier .mobile-horizontal-section {
    height: 680px !important;
  }

  .main-banner-box.banner-bg-image .banner-vedio-icon-image {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .banner-vedio-icon-image img {
    width: 100px !important;
  }

  .main-banner-box {
    padding: 50px 0 !important;
  }

  .row.flex-convert-box .mt-5 {
    margin-top: 30px !important;
  }

  h4.banner-sub-heading {
    margin-top: 20px !important;
  }

  .main-banner-box .banner-vedio-side-image img {
    width: 100% !important;
  }

  .hire-for-us-slider {
    padding: 30px !important;
  }

  .scroll-bar-container {
    bottom: 75px;
  }

  h1.banner-heading.main-banner-heading {
    font-size: 40px !important;
  }

  .scroll-bar-text span {
    font-size: 14px;
  }

  .scroll-bar-text {
    bottom: 20px;
  }

  .mobile-horizontal-section {
    display: flex;
  }

  #container {
    display: none;
  }

  .mobile-horizontal-section {
    height: 90vh;
  }
}

@media (max-width: 376px) {
  h1.banner-heading {
    font-size: 32px !important;
    line-height: 40px;
  }

  .flex-end-box {
    align-items: flex-start !important;
    margin-top: 10px;
  }

  .scroll-bar-container {
    bottom: 55px;
  }

  .scroll-bar-text {
    bottom: 10px;
  }
}

@media (max-width: 321px) {
  .scroll-bar-container {
    bottom: 45px;
  }

  h1.banner-heading.main-banner-heading {
    font-size: 32px !important;
  }

  .scroll-banner h2.banner-sub-heading {
    font-size: 16px;
  }

  .scroll-banner h3.common-text {
    line-height: 26px;
  }

  .mobile-horizontal-section {
    height: 100vh;
  }
}

.slide-in-top-1 {
  -webkit-animation: slide-in-top-1 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top-1 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-top-2 {
  -webkit-animation: slide-in-top-2 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-top-2 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-top-3 {
  -webkit-animation: slide-in-top-3 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top-3 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-top-4 {
  -webkit-animation: slide-in-top-4 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-top-4 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-12-16 21:39:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top-1 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top-1 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-top-2 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top-2 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-top-3 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top-3 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-top-4 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top-4 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes vedioSpin {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }

  100% {
    transform: scale(1.7);
    opacity: 0.2;
  }
}

.banner-pioner-image span {
  font-size: 24px;
  position: relative;
  top: 4px;
  margin-left: 5px;
}

.banner-pioner-image img {
  width: 40px;
}
